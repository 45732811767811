import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React, { Suspense } from 'react';

interface ComponentsProps {
  [index: string]: React.FC<any>;
}

const components: ComponentsProps = {
  storage__article_cards: React.lazy(() => import('../cms-components/StorageArticleCards')),
  storage__image_content: React.lazy(() => import('../cms-components/StorageImageContent')),
  storage__hero_card: React.lazy(() => import('../cms-components/StorageHeroCard')),
  storage__manual_cards: React.lazy(() => import('../cms-components/StorageManualCards')),
  storage__product_cards: React.lazy(() => import('../cms-components/StorageProductCards')),
  storage__highlights: React.lazy(() => import('../cms-components/StorageHighlights')),
  storage__rich_text: React.lazy(() => import('../cms-components/StorageRichText')),
  storage__ansira_profile_form: React.lazy(
    () => import('../cms-components/StorageAnsiraProfileForm'),
  ),
  storage__ingredients_map: React.lazy(() => import('../cms-components/StorageIngredientsMap')),
  storage__page_title: React.lazy(() => import('../cms-components/StoragePageTitle')),
  storage__banner: React.lazy(() => import('../cms-components/StorageBanner')),
  storage__brand_grid: React.lazy(() => import('../cms-components/StorageBrandGrid')),
  storage__faq_section: React.lazy(() => import('../cms-components/StorageFaqSection')),
  storage__html: React.lazy(() => import('../cms-components/StorageUnfilteredHtml')),
  storage__subnav: React.lazy(() => import('../cms-components/StorageSubnav')),
  storage__card_grid: React.lazy(() => import('../cms-components/StorageCardGrid')),
  storage__reviews: React.lazy(() => import('../cms-components/StorageReviews')),
  storage__category_cards: React.lazy(() => import('../cms-components/StorageCategoryCards')),
  storage__contact_cards: React.lazy(() => import('../cms-components/StorageContactCards')),
  storage__video: React.lazy(() => import('../cms-components/StorageVideo')),
  storage__newsletter_signup_form: React.lazy(
    () => import('../cms-components/StorageNewsletterSignUpForm'),
  ),
  storage__nominate_pet_hero_form: React.lazy(
    () => import('../cms-components/StorageNominatePetHeroForm'),
  ),
  storage__demand_gen_form: React.lazy(() => import('../cms-components/StorageDemandGenForm')),
  storage__proclub_member_form: React.lazy(
    () => import('../cms-components/StorageProClubMemberForm'),
  ),
  storage__breeder_referral_form: React.lazy(
    () => import('../cms-components/StorageBreederReferralForm'),
  ),
  storage__contact_us_form: React.lazy(() => import('../cms-components/StorageContactUsForm')),
  storage__hero_carousel: React.lazy(() => import('../cms-components/StorageHeroCarousel')),
  storage__dynamic_script: React.lazy(() => import('../cms-components/StorageDynamicScript')),
};

interface Props {
  [key: string]: any;
}
interface ICmsComponentProps {
  node: IStorage | TCmsComponents;
  index: number;
  props?: Props;
}

export const getCmsComponent = ({
  node,
  index,
  props,
}: ICmsComponentProps): React.ReactElement | null => {
  if (components.hasOwnProperty(node.type)) {
    const CmsComponent = components[node.type];
    // eslint-disable-next-line
    node.indexOnPage = index;
    return (
      <Suspense fallback={<>Loading ${node.type}...</>}>
        <AnalyticsPoint type="module" node={node}>
          <CmsComponent node={node} props={props} />
        </AnalyticsPoint>
      </Suspense>
    );
  }
  return null;
};
