import React from 'react';
import { CMS_THEME } from '../../common/enums';
import VideoEmbed from '../video_embed/VideoEmbed';

interface Props extends Media {
  field_media_oembed_video: string;
  field_media_video_transcript: {
    processed: string;
  };
  cmsTheme?: CMS_THEME;
}

const MediaRemoteVideo = function (props: Props) {
  const embedUrl = props.field_media_oembed_video;
  const transcript = props?.field_media_video_transcript?.processed;
  if (!transcript) return null;
  return <VideoEmbed cmsTheme={props.cmsTheme} embedUrl={embedUrl} transcript={transcript} />;
};

export default MediaRemoteVideo;
