import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useWebform } from 'src/hooks/useWebform';
import NominatePetHeroForm from '../components/forms/NominatePetHeroForm/NominatePetHeroForm';

interface StorageNominatePetFormProps {
  node?: IStorageNominatePetForm;
}

const StorageNominatePetHeroForm = ({ node: data }: StorageNominatePetFormProps) => {
  const { getFieldDataByName } = useWebform(data?.relationships?.form);

  return (
    <Container className="py-5">
      <Row className="justify-content-md-center">
        <Col>
          <NominatePetHeroForm getFieldDataByName={getFieldDataByName} />
        </Col>
      </Row>
    </Container>
  );
};

export default StorageNominatePetHeroForm;
