import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import { formatSku } from '../../utils/helpers';
import ProductCard from '../product_card/ProductCard';
import ProductCardSkeleton from '../product_card/ProductCardSkeleton';
import NoResults from './NoResults';
import { SearchListingContext } from './SearchListingContext';

interface Props {
  serverData?: DrupalSearch;
}

const ProductSearchResults: React.FC<Props> = ({ serverData }) => {
  const context = useContext(SearchListingContext);

  if (!context) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }

  const { data, loaded } = context || {};
  const search_results = serverData?.search_results || data?.search_results || ({} as DrupalSearch);
  if (!loaded) {
    return (
      <Row className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3">
        {[...Array(12)].map((_, i) => {
          return (
            <Col key={`loading-card-${i}`} xs={6} md={4} className="d-flex">
              <ProductCardSkeleton />
            </Col>
          );
        })}
      </Row>
    );
  }

  if (!search_results) {
    return <NoResults />;
  }

  return (
    <Row id="search-results" className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3">
      {Object.values(search_results).map(node => {
        const image = node.product_image
          ? process.env.GATSBY_DRUPAL_ENDPOINT + node.product_image.substring(1)
          : '';

        const sku = node.upc ? node.upc : '';
        // const sku = node.upc ? formatSku(node.upc) : '';

        return (
          <Col key={node.nid} xs={6} md={4} className="d-flex">
            <AnalyticsPoint
              type="component"
              category="search_result_selected"
              action={node.title}
              eventLabel="products"
              node={{ ...node }}
            >
              <ProductCard
                image={{
                  images: {
                    fallback: {
                      src: image,
                    },
                  },
                  layout: 'constrained',
                  width: 800,
                  height: 800,
                }}
                disableAnalyticsPoint
                brand={node.field_brand}
                link={node.url}
                title={node.title}
                sku={sku}
                bvID={node.bazaarvoice_id}
                product_status={node.product_status}
              />
            </AnalyticsPoint>
          </Col>
        );
      })}
    </Row>
  );
};

ProductSearchResults.displayName = 'SearchListing.ProductSearchResults';

export default ProductSearchResults;
