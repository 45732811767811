import { styled } from '@linaria/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import RichText from '../rich_text/RichText';
import Typography from '../typography/Typography';

type Props = {
  node: IStorageFaq;
  cmsTheme: CMS_THEME;
  headingStyle?: 'h3' | 'h4';
};

const red = 'red';
// This wrapper has the correct facet icon
const StyledAccordionHeaderWrapper = styled.div<{ cmsTheme: CMS_THEME }>`
  /* --bs-accordion-btn-icon: url(${`"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='${red}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"`}); */
  --bs-accordion-btn-icon: ${({ cmsTheme }) =>
    `url(${`"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='${theme[
      cmsTheme
    ].text.default.replace(
      '#',
      '%23',
    )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"`})`};
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%230c63e4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  --bs-accordion-border-color: ${theme.common.gray[400]};
  --bs-accordion-btn-padding-y: 2.5rem; /* 40px */
  --bs-accordion-btn-padding-x: 2rem; /* 32px */
  --bs-accordion-btn-color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  --bs-accordion-btn-bg: ${({ cmsTheme }) => theme[cmsTheme].background.default};
`;

const StyledAccordionItem = styled(Accordion.Item)<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  background: ${({ cmsTheme }) => theme[cmsTheme].background.default};
`;

const FaqItem = ({ node, headingStyle = 'h4', cmsTheme }: Props) => {
  return (
    <AnalyticsPoint
      type="component"
      node={node}
      as={StyledAccordionItem}
      data-id={node.drupal_id}
      cmsTheme={cmsTheme}
      className="js-track"
      id={node.id}
      eventKey={node.id}
    >
      <StyledAccordionHeaderWrapper cmsTheme={cmsTheme}>
        <Accordion.Header>
          <Typography variant={headingStyle}>{node.question}</Typography>
        </Accordion.Header>
      </StyledAccordionHeaderWrapper>
      <Accordion.Body>
        <RichText body={node.answer.processed} />
      </Accordion.Body>
    </AnalyticsPoint>
  );
};

export default FaqItem;
