import { styled } from '@linaria/react';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import Slider from 'react-slick';
import { theme } from '../theme/theme';
import StorageHeroCard from './StorageHeroCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HeroCarouselContainer = styled.div`
  .slick-slider {
    padding-bottom: 36px;
  }

  @media (min-width: ${theme.media.lg}) {
    .slick-slider {
      padding-bottom: 0;
    }
  }
`;

const ArrowContainer = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b3b3b3;
  border-radius: 50%;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  &.slick-arrow {
    &.slick-next,
    &.slick-prev {
      width: 34px;
      height: 34px;
      background: #f5f5f5;
    }

    &::before {
      content: '';
    }
  }

  &.prev-arrow {
    left: 20px;
  }

  &.next-arrow {
    right: 20px;
  }
`;

const DotsContainer = styled.div`
  z-index: 1;
  bottom: 0;
  left: 50%;
  margin-bottom: -12px;
  position: absolute;
  width: fit-content;

  @media (min-width: ${theme.media.lg}) {
    margin-bottom: 10px;
  }

  ul {
    background-color: hsla(0, 0%, 100%, 0.5);
    border-radius: 17px;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    position: relative;
    transform: translateX(-50%);

    li {
      background: transparent;
      border-radius: 16px;
      box-sizing: border-box;
      display: block;
      height: 16px;
      width: 16px;

      &.slick-active {
        button {
          &::before {
            background-color: #424242;
          }
        }
      }

      button {
        background: transparent;
        border: 0;
        color: transparent;
        cursor: pointer;
        display: block;
        font-size: 0;
        padding: 0;

        &:hover {
          &::before {
            background-color: #424242;
          }
        }

        &::before {
          border: 2px solid #424242;
          border-radius: 50%;
          content: '';
          display: block;
          height: 12px;
          width: 12px;
          background-color: #fff;
          border: 2px solid #424242;
          opacity: 1;
        }
      }
    }
  }
`;

const CustomArrow = (props: any) => {
  const { className, onClick, type } = props;
  return (
    <ArrowContainer className={`${className} ${type}-arrow`} onClick={onClick}>
      <Icon
        path={type === 'prev' ? mdiArrowLeft : mdiArrowRight}
        size="20"
        style={{ width: '100%', height: '100%' }}
        color="black"
        aria-hidden
      />
    </ArrowContainer>
  );
};

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <CustomArrow type="next" />,
  prevArrow: <CustomArrow type="prev" />,
  appendDots: (dots: any) => (
    <DotsContainer>
      <ul> {dots} </ul>
    </DotsContainer>
  ),
};

interface StorageHeroCarouselProps {
  node: IStorageHeroCarousel;
}

const StorageHeroCarousel = ({ node: heroCarousel }: StorageHeroCarouselProps) => {
  if (!heroCarousel?.relationships) return <></>;

  const { relationships } = heroCarousel;

  return (
    <HeroCarouselContainer>
      <Slider {...settings}>
        {relationships.hero_cards.map((item: IStorageHeroCard) => (
          <StorageHeroCard key={item.id} node={item} eagerLoad />
        ))}
      </Slider>
    </HeroCarouselContainer>
  );
};

export default StorageHeroCarousel;
