import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';
import Row from 'react-bootstrap/Row';
import { CMS_THEME } from 'src/common/enums';
import { defaultColors } from '../../theme/colors';
import { theme } from '../../theme/theme';
import Link from '../Link';
import { ButtonLink } from '../button/Button';
import RichText from '../rich_text/RichText';

const StyledLink = styled(Link)`
  letter-spacing: 2px;
`;

const StyledNav = styled.div<{ offset?: number }>`
  a {
    color: ${defaultColors.dark};
  }
  .dropdown {
    border-bottom: 1px solid ${defaultColors.border};

    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      color: ${defaultColors.dark};

      .icon {
        height: 36px;
        width: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: ${defaultColors.light};

        svg {
          width: 24px;
          height: 24px;
        }
      }

      ::after {
        display: none;

        svg {
          display: inline-block;
          height: 1.75rem;
          width: 1.75rem;
        }
      }
    }

    &.show {
      border: none;
      & > a svg {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    border: none;
  }

  @media (min-width: ${theme.media.lg}) {
    .dropdown {
      border-bottom: none;
      position: static;

      .dropdown-toggle {
        justify-content: start;
        font-size: 22px;

        .icon {
          height: auto;
          width: auto;
          display: block;
          background-color: transparent;
        }

        svg {
          height: 1.125rem;
          width: 1.125rem;
          margin-left: 0.25rem;
        }
      }
    }

    .dropdown-menu {
      background: none;
      position: fixed !important;
      top: ${props => (props?.offset ? `${props.offset}px` : 'auto')};
      left: 0 !important;
      right: 0 !important;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      margin: 0;
      padding: 0;
      .mega-content {
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        > *:last-child {
          border-bottom-right-radius: 0.375rem;
        }

        max-height: 600px;
        overflow: auto;
      }

      &.show {
        .mega-content {
          border-top: 1px solid ${defaultColors.border} !important;
        }
      }
    }
  }
`;

const SubtitleNav = styled.span`
  color: #e81c24;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2.5px;
  text-transform: uppercase;
`;
const StyledPromo = styled.div`
  @media (min-width: ${theme.media.lg}) {
    margin-right: 2rem;
  }
`;

interface MegaMenuProps {
  node: IStorageMegaMenu;
  offset?: number | undefined;
  className?: string;
}

const MegaMenu: React.FC<MegaMenuProps> = function ({ node, offset, className }) {
  const menuLinks = node.relationships?.menu_links;
  const menuSecondary = node.relationships?.secondary_menu;

  return (
    <AnalyticsPoint
      type="module"
      typeLabel="mega_menu"
      label="Mega menu"
      as={StyledNav}
      id="mainMenu"
      offset={offset}
      className={cx(className, 'overflow-auto')}
    >
      <StyledNav>
        <Nav
          as="ul"
          aria-label="Main Navigation"
          className="flex-column flex-lg-row w-100 p-3 p-lg-0 gap-1"
        >
          {menuLinks &&
            menuLinks.map(menuLink => {
              const subMenus = menuLink?.relationships?.menus || [];
              const card = menuLink?.relationships?.promo?.[0];
              const media = card?.relationships.image;
              if (subMenus.length >= 1) {
                return (
                  <Dropdown
                    id={`nav-${menuLink.id}`}
                    as="li"
                    key={menuLink.id}
                    className="nav-item"
                  >
                    <Dropdown.Toggle className="fw-light" as={NavLink}>
                      {menuLink.link.title}
                      <span className="icon rounded-circle">
                        <Icon path={mdiChevronDown} />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu renderOnMount className="border-0">
                      <Container fluid className="p-0">
                        <Container fluid="lg">
                          <Row className="gx-lg-0 bg-white mega-content">
                            <Col lg={card ? 8 : 12} className="p-3 px-lg-5">
                              <Link
                                className="h4 mb-2 mb-lg-3 d-none d-lg-inline-block text-decoration-none text-body "
                                to={menuLink.link.url}
                              >
                                {menuLink.link.title}
                              </Link>
                              <Row>
                                {subMenus.map(subMenu => {
                                  const { links } = subMenu;
                                  return (
                                    <AnalyticsPoint
                                      type="component"
                                      node={{
                                        id: subMenu.id,
                                        type: 'submenu_header_item',
                                        name: subMenu.link.title,
                                      }}
                                      as={Col}
                                      key={subMenu.id}
                                      lg="auto"
                                      className="mb-3 mb-lg-0"
                                    >
                                      <SubtitleNav className="mb-2">
                                        {subMenu.link.title}
                                      </SubtitleNav>
                                      {links.length >= 1 && (
                                        <Nav
                                          as="ul"
                                          className="flex-column rounded-2 p-3 bg-gray-100"
                                        >
                                          {links.map((link, index) => {
                                            return (
                                              <AnalyticsPoint
                                                type="component"
                                                node={{
                                                  name: link.title,
                                                  type: 'sub_menu_item',
                                                  id: '',
                                                }}
                                                as="li"
                                                key={`${subMenu.id}${index}`}
                                              >
                                                <Link
                                                  to={link.url}
                                                  {...link.options?.attributes}
                                                  className="text-decoration-none text-body py-1 d-block fw-light"
                                                >
                                                  {link.title}
                                                </Link>
                                              </AnalyticsPoint>
                                            );
                                          })}
                                        </Nav>
                                      )}
                                    </AnalyticsPoint>
                                  );
                                })}
                              </Row>
                            </Col>
                            {media && (
                              <Col className="p-3 bg-light">
                                <StyledPromo>
                                  <GatsbyImage
                                    alt=""
                                    image={media.relationships.field_media_image.gatsbyImage}
                                    className="rounded-2 mb-3"
                                    loading="lazy"
                                  />
                                  {card.body && (
                                    <RichText
                                      className="text-center text-lg-start"
                                      body={card.body.processed}
                                    />
                                  )}
                                  {card.links &&
                                    card.links.map(link => {
                                      return (
                                        <AnalyticsPoint
                                          type="component"
                                          node={{
                                            name: link.title,
                                            type: 'submenu_item',
                                            id: '',
                                          }}
                                        >
                                          <ButtonLink
                                            cmsTheme={CMS_THEME.LIGHT}
                                            to={link.url}
                                            variant="btn-outline"
                                            className="mx-auto d-block mx-lg-0 d-lg-inline-block"
                                            {...link?.options?.attributes}
                                          >
                                            {link.title}
                                          </ButtonLink>
                                        </AnalyticsPoint>
                                      );
                                    })}
                                </StyledPromo>
                              </Col>
                            )}
                          </Row>
                        </Container>
                      </Container>
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
              return (
                <AnalyticsPoint
                  type="component"
                  node={{ name: menuLink.link.title, type: 'submenu_item', id: '' }}
                >
                  <Link
                    to={menuLink.link.url}
                    key={menuLink.id}
                    {...menuLink.link.options?.attributes}
                  >
                    {menuLink.link.title}
                  </Link>
                </AnalyticsPoint>
              );
            })}
        </Nav>
        {menuSecondary && (
          <ul className="d-block d-lg-none px-3 list-unstyled" style={{ columnCount: '2' }}>
            {menuSecondary.links.map((link, index) => {
              return (
                <AnalyticsPoint
                  type="component"
                  node={{ name: link.title, type: 'secondary_menu_item', id: '' }}
                  as="li"
                  key={`secondary-${index}`}
                >
                  <StyledLink
                    to={link.url}
                    {...link.options?.attributes}
                    className="text-body fs-xx-small d-block text-uppercase text-decoration-none py-2"
                  >
                    {link.title}
                  </StyledLink>
                </AnalyticsPoint>
              );
            })}
          </ul>
        )}
      </StyledNav>
    </AnalyticsPoint>
  );
};

export default MegaMenu;
