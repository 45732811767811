import { Script } from 'gatsby';
import React from 'react';

export const getBVLocale = (locale: string) => {
  const locals: Record<string, string> = {
    en: 'en_CA',
    fr: 'fr_CA',
  };

  return locals[locale] || 'en_CA';
};

const WrapPageElement = ({ element, props }, pluginOptions) => {
  const lang = getBVLocale(props.pageContext.language);
  // Currently have to explicitly manipulate script tag conditional rendering to trigger new BV script on lang change.
  // Additionally, since the new script won't trigger a refresh of the current BV UI, a forced refresh is necessary to get the correct BV language.
  // TODO: look into a simpler solution for this integration
  return (
    <>
      {lang === 'en_CA' && (
        <Script
          src={`//apps.bazaarvoice.com/deployments/${pluginOptions.clientName}/${pluginOptions.siteID}/${pluginOptions.mode}/${lang}/bv.js`}
          onLoad={() => {
            if (window.BV.global.locale !== lang) {
              window.location.reload();
            }
            // // Unknown amount of additional pre-requisite network calls need to be made prior to a trackEvent is able to succeed.
            // setTimeout(() => {
            //   // @ts-ignore - Explicitly calling bvCallback upon pixel loaded confirmation.
            //   window.bvCallback(window.BV);
            // }, 1000);
          }}
        />
      )}
      {lang === 'fr_CA' && (
        <Script
          src={`//apps.bazaarvoice.com/deployments/${pluginOptions.clientName}/${pluginOptions.siteID}/${pluginOptions.mode}/${lang}/bv.js`}
          onLoad={() => {
            if (window.BV.global.locale !== lang) {
              window.location.reload();
            }
            // // Unknown amount of additional pre-requisite network calls need to be made prior to a trackEvent is able to succeed.
            // setTimeout(() => {
            //   // @ts-ignore - Explicitly calling bvCallback upon pixel loaded confirmation.
            //   window.bvCallback(window.BV);
            // }, 1000);
          }}
        />
      )}
      {element}
    </>
  );
};

export default WrapPageElement;
