import { GatsbyImageProps } from 'gatsby-plugin-image';
import React from 'react';
import MediaImage from '../components/media/MediaImage';
import MediaInfographic from '../components/media/MediaInfographic';
import MediaRemoteVideo from '../components/media/MediaRemoteVideo';
import MediaVectorImage from '../components/media/MediaVectorImage';

type MediaMap = {
  [index: string]: React.FC<any>;
};

const components: MediaMap = {
  media__remote_video: MediaRemoteVideo,
  media__image: MediaImage,
  media__infographic: MediaInfographic,
  media__vector_image: MediaVectorImage,
};

interface Props extends JSX.IntrinsicAttributes, Omit<GatsbyImageProps, 'alt' | 'image' | 'id'> {
  media: Media;
  variant?: string;
  width?: number;
}

export type GetMediaReturn = React.ReactElement | null;

export const getMedia = ({ media, ...props }: Props): GetMediaReturn => {
  if (media && components.hasOwnProperty(media?.internal.type)) {
    const MediaComponent = components[media.internal.type];
    return <MediaComponent {...media} {...props} />;
  }
  return null;
};

interface FocalPoint {
  aspect_ratio: {
    width: number;
    height: number;
  };

  focal_point: {
    x: string;
    y: string;
  };

  original_size: {
    width: string;
    height: string;
  };
}

export const getFocalPoint = (imageField: any): string | undefined => {
  if (!imageField) return undefined;
  const focalPoint: FocalPoint = imageField?.focal_point;

  if (!focalPoint || focalPoint.focal_point.x === null || focalPoint.focal_point.y === null) {
    return undefined;
  }

  const x = parseInt(focalPoint.focal_point.x, 10);
  const y = parseInt(focalPoint.focal_point.y, 10);
  const width = parseInt(focalPoint.original_size.width, 10);
  const height = parseInt(focalPoint.original_size.height, 10);

  const mutate = (value: number) => {
    let newValue = value;
    if (value > 75) newValue = value + 10;
    if (value < 25) newValue = value - 10;
    if (newValue > 100) return 100;
    if (newValue < 0) return 0;
    return newValue;
  };

  const converted = {
    x: (x / width) * 100,
    y: (y / height) * 100,
  };

  return `${mutate(converted.x)}% ${mutate(converted.y)}%`;
};
