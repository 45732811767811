import React from 'react';
import Row from 'react-bootstrap/Row';
import { getParagraph } from '../../utils/paragraphHelpers';

interface ParagraphProps extends Paragraph {
  relationships: {
    columns?: [Paragraph];
  };
}

interface ParagraphFooterRowProps {
  node: ParagraphProps;
}

const ParagraphFooterRow: React.FC<ParagraphFooterRowProps> = function ({ node }) {
  const columns = node?.relationships?.columns;
  const classes = node.behavior_settings?.decoupled_styles?.classes;

  return (
    <Row className={classes && classes.join(' ')}>
      {columns &&
        columns.map(column => {
          return (
            <React.Fragment key={column.id}>
              {getParagraph({ node: column, props: { location: 'footer' } })}
            </React.Fragment>
          );
        })}
    </Row>
  );
};

export default ParagraphFooterRow;
