import YAML from 'yaml';
// import { State, IState } from 'country-state-city';

// The Show Form Button allows a form to become toggleable if a checkbox field with the given name is present.
const SHOW_FORM_KEY = 'show_form';

// Within a form, there can be a special field that is used to display some header text.
const HEADER_TEXT_KEY = 'processed_text';

export interface WebformRaw {
  [key: string]: WebformRawField;
}

export interface WebformRawSelectOptions {
  [key: string]: string;
}

export interface WebformRawStatesObject {
  visible: {
    [key: string]: {
      value: string;
    };
  };
}

export interface WebformRawField {
  '#type': string;
  '#title'?: string;
  '#description'?: string;
  '#autocomplete'?: string;
  '#required'?: boolean;
  '#required_error'?: string;
  '#title_display'?: string;
  '#options'?: WebformRawSelectOptions;
  '#element'?: WebformRawSelectOptions;
  '#empty_option'?: string;
  '#empty_value'?: string;
  '#submit__label'?: string;
  '#states'?: WebformRawStatesObject;
  [key: string]:
    | WebformRawField
    | WebformRawSelectOptions
    | WebformRawStatesObject
    | string
    | boolean
    | undefined;
}

export enum WebformDataType {
  CONTAINER = 'container',
  CHECKBOX = 'checkbox',
  TEXTFIELD = 'textfield',
  EMAIL = 'email',
  SELECT = 'select',
  TEL = 'tel',
  TEXTAREA = 'textarea',
  WEBFORM_FLEXBOX = 'webform_flexbox',
  WEBFORM_ACTIONS = 'webform_actions',
  CAPTCHA = 'captcha',
}

export interface WebformConfirmationSettings {
  title: string;
  message: string;
}

export interface ParsedWebform {
  fields: ParsedWebformField[];
  title: string;
  webformId: string;
  confirmationSettings: WebformConfirmationSettings;
  showFormField?: ParsedWebformField;
  headerField?: ParsedWebformField;
}

export type ParsedWebformSelectOptions = { value: string; label: string }[];
export type ParsedWebformSelectOptionsGroup = {
  [key: string]: ParsedWebformSelectOptions;
};

export interface ParsedWebformFieldInteractionParent {
  fieldName: string;
  interactionType: ParentFieldInteractionType;
  valuesToMakeChildVisible?: string[]; // used only for toggle fields
  fieldLabelByParentValue?: { [key: string]: string };
}

export enum ParentFieldInteractionType {
  TOGGLE = 'toggle',
  GROUP_SELECT_OPTIONS = 'group_select_options',
}

export interface ParsedWebformField {
  type: WebformDataType;
  name: string;
  title?: string;
  description?: string;
  text?: string;
  autocomplete?: string;
  required?: boolean;
  requiredError?: string;
  titleDisplay?: string;
  options?: ParsedWebformSelectOptions | ParsedWebformSelectOptionsGroup;
  element?: ParsedWebformSelectOptions | ParsedWebformSelectOptionsGroup;
  emptyOption?: string;
  emptyValue?: string;
  submitLabel?: string;
  children?: ParsedWebformField[];
  interactionParent?: ParsedWebformFieldInteractionParent;
}

const STATE_PROVINCE_FIELD_NAME = 'state_province_region';
const CITY_FIELD_NAME = 'city';
const ZIP_FIELD_NAME = 'zip_postal_code';
const COUNTRY_FIELD_NAME = 'country';

const parseAllKnownProperties = (
  rawFieldData: WebformRawField,
  originalName: string
): ParsedWebformField => {
  const formField: ParsedWebformField = {
    type: rawFieldData['#type'] as WebformDataType,
    name: originalName,
    children: [] as ParsedWebformField[],
  };

  // check for each known key specifically
  if (rawFieldData['#title']) {
    formField.title = rawFieldData['#title'];
  }
  if (rawFieldData['#description']) {
    formField.description = rawFieldData['#description'];
  }
  if (rawFieldData['#text']) {
    formField.text = rawFieldData['#text'];
  }
  if (rawFieldData['#autocomplete']) {
    formField.autocomplete = rawFieldData['#autocomplete'];
  }
  if (rawFieldData['#required']) {
    formField.required = rawFieldData['#required'];
  }
  if (rawFieldData['#required_error']) {
    formField.requiredError = rawFieldData['#required_error'];
  }
  if (rawFieldData['#title_display']) {
    formField.titleDisplay = rawFieldData['#title_display'];
  }
  if (rawFieldData['#options']) {
    const rawOptions = rawFieldData['#options'];
    formField.options = Object.entries(rawOptions).map(([value, label]) => ({
      value,
      label,
    }));
  }
  if (rawFieldData['#element']) {
    const rawElement = rawFieldData['#element'];
    formField.element = Object.entries(rawElement).map(([value, label]) => ({
      value,
      label,
    }));
  }
  if (rawFieldData['#empty_option']) {
    formField.emptyOption = rawFieldData['#empty_option'];
  }
  if (rawFieldData['#empty_value']) {
    formField.emptyValue = rawFieldData['#empty_value'];
  }
  if (rawFieldData['#submit__label']) {
    formField.submitLabel = rawFieldData['#submit__label'];
  }

  if (rawFieldData['#states']) {
    const statesObject = rawFieldData['#states'];
    const key = Object.keys(statesObject.visible)[0];

    // Example key: :input[name="issue_concerning_adopted_pet"]
    // And we pull out the name of the field: ie, "issue_concerning_adopted_pet" using a regex
    const regex = /:input\[name="([^"]*)"\]/;
    const match = key.match(regex);
    const fieldName = match ? match[1] : '';
    const value = statesObject.visible[key].value;

    formField.interactionParent = {
      fieldName,
      interactionType: ParentFieldInteractionType.TOGGLE,
      valuesToMakeChildVisible: [value],
    };
  }

  return formField;
};

const COUNTRY_CODES = ['us', 'mx', 'ca'];

const getStateOptionsForCountry = (countryCode: string): ParsedWebformSelectOptions => {
  const states: IState[] = State.getStatesOfCountry(countryCode);
  return states.map(state => ({
    value: state.name,
    label: state.name,
  }));
};

const getStateOptionsForCountries = (countryCodes: string[]): ParsedWebformSelectOptionsGroup => {
  const stateOptionsForCountries: ParsedWebformSelectOptionsGroup = {};
  countryCodes.forEach(countryCode => {
    stateOptionsForCountries[countryCode] = getStateOptionsForCountry(countryCode.toUpperCase());
  });
  return stateOptionsForCountries;
};

// this function parses a single field from the raw data, and recurses into any children if needed
const parseFieldFromRaw = (
  rawFieldData: WebformRawField,
  originalName: string
): ParsedWebformField | null => {
  const formField: ParsedWebformField = parseAllKnownProperties(rawFieldData, originalName);

  // ignore any captcha fields, we handle them in submit, for all forms
  if (formField.type === WebformDataType.CAPTCHA) {
    return null;
  }

  // if the field name is the state province field, then we need to add an interaction parent to it
  // also change its type to SELECT. the interaction parent should be the country field, and the type should be GROUP_SELECT_OPTIONS
  if (formField.name === STATE_PROVINCE_FIELD_NAME) {
    formField.type = WebformDataType.SELECT;
    formField.interactionParent = {
      fieldName: COUNTRY_FIELD_NAME,
      interactionType: ParentFieldInteractionType.GROUP_SELECT_OPTIONS,
      fieldLabelByParentValue: { us: 'State', ca: 'Province', mx: 'State/Region' },
    };
    formField.options = getStateOptionsForCountries(COUNTRY_CODES);
  }

  if (formField.name === CITY_FIELD_NAME || formField.name === ZIP_FIELD_NAME) {
    formField.interactionParent = {
      fieldName: COUNTRY_FIELD_NAME,
      interactionType: ParentFieldInteractionType.TOGGLE,
      valuesToMakeChildVisible: COUNTRY_CODES,
    };
  }

  // traverse all keys in the field object, each one is a property of the field
  Object.entries(rawFieldData).forEach(([propertyName, value]) => {
    // if the property starts with a #, then it is a property of the field, if not, it is a child field we need to parse
    const isChildFieldProperty = !propertyName.startsWith('#');
    if (isChildFieldProperty) {
      const childField: ParsedWebformField | null = parseFieldFromRaw(
        value as WebformRawField,
        propertyName
      );
      if (!childField) {
        return;
      }
      formField.children?.push(childField);
    }
  });

  return formField;
};

export const useWebform = (
  webformField?: any | null // TODO: track down this type from Pet finder
): { webform: ParsedWebform; getFieldDataByName: Function } => {
  const webform: ParsedWebform = {
    fields: [] as ParsedWebformField[],
    title: webformField?.title || '',
    webformId: webformField?.drupal_internal__id || '',
    confirmationSettings: {
      title: webformField?.settings?.confirmation_title || '',
      message: webformField?.settings?.confirmation_message || '',
    },
  };

  const webformElementsYaml = webformField?.elements;
  const webformElementsRaw: WebformRaw = YAML.parse(webformElementsYaml || '') as WebformRaw;

  // traverse all keys in the raw object each one is a field
  Object.entries(webformElementsRaw).forEach(([propertyName, valueOfProperty]) => {
    const field: ParsedWebformField | null = parseFieldFromRaw(valueOfProperty, propertyName);

    if (!field) {
      return;
    }

    // There is a special field that is used to display a button to toggle form visibility.
    // This field should not be rendered with the standard field logic.
    if (field.name === SHOW_FORM_KEY) {
      webform.showFormField = field;
      return;
    }

    const headerField = field.children?.find(x => x.name === HEADER_TEXT_KEY);
    if (headerField) {
      webform.headerField = headerField;
    }

    webform.fields.push(field);
  });

  const getFieldDataByName = (name: string) => {
    return webform.fields.find((field: ParsedWebformField) => field.name === name) || {};
  };

  return {
    webform,
    getFieldDataByName,
  };
};
