import { useEffect, useState } from 'react';

const useScrollTracker = (
  trackScrollDepths?: number[],
  minScrollDepth?: number,
  callback?: (params: {
    scrollY: number;
    scrollPercent: number;
    remainingDepths: Array<number>;
  }) => void,
): { scrollY: number } => {
  const [state, setState] = useState({
    scrollDepths: trackScrollDepths,
    scrollY: 0,
  });

  const { scrollDepths, scrollY } = state;

  useEffect(() => {
    if (typeof window === 'undefined' || window.scrollY === 0) {
      return;
    }

    // Levergage in Gatsby SSR to reset scroll depth.
    window.resetScrollDepth = () =>
      setState({
        scrollDepths: trackScrollDepths,
        scrollY: 0,
      });

    setState(oldState => ({
      ...oldState,
      scrollY: window.scrollY,
    }));
  }, [trackScrollDepths]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleScroll = () => {
      const h = document.documentElement;
      const b = document.body;

      const scrollTop = h.scrollTop || b.scrollTop;
      const scrollHeight = h.scrollHeight || b.scrollHeight;
      const { clientHeight } = h;

      if (minScrollDepth && scrollHeight - clientHeight < minScrollDepth) {
        (() => window.removeEventListener('scroll', handleScroll))();
      }

      const scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrollDepths) {
        const nextMinDepth = Math.min(...scrollDepths, scrollHeight);

        if (scrollPercent >= nextMinDepth) {
          const updatedScrollDepths = scrollDepths.filter(depth => depth !== nextMinDepth);

          if (updatedScrollDepths.length === 0) {
            (() => window.removeEventListener('scroll', handleScroll))();
          }

          if (callback) {
            callback({
              scrollY: nextMinDepth,
              scrollPercent,
              remainingDepths: updatedScrollDepths,
            });
          }

          setState({
            scrollY: nextMinDepth,
            scrollDepths: updatedScrollDepths,
          });
        }
      } else {
        setState({ ...state, scrollY: scrollPercent });
      }
    };

    window.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollDepths, scrollY, state]);

  return { scrollY };
};

export default useScrollTracker;
