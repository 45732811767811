import { styled } from '@linaria/react';
import { mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import * as React from 'react';
import { prepareAttribute } from '../utils/helpers';

const StyledLink = styled.a`
  > svg {
    margin-left: 5px;
  }
`;

interface AttributeProps {
  [key: string]: any;
}

interface LinkPropsExtended {}

export type LinkProps = Omit<GatsbyLinkProps<{}>, 'ref'> & LinkPropsExtended;

const Link: React.FC<LinkProps> = function ({ children, to, ...props }) {
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  const file = /\.[0-9a-z]+(?=\?|$)/i.test(to);

  const attributes: AttributeProps = {};
  // Rewrite attributes to valid html.
  Object.entries(props).forEach(attribute => {
    const key: string = prepareAttribute(attribute[0]);
    attributes[key] = attribute[1];
  });

  if (attributes.className) {
    attributes.className = `${attributes.className} js-track`;
  } else {
    attributes.className = 'js-track';
  }

  const withIcon = attributes?.icon;

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    if (file) {
      return (
        <StyledLink href={to} {...attributes}>
          {children}
        </StyledLink>
      );
    }

    if (props?.target !== '_blank') {
      return (
        <GatsbyLink to={to} {...attributes}>
          {children}
        </GatsbyLink>
      );
    }
  }

  return (
    <StyledLink href={to} {...attributes}>
      {children}
      {props?.target === '_blank' && !withIcon && (
        <Icon title="Open in new window" path={mdiOpenInNew} size={0.6} />
      )}
    </StyledLink>
  );
};
export default Link;
