import { styled } from '@linaria/react';
import React from 'react';
import ParagraphSearchForm from '../paragraphs/ParagraphSearchForm';

const StyledContainer = styled.div`
  position: relative;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  background: #f8faff;

  .search {
    width: 100%;
    padding: 20px;

    @media (min-width: 870px) {
      padding: 0;
      width: 800px;
    }
  }
`;

const SearchAdditionalBar: React.FC = () => {
  return (
    <StyledContainer>
      <ParagraphSearchForm
        props={{
          showSearch: true,
          showMenu: false,
          showCancel: false,
          setShowSearch: () => true,
        }}
      />
    </StyledContainer>
  );
};
export default SearchAdditionalBar;
