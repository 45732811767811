import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

// TODO: we need a proper svg for a page skeleton
const MyLoader: React.FC<React.SVGAttributes<SVGElement> & IContentLoaderProps> = function (props) {
  return (
    <ContentLoader
      speed={1.5}
      width="100%"
      viewBox="0 0 400 617"
      backgroundColor="#EDF0F8"
      foregroundColor="#e2e7f3"
      {...props}
    >
      <path d="M381.961 416H3.03947C1.36082 416 0 418.388 0 421.333V442.667C0 445.612 1.36082 448 3.03947 448H381.961C383.639 448 385 445.612 385 442.667V421.333C385 418.388 383.639 416 381.961 416Z" />
      <path d="M282.75 459H2.25C1.00736 459 0 461.388 0 464.333V485.667C0 488.612 1.00736 491 2.25 491H282.75C283.993 491 285 488.612 285 485.667V464.333C285 461.388 283.993 459 282.75 459Z" />
      <path d="M30.8412 513.501C30.6499 512.97 30.139 512.604 29.5519 512.577H20.3211L17.3908 503.926C17.1821 503.346 16.5959 502.969 15.9549 503.002C15.3274 502.998 14.7696 503.384 14.5776 503.954L11.6472 512.605H2.44584C1.82777 512.601 1.27634 512.976 1.0769 513.535C0.877456 514.094 1.07477 514.712 1.56673 515.069L9.03919 520.472L6.10881 529.18C5.9018 529.751 6.11107 530.385 6.62396 530.74C7.13684 531.094 7.83229 531.086 8.3359 530.719L16.16 525.12L24.0134 530.719C24.258 530.897 24.5559 530.995 24.8632 530.999C25.331 531.006 25.7728 530.794 26.0469 530.432C26.3211 530.069 26.3933 529.602 26.2405 529.18L23.3102 520.472L30.6361 515.041C31.031 514.617 31.1125 514.006 30.8412 513.501Z" />
      <path d="M65.8412 513.501C65.6499 512.97 65.139 512.604 64.5519 512.577H55.3211L52.3908 503.926C52.1821 503.346 51.5959 502.969 50.9549 503.002C50.3274 502.998 49.7696 503.384 49.5776 503.954L46.6472 512.605H37.4458C36.8278 512.601 36.2764 512.976 36.0769 513.535C35.8775 514.094 36.0748 514.712 36.5667 515.069L44.0392 520.472L41.1088 529.18C40.9018 529.751 41.1111 530.385 41.624 530.74C42.1369 531.094 42.8323 531.086 43.3359 530.719L51.16 525.12L59.0134 530.719C59.258 530.897 59.5559 530.995 59.8632 530.999C60.331 531.006 60.7728 530.794 61.0469 530.432C61.3211 530.069 61.3933 529.602 61.2405 529.18L58.3102 520.472L65.6361 515.041C66.031 514.617 66.1125 514.006 65.8412 513.501Z" />
      <path d="M135.842 513.501C135.65 512.97 135.139 512.604 134.552 512.577H125.321L122.39 503.926C122.182 503.346 121.596 502.969 120.955 503.002C120.328 502.998 119.77 503.384 119.578 503.954L116.647 512.605H107.446C106.828 512.601 106.277 512.976 106.077 513.535C105.877 514.094 106.075 514.712 106.567 515.069L114.039 520.472L111.108 529.18C110.902 529.751 111.111 530.385 111.624 530.74C112.137 531.094 112.833 531.086 113.336 530.719L121.16 525.12L129.014 530.719C129.258 530.897 129.556 530.995 129.863 530.999C130.331 531.006 130.772 530.794 131.047 530.432C131.321 530.069 131.393 529.602 131.24 529.18L128.31 520.472L135.636 515.041C136.031 514.617 136.112 514.006 135.842 513.501Z" />
      <path d="M170.842 513.501C170.65 512.97 170.139 512.604 169.552 512.577H160.321L157.39 503.926C157.182 503.346 156.596 502.969 155.955 503.002C155.328 502.998 154.77 503.384 154.578 503.954L151.647 512.605H142.446C141.828 512.601 141.277 512.976 141.077 513.535C140.877 514.094 141.075 514.712 141.567 515.069L149.039 520.472L146.109 529.18C145.902 529.751 146.111 530.385 146.624 530.74C147.137 531.094 147.833 531.086 148.336 530.719L156.16 525.12L164.014 530.719C164.258 530.897 164.556 530.995 164.863 530.999C165.331 531.006 165.772 530.794 166.047 530.432C166.321 530.069 166.393 529.602 166.24 529.18L163.31 520.472L170.636 515.041C171.031 514.617 171.112 514.006 170.842 513.501Z" />
      <path d="M100.842 513.501C100.65 512.97 100.139 512.604 99.5515 512.577H90.3211L87.3907 503.926C87.1821 503.346 86.5958 502.969 85.9549 503.002C85.3274 502.998 84.7696 503.384 84.5776 503.954L81.6472 512.605H72.4458C71.8278 512.601 71.2764 512.976 71.0769 513.535C70.8775 514.094 71.0748 514.712 71.5667 515.069L79.0391 520.472L76.1088 529.18C75.9018 529.751 76.111 530.385 76.624 530.74C77.1369 531.094 77.8323 531.086 78.3359 530.719L86.16 525.12L94.0133 530.719C94.2579 530.897 94.5556 530.995 94.8631 530.999C95.3309 531.006 95.7725 530.794 96.0471 530.432C96.3209 530.069 96.3931 529.602 96.2403 529.18L93.3101 520.472L100.636 515.041C101.031 514.617 101.112 514.006 100.842 513.501Z" />
      <path d="M375.177 568H25.8229C12.1135 568 1 578.745 1 592C1 605.255 12.1135 616 25.8229 616H375.177C388.886 616 400 605.255 400 592C400 578.745 388.886 568 375.177 568Z" />
      <rect width="400" height="400" rx="15" />
    </ContentLoader>
  );
};

export default MyLoader;
