/* eslint-disable react/no-unknown-property */
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  widgetID?: string | null;
  sku?: string;
}

const Widget = function ({ widgetID = null, sku = '', className, ...rest }: Props) {
  useEffect(() => {
    if (typeof window !== 'undefined' && window?.PriceSpider?.debouncedRebind) {
      window?.PriceSpider?.debouncedRebind();
    }
  }, []);

  const pluginData = useStaticQuery(graphql`
    query {
      sitePlugin(name: { eq: "gatsby-plugin-pricespider" }) {
        pluginOptions
      }
    }
  `);
  const { defaultWidgetID } = pluginData.sitePlugin.pluginOptions;

  return (
    <div
      className={`ps-widget ${className} js-track`}
      ps-sku={sku}
      ps-config={widgetID || defaultWidgetID}
      {...rest}
    />
  );
};

export default Widget;
