import { PageProps, graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Widget from 'gatsby-plugin-pricespider/Widget';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface Image {
  gatsbyImage: IGatsbyImageData;
}

interface LandingPageProps extends NodeContent {
  image: ImageMetadata;
  widget: {
    default_sku: string;
    widget_id: string;
  };
  relationships: {
    image: Image;
  };
}

interface DataProps {
  node: LandingPageProps;
  translations: Translations;
}

const PSLandingPageTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, translations },
}) {
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const image = node.relationships?.image?.gatsbyImage;
  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <Container>
        <h1 className="text-center my-4">{node.title}</h1>
        <Row className="py-4">
          {image && (
            <Col xs={12} md={6}>
              <GatsbyImage
                alt={node.image?.alt}
                image={image}
                style={{ height: 'auto', width: '100%' }}
              />
            </Col>
          )}
          <AnalyticsPoint
            type="module"
            typeLabel="ps_landing_widget"
            label="price spider landing page widget"
            as={Col}
            xs={12}
            md={image ? 6 : 12}
          >
            <Widget sku={node.widget.default_sku} widgetID={node.widget.widget_id} />
          </AnalyticsPoint>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodePricespiderLandingPage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      title
      langcode
      path {
        alias
      }
      ...Metatag
      image: field_pricespider_social_image {
        alt
        height
        width
      }
      widget: field_pricespider_widget_config {
        default_sku
        widget_id
      }
      relationships {
        image: field_pricespider_social_image {
          gatsbyImage(height: 1080, width: 1080, formats: AUTO, placeholder: NONE)
        }
      }
    }
    translations: allNodePricespiderLandingPage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
  }
`;

export default PSLandingPageTemplate;
