import { useLocation } from '@reach/router';
import { AxiosError } from 'axios';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SearchListing from '../../components/search_listing/SearchListing';
import StickyFacetContainer from '../../components/search_listing/StickyFacetContainer';
import { handleFacetChange } from '../../utils/helpers';

interface Base {
  error: unknown;
  isFetching: boolean;
  contextualFilters?: SearchParams;
  defaultLoadedState?: boolean;
}

interface Api extends Base {
  data: DrupalSearch | undefined;
  serverData?: DrupalSearch;
}

interface Server extends Base {
  data: DrupalSearch | undefined;
  serverData: DrupalSearch;
}

const BreedListingBody: React.FC<Server | Api> = ({
  data,
  serverData,
  error,
  contextualFilters,
  defaultLoadedState,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [location]);

  return (
    <Container className="my-5">
      <SearchListing
        data={data}
        error={(error as AxiosError) || new Error(t('Sorry, there was an error'))}
        loaded={!isLoading}
        defaultLoadedState={defaultLoadedState}
      >
        <Row className="align-items-center py-3">
          <SearchListing.SearchHeader
            title={t('Breeds')}
            onFacetRemove={e =>
              handleFacetChange(e, location, navigate, contextualFilters, setIsLoading)
            }
          />
        </Row>
        <Row className="position-relative">
          <Col lg={3} className="py-3 d-none d-lg-block">
            <StickyFacetContainer>
              <SearchListing.SearchFacets
                onChange={e =>
                  handleFacetChange(e, location, navigate, contextualFilters, setIsLoading)
                }
              />
            </StickyFacetContainer>
          </Col>
          <Col>
            <SearchListing.BreedSearchResults serverData={serverData} />
            <SearchListing.Pagination serverData={serverData} />
          </Col>
          <SearchListing.FacetModal
            onChange={e => handleFacetChange(e, location, navigate, contextualFilters)}
          />
        </Row>
      </SearchListing>
    </Container>
  );
};
export default BreedListingBody;
