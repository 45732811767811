import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../Link';

interface Props extends Media {
  relationships: {
    field_media_image: {
      gatsbyImage: IGatsbyImageData;
    };
    field_media_pdf: {
      url: string;
    };
  };
  field_media_image: {
    alt: string;
  };
}

const MediaInfographic = function (media: Props) {
  const { t } = useTranslation();
  const infographicImage = media.relationships?.field_media_image?.gatsbyImage;
  const url = media.relationships?.field_media_pdf?.url;
  const infographicImageAlt = media.field_media_image?.alt;

  if (!infographicImage) {
    return null;
  }

  return (
    <>
      <GatsbyImage
        alt={infographicImageAlt}
        image={infographicImage}
        style={{ borderRadius: '10px' }}
      />
      <div className="py-3 text-center">
        {url && (
          <Link target="_blank" to={url}>
            {t('Download Infographic')} [PDF]
          </Link>
        )}
      </div>
    </>
  );
};

export default MediaInfographic;
