import { createContext } from 'react';

export interface ISearchListingContext {
  id: string;
  data?: DrupalSearch;
  loaded: boolean;
  error: Error | null;
  facetModalActive: boolean;
  setFacetModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchListingContext = createContext<ISearchListingContext | undefined>(undefined);
