/* eslint-disable complexity */
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import Icon from '@mdi/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React, { ReactNode } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from '../../common/enums';
import { StarEmpty, StarFilled } from '../../theme/icons';
import { theme } from '../../theme/theme';
import { ButtonLink } from '../button/Button';
import PricespiderWidget from '../pricespider/PricespiderWidget';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  cmsTheme: CMS_THEME;
  title: string;
  review: string;
  author: string;
  rating: number;
  alignment?: 'left' | 'right';
  isVertical: boolean;
  imgComponent?: React.ReactNode;
  imgCover: boolean;
  sku?: string;
  reviewLink?: string;
}

const RatingStars = ({ rating }: { rating: number }) => {
  const stars = [];

  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<Icon key={i} path={StarFilled} size={1.5} style={{ color: '#E7BF2E' }} />);
    } else {
      stars.push(
        <Icon
          key={i}
          path={StarEmpty}
          size={1.5}
          style={{ stroke: '#E4E9F4', color: '#E4E9F4' }}
        />,
      );
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{stars}</>;
};

const ButtonContainer = styled(Stack)<{ hasImage: ReactNode; hasBothButtons: boolean }>`
  > * {
    width: 100%;
    @media (min-width: ${theme.media.lg}) {
      width: ${({ hasImage, hasBothButtons }) => {
        if (hasBothButtons && hasImage) {
          return 'calc(50% - 20px)';
        }
        if (hasImage) {
          return '100%';
        }
        return 'auto';
      }};
    }
  }
`;

const ImageContainer = styled.div<{ direction: string; cmsTheme: CMS_THEME }>`
  display: flex;
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.imageMatte};
  border-radius: 1rem;
  border-bottom-left-radius: ${props => (props?.direction === 'vertical' ? '0' : '1rem')};
  border-bottom-right-radius: ${props => (props?.direction === 'vertical' ? '0' : '1rem')};
  justify-content: center;
  min-width: 300px;
  height: 318px;
  overflow: hidden;
  padding: 0;
  width: 100%;
`;

const Wrapper = styled(Stack)<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StyledCard = styled.figure<{ cmsTheme: CMS_THEME; isVertical: boolean; isMobile: boolean }>`
  background: ${({ cmsTheme, isMobile, isVertical }) =>
    isMobile || isVertical ? theme[cmsTheme].background.paper : 'transparent'};
`;

const ReviewBtn = styled(ButtonLink)`
  width: 100%;
`;

const ProductReview = ({
  cmsTheme = CMS_THEME.LIGHT,
  title,
  review,
  author,
  rating,
  alignment = 'left',
  isVertical = false,
  imgComponent,
  imgCover = false,
  sku,
  reviewLink,
  ...props
}: Props) => {
  const breakpoint = useBreakpoint();
  const isMobile = !breakpoint.lg;
  const starRating = Math.min(Math.max(rating, 0), 5);
  const direction = isVertical || isMobile ? 'vertical' : 'horizontal';
  const contentAlignment = !imgComponent || isVertical ? 'center' : alignment;
  const hasBothButtons = Boolean(sku && reviewLink);
  const { t } = useTranslation();

  return (
    <Wrapper
      cmsTheme={cmsTheme}
      direction={direction}
      gap={direction === 'horizontal' ? 5 : 0}
      {...props}
    >
      {imgComponent && (
        <ImageContainer
          cmsTheme={cmsTheme}
          direction={direction}
          className={cx(
            direction === 'vertical' ? 'w-100' : null,
            !isMobile && contentAlignment === 'left' ? 'order-1' : null,
          )}
        >
          {imgComponent}
        </ImageContainer>
      )}
      <StyledCard
        cmsTheme={cmsTheme}
        isMobile={isMobile}
        isVertical={isVertical}
        className={cx(
          'vstack',
          `flex-grow-${isVertical ? 1 : 0} rounded-bottom`,
          imgComponent ? null : 'text-center',
          direction === 'vertical' ? 'p-4 p-sm-5' : null,
        )}
        as="figure"
      >
        <div>
          <RatingStars rating={starRating} />
          &nbsp;
          <span aria-hidden className="text-nowrap">
            {t('{{starRating}} out of 5 stars', { starRating })}
          </span>
        </div>
        <span className="typography_h3 my-3">
          {title}
          <span className="sr-only">{t(': {{starRating}} out of 5', { starRating })}</span>
        </span>
        <q
          className="typography_body fw-light my-3"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: review }}
        />
        <figcaption className={`typography_body ${sku || reviewLink ? 'mb-4' : null}`}>
          &mdash;&nbsp;{author}
        </figcaption>
        {(sku || reviewLink) && (
          <ButtonContainer
            direction={isMobile ? 'vertical' : 'horizontal'}
            gap={3}
            hasImage={imgComponent}
            hasBothButtons={hasBothButtons}
            className={cx('flex-wrap mt-auto', !imgComponent ? 'justify-content-center' : null)}
          >
            {sku && (
              <div>
                <PricespiderWidget title={title} sku={sku} cmsTheme={cmsTheme} />
              </div>
            )}
            {Boolean(reviewLink) && (
              <AnalyticsPoint type="component" typeLabel="product_review" label="read more reviews">
                <ReviewBtn variant="btn-outline" to={`${reviewLink}#reviews`} cmsTheme={cmsTheme}>
                  {t('Read more reviews')}
                </ReviewBtn>
              </AnalyticsPoint>
            )}
          </ButtonContainer>
        )}
      </StyledCard>
    </Wrapper>
  );
};

export default ProductReview;
