import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RichText from '../../../rich_text/RichText';
// eslint-disable-next-line import/no-cycle
import { IContactUsFormData } from '../../ContactUsForm';
// eslint-disable-next-line import/no-cycle
import RecaptchaDisclaimer from '../RecaptchaDisclaimer';

interface IConsentSectionFormProps {
  getFieldDataByName: Function;
}

const ConsentSection = ({ getFieldDataByName }: IConsentSectionFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IContactUsFormData>();
  const { t } = useTranslation();

  return (
    <>
      <>
        <Form.Label>{t('My pets')}</Form.Label>
        <Col md={4} xs={12}>
          <Form.Group controlId="ownedDogs">
            <Form.Select {...register('ownedDogs')}>
              <option key="nd-default" value={0}>
                {getFieldDataByName('number_of_dogs')?.title}
              </option>
              {getFieldDataByName('number_of_dogs')?.options?.map((opt: any) => (
                <option key={`${opt.value} owned dogs`} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Form.Select>
            {errors?.ownedDogs && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {errors.ownedDogs.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group controlId="ownedCats">
            <Form.Select {...register('ownedCats')}>
              <option key="nc-default" value={0}>
                {getFieldDataByName('number_of_cats')?.title}
              </option>
              {getFieldDataByName('number_of_cats')?.options?.map((opt: any) => (
                <option key={`${opt.value} owned cats`} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </>
      <Col md={12}>
        <Form.Group controlId="emailSubscribingConsent" className="d-flex gap-2">
          <Form.Check
            type="checkbox"
            aria-describedby="emailSubscribingConsent-message"
            isInvalid={!!errors.emailSubscribingConsent}
            aria-invalid={!!errors.emailSubscribingConsent}
            label={
              getFieldDataByName('agree_box')?.description ? (
                <RichText body={getFieldDataByName('agree_box')?.description} />
              ) : null
            }
            {...register('emailSubscribingConsent')}
          />
          {errors.emailSubscribingConsent && (
            <Form.Control.Feedback
              id="emailSubscribingConsent-message"
              className="d-block"
              type="invalid"
            >
              {errors.emailSubscribingConsent?.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      {getFieldDataByName('legal_text')?.text && (
        <RichText body={getFieldDataByName('legal_text')?.text} />
      )}
      <Col xs={12}>
        <RecaptchaDisclaimer />
      </Col>
    </>
  );
};

export default ConsentSection;
