import { AxiosError } from 'axios';
import { PageProps, graphql } from 'gatsby';
import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { getBreedSearchData } from 'src/ssr/getSearchData';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import RichText from '../../components/rich_text/RichText';
import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';
import BreedListingBody from '../components/BreedListingBody';
import { BreedSD } from './types/types';

interface IBreedListingPage extends NodeContent {
  relationships?: {
    breed_collection?: TaxonomyTerm[];
    species?: TaxonomyTerm;
  };
}

interface DataProps {
  node: IBreedListingPage;
  translations: Translations;
}

const BreedListingTemplate: React.FC<PageProps<DataProps, any, any, BreedSD>> = function ({
  data: { node, translations },
  serverData,
}) {
  const { t } = useTranslation();
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <Container fluid className="bg-gray-100 py-5 text-center">
        <h1>{node.title}</h1>
        {node.body?.processed && <RichText body={node.body.processed} />}
      </Container>
      <BreedListingBody
        data={serverData.breedData}
        serverData={serverData.breedData}
        error={new Error(t('Sorry, there was an error'))}
        contextualFilters={serverData.contextualFilters}
        isFetching={false}
        defaultLoadedState
      />
    </Layout>
  );
};

export const getServerData = async (props: any) => {
  try {
    const breedData = await getBreedSearchData(props.pageContext, props.query);
    return {
      props: {
        breedData: breedData.data,
        contextualFilters: breedData.contextualFilters,
        error: null,
      },
    };
  } catch (e) {
    return {
      props: {
        error: (e as AxiosError).response,
        breedData: null,
      },
    };
  }
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeBreedListingPage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      title
      path {
        alias
      }
      body {
        processed
      }
      langcode
      ...Metatag
      relationships {
        species: field_species_term {
          drupal_internal__tid
        }
        breed_collection: field_breed_collection {
          drupal_internal__tid
        }
      }
    }
    translations: allNodeBreedListingPage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default BreedListingTemplate;
