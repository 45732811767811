import { styled } from '@linaria/react';
import React, { forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import { FormControlProps } from 'react-bootstrap/FormControl';
import ListGroup, { ListGroupProps } from 'react-bootstrap/ListGroup';
import ListGroupItem, { ListGroupItemProps } from 'react-bootstrap/ListGroupItem';

export const ComboboxInput = styled(
  forwardRef<HTMLInputElement, FormControlProps>((props, ref) => (
    <Form.Control {...props} ref={ref} as="input" />
  )),
)`
  background: #edf0f8;
  border-radius: 60px;
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;

  &:focus {
    background: #edf0f8;
  }
`;
export const ComboboxListGroup = styled(
  forwardRef<HTMLUListElement, ListGroupProps>((props, ref) => (
    <ListGroup {...props} ref={ref} as="ul" />
  )),
)`
  display: block;
  border: 1px solid rgba(76, 76, 76, 0.3);
  border-radius: 1rem;
  height: auto;
  max-height: 500px;
  overflow: auto;

  &:empty {
    border: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    border: 0;
  }

  li {
    border-left: 0;
    border-right: 0;
  }

  ul:first-child > li:first-of-type {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  ul:last-child > li:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
export const ComboboxListGroupTitle = styled(
  React.forwardRef<HTMLLIElement, ListGroupItemProps>((props, ref) => (
    <ListGroupItem {...props} ref={ref} as="li" />
  )),
)`
  background: #e3e7f2;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.08rem;
  padding: 1rem;
  text-transform: uppercase;
  width: 100%;
`;
export const ComboboxListItem = styled(
  React.forwardRef<HTMLLIElement, ListGroupItemProps>((props, ref) => (
    <ListGroupItem {...props} ref={ref} as="li" />
  )),
)`
  background: #fff;
  cursor: pointer;
  padding: 1rem 2.5rem 1rem 2.5rem;
  text-transform: capitalize;
  width: 100%;

  &:hover,
  &:focus {
    background-color: #2553f8;
    color: #fff;
  }

  &.active {
    background-color: #2b2b2b;
    color: #fff;
    font-weight: bold;
  }
`;
export const ComboboxCancel = styled.button`
  background: transparent;
  border: 0;
  margin-right: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    text-decoration: underline;
  }
`;
