import { PageProps, graphql } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Seo from 'src/components/Seo';
import Layout from '../../components/Layout';
import Link from '../../components/Link';
import ThemedCard from '../../components/card/ThemedCard';
import RichText from '../../components/rich_text/RichText';
import { theme } from '../../theme/theme';
import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';

interface IBreedNode {
  id: string;
  body: IBody;
  title: string;
  path: IPath;
}

interface ICollections {
  node: IBreedNode;
}

interface CollectionsProps {
  edges: ICollections[];
}

interface DataProps {
  collections: CollectionsProps;
  translations: Translations;
  species: string;
  node: NodeContent;
}

const StyledLink = styled(Link)`
  color: ${theme.common.common.black};
  text-decoration: none;

  a,
  h2 {
    color: ${theme.common.common.black};
    text-decoration: none;

    &:hover {
      color: ${theme.common.common.black};
    }
  }

  &:hover h2 {
    text-decoration: underline;
  }
`;

const CollectionCard = styled(ThemedCard)`
  border: 2px solid transparent;

  &:hover {
    border: 2px solid ${theme.common.gray[400]};
  }
`;

const BreedCollectionsListingTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { collections, node, translations },
}) {
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  return (
    <Layout language={node.langcode} translations={translations}>
      <AnalyticsPoint type="page" pageType="breed_collection_listing">
        <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
        <Container fluid className="bg-gray-100 py-5 text-center">
          <h1>{node.title}</h1>
          {node.body?.processed && <RichText body={node.body.processed} />}
        </Container>
        <Container>
          <Row className="my-5">
            {collections.edges.map(collection => (
              <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
                <StyledLink to={collection.node.path.alias || ''}>
                  <CollectionCard className={cx('p-3 h-100 d-flex flex-column')}>
                    <h2 className="typography_h4">{collection.node.title}</h2>
                    {collection.node.body.processed && (
                      <RichText className="mb-4" body={collection.node.body.processed} />
                    )}
                  </CollectionCard>
                </StyledLink>
              </Col>
            ))}
          </Row>
        </Container>
      </AnalyticsPoint>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $species: Int!) {
    node: nodeComplex(id: { eq: $id }, langcode: { eq: $language }) {
      id
      title
      path {
        alias
      }
      body {
        processed
      }
      langcode
      ...Metatag
    }
    collections: allNodeBreedListingPage(
      filter: { field_species_term: { drupal_internal__target_id: { eq: $species } }, langcode: { eq: $language } }
      sort: { title: ASC }
    ) {
      edges {
        node {
          id
          path {
            alias
          }
          title
          body {
            processed
          }
        }
      }
    }
    translations: allNodeBreedListingPage(filter: { langcode: { ne: $language } }) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
  }
`;

export default BreedCollectionsListingTemplate;
