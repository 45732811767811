import React from 'react';

type Props = {
  onClick: React.MouseEventHandler;
};

const apiEndpoint = process.env.GATSBY_DRUPAL_SEARCH_ENDPOINT || '';

const ClearAll: React.FC<Props> = function ({ children, onClick }) {
  const clearAllClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onClick(event);
  };

  return (
    <a href="#" data-url={`${apiEndpoint}api/search`} role="button" onClick={clearAllClick}>
      {children}
    </a>
  );
};

export default ClearAll;
