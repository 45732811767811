import { styled } from '@linaria/react';
import * as React from 'react';

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin-bottom: 0.5rem;
  }
  .icon {
    display: inline-block;
    margin-right: 0.5rem;
  }
  &.small .icon {
    width: 24px;
    height: 24px;
  }
  &.large .icon {
    width: 32px;
    height: 32px;
  }
`;

interface IconListProps {
  items: { text: string; icon: { url: string; alt: string } }[];
  size?: 'small' | 'large';
}

const IconList: React.FC<IconListProps> = function ({ items, size = 'small' }) {
  return (
    <StyledList className={size}>
      {items.map((item, index) => {
        return (
          <li key={index}>
            <img src={item.icon.url.svg.url} className="icon" alt={item.icon?.alt} />
            {item.text}
          </li>
        );
      })}
    </StyledList>
  );
};

export default IconList;
