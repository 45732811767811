import { css, cx } from '@linaria/core';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import SwiperCore, { A11y, Navigation, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { theme } from '../../theme/theme';

type Props = {
  className?: string;
  breakpointSettings?: SwiperOptions['breakpoints'];
};

const customSwiper = css`
  &.fs-offset,
  &.fs-offset-fluid,
  &.fs-offset-xxl,
  &.fs-offset-xl,
  &.fs-offset-lg,
  &.fs-offset-md,
  &.fs-offset-sm {
    padding-inline: 0.75rem;
  }

  &.fs-no-offset {
    padding-inline: 0;
  }

  @media (min-width: ${theme.media.sm}) {
    &.fs-offset-sm,
    &.fs-offset {
      .swiper-slide.swiper-slide-active:first-child {
        margin-left: calc((100vw - 540px) / 2);
      }
    }
  }
  @media (min-width: ${theme.media.md}) {
    &.fs-offset-md,
    &.fs-offset-sm,
    &.fs-offset {
      .swiper-slide.swiper-slide-active:first-child {
        margin-left: calc((100vw - 720px) / 2);
      }
    }
  }
  @media (min-width: ${theme.media.lg}) {
    &.fs-offset-lg,
    &.fs-offset-md,
    &.fs-offset-sm,
    &.fs-offset {
      .swiper-slide.swiper-slide-active:first-child {
        margin-left: calc((100vw - 960px) / 2);
      }
    }
  }
  @media (min-width: ${theme.media.xl}) {
    &.fs-offset-xl,
    &.fs-offset-lg,
    &.fs-offset-md,
    &.fs-offset-sm,
    &.fs-offset {
      .swiper-slide.swiper-slide-active:first-child {
        margin-left: calc((100vw - 1140px) / 2);
      }
    }
  }
  @media (min-width: ${theme.media.xxl}) {
    &.fs-offset-xxl,
    &.fs-offset-xl,
    &.fs-offset-lg,
    &.fs-offset-md,
    &.fs-offset-sm,
    &.fs-offset {
      .swiper-slide.swiper-slide-active:first-child {
        margin-left: calc((100vw - 1320px) / 2);
      }
    }
  }

  @media (min-width: 1680px) {
    &.fs-offset-xxl,
    &.fs-offset-xl,
    &.fs-offset-lg,
    &.fs-offset-md,
    &.fs-offset-sm,
    &.fs-offset {
      .swiper-slide.swiper-slide-active:first-child {
        margin-left: calc((100vw - 1600px) / 2);
      }
    }
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-button-next,
  .swiper-button-prev {
    align-items: center;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.12);
    color: #323232;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-top: 0;
    transform: translateY(-50%);
    width: 50px;

    &:hover {
      box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.2);
      background-color: #edf0f8;
    }

    > svg {
      width: 25px;
    }

    &:after {
      content: '';
      display: none;
    }
  }

  .swiper-notification {
    visibility: hidden;
  }
`;

SwiperCore.use([Navigation, A11y]);

const Filmstrip: React.FC<Props> = function ({ children, ...props }) {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const slides = React.Children.toArray(children);

  const initSwiper = (swiper: SwiperCore) => {
    if (swiper && navigationNextRef.current && navigationPrevRef.current) {
      // eslint-disable-next-line no-param-reassign
      swiper.params.navigation = {
        ...(swiper.params.navigation as Object),
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      };

      const DefaultSettings = {
        breakpoints: {
          0: {
            simulateTouch: true,
            slidesPerView: 1.2,
            spaceBetween: 16,
            centeredSlides: false,
            navigation: {
              enabled: true,
              hiddenClass: 'd-none',
              navigationDisabledClass: 'd-none',
              disabledClass: 'd-none',
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            },
          },
          576: {
            simulateTouch: true,
            slidesPerView: 3,
            spaceBetween: 16,
            centeredSlides: false,
            navigation: {
              enabled: true,
              hiddenClass: 'd-none',
              navigationDisabledClass: 'd-none',
              disabledClass: 'd-none',
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            },
          },
          1024: {
            simulateTouch: true,
            slidesPerView: 5,
            spaceBetween: 16,
            centeredSlides: false,
            navigation: {
              enabled: true,
              hiddenClass: 'd-none',
              navigationDisabledClass: 'd-none',
              disabledClass: 'd-none',
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            },
          },
        },
      };

      // eslint-disable-next-line no-param-reassign
      swiper.params.breakpoints = {
        0: {
          ...DefaultSettings.breakpoints?.[0],
          ...props?.breakpointSettings?.[0],
        },
        576: {
          ...DefaultSettings.breakpoints?.[576],
          ...props?.breakpointSettings?.[576],
        },
        1024: {
          ...DefaultSettings.breakpoints?.[1024],
          ...props?.breakpointSettings?.[1024],
        },
      };
    }
  };

  return (
    <Swiper
      className={cx(customSwiper, props.className)}
      navigation={{
        enabled: true,
        hiddenClass: 'd-none',
        navigationDisabledClass: 'd-none',
        disabledClass: 'd-none',
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      preloadImages={false}
      keyboard
      onBeforeInit={initSwiper}
      onResize={initSwiper}
    >
      {React.Children.map(slides, (child, index) => {
        return <SwiperSlide>{child}</SwiperSlide>;
      })}
      <div ref={navigationPrevRef} className="swiper-button-prev">
        <Icon path={mdiArrowLeft} />
      </div>
      <div ref={navigationNextRef} className="swiper-button-next">
        <Icon path={mdiArrowRight} />
      </div>
    </Swiper>
  );
};

export default Filmstrip;
