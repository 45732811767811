import { mdiClose, mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
import { useLocation } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { CMS_THEME } from '../../common/enums';
import { ButtonLink } from '../button/Button';
import {
  LanguageSelectButton,
  LanguageSelectorContainer,
  StyledPopover,
  UnstyledButton,
} from './style';

interface LanguageSelectorProps {
  language: Languages;
  shouldDisplay?: boolean;
}

const languageLabel: Record<string, string> = {
  en: 'English',
  fr: 'Français',
};

const LanguageSelector = ({ language, shouldDisplay = true }: LanguageSelectorProps) => {
  const { languages } = useI18next();

  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();

  const frenchLangcodeFragmentRegex = /^\/(fr)\/?/;
  const cleanedPathName =
    language === 'fr'
      ? location.pathname.replace(frenchLangcodeFragmentRegex, '/')
      : location.pathname;

  if (!shouldDisplay) return null;

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      show={show}
      overlay={
        <StyledPopover>
          <Popover.Body
            style={{
              padding: '0',
            }}
          >
            <LanguageSelectorContainer>
              <div className="header">
                <div
                  style={{
                    width: '100px',
                  }}
                >
                  <StaticImage
                    src="../../assets/media/images/logo.png"
                    alt="logo"
                    className="mt-3"
                  />
                </div>
                <UnstyledButton onClick={handleClose}>
                  <Icon path={mdiClose} size={1} />
                </UnstyledButton>
              </div>

              <div className="content">
                <h6>
                  <Icon path={mdiWeb} size={1} /> Select Language
                </h6>
                {languages.map((lang, index) => (
                  <ButtonLink
                    key={index}
                    cmsTheme={CMS_THEME.LIGHT}
                    to={lang === 'en' ? cleanedPathName : `/${lang}${cleanedPathName}`}
                    shape="smallRadius"
                    variant="btn-outline"
                  >
                    {languageLabel[lang]}
                  </ButtonLink>
                ))}
              </div>
            </LanguageSelectorContainer>
          </Popover.Body>
        </StyledPopover>
      }
    >
      <div onClick={handleShow} onKeyDown={handleShow} role="button" tabIndex={0}>
        <LanguageSelectButton className="d-none d-xxl-flex">
          <span className="d-sm-none d-lg-inline-block">{languageLabel[language]}</span>
          <Icon path={mdiWeb} size={1} />
        </LanguageSelectButton>

        <Button
          variant="link"
          aria-controls="mainMenu"
          className="rounded-pill bg-light p-2 d-xxl-none ms-2 text-decoration-none justify-content-center"
          style={{
            height: '48px',
            width: '48px',
            color: '#2B2B2B',
          }}
        >
          <Icon path={mdiWeb} size={1} />
        </Button>

        {/* including hidden anchor tags to provide crawlers with translated url paths */}
        {languages.map(lang => (
          <a
            className="d-none"
            href={lang === 'en' ? cleanedPathName : `/${lang}${cleanedPathName}`}
          >
            {languageLabel[lang]}
          </a>
        ))}
      </div>
    </OverlayTrigger>
  );
};

export default LanguageSelector;
