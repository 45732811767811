import { useMemo } from 'react';
import { pollObjectOnWindow } from 'src/utils/helpers';

type Props = {
  token: string;
};

const BazaarvoiceDCC = function ({ token }: Props) {
  const isSSR = typeof window === 'undefined';
  const stringifiedData = useMemo(() => {
    return token;
  }, [token]);

  if (isSSR) return null;

  pollObjectOnWindow('BV', 50, 1000)
    .then(object => {
      object.pixel.trackEvent('CatalogUpdate', {
        type: 'Product',
        token: stringifiedData,
      });
    })
    .catch(error => {
      console.error('BV never loaded on window', error);
    });

  return null;
};

export default BazaarvoiceDCC;
