import { cx } from '@linaria/core';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef } from 'react';

const defaultStickyTop = 180;
const StickyFacetContainer: React.FC<React.HTMLAttributes<'div'>> = function ({
  className,
  ...props
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (ref.current) {
      const headerHeight = document.querySelector('header')?.offsetHeight || defaultStickyTop;
      ref.current.style.top = `calc(${headerHeight}px + 1rem)`;
    }

    const updateStickyPosition = debounce(() => {
      if (ref.current) {
        const headerHeight = document.querySelector('header')?.offsetHeight || defaultStickyTop;
        ref.current.style.top = `calc(${headerHeight}px + 1rem)`;
        const maxHeight = window.innerHeight - ref.current.getBoundingClientRect().top;
        ref.current.style.maxHeight = `${maxHeight}px`;
      }
    }, 300);

    window.addEventListener('resize', updateStickyPosition, { passive: true });
    window.addEventListener('scroll', updateStickyPosition, { passive: true });

    return () => {
      window.removeEventListener('resize', updateStickyPosition);
      window.removeEventListener('scroll', updateStickyPosition);
    };
  }, [ref]);

  return (
    <div ref={ref} className={cx('position-sticky', className)} style={{ overflowY: 'auto' }}>
      {props.children}
    </div>
  );
};

export default StickyFacetContainer;
