import { css, cx } from '@linaria/core';
import React from 'react';

type Props = {
  containment?: 'explicit' | 'implicit';
  media?: JSX.Element;
  actions?: JSX.Element;
};

const BaseCard = css`
  --bs-card-border-radius: 1rem;
  --bs-card-inner-border-radius: 1rem;
  --bs-card-cap-padding-y: 1.5rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1.5rem;
  width: 100%;

  .card-img {
    background-color: #f8f9fc;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    text-align: center;
  }

  .card-footer {
    background-color: transparent;
    border-top: none;
  }
`;

const ImplicitCard = css`
  border: none;
  &.${BaseCard} {
    .card-img {
      background-color: #f8f9fc;
      border-bottom-left-radius: var(--bs-card-inner-border-radius);
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
    }

    .card-body,
    .card-footer {
      background-color: transparent;
      padding-inline: 0;
    }
  }
`;

const Card: React.FC<Props> = function ({ media, children, actions, containment = 'explicit' }) {
  return (
    <div className={cx('card', `${BaseCard}`, containment === 'implicit' && `${ImplicitCard}`)}>
      {media && <div className="card-img">{media}</div>}
      <div className="card-body">{children}</div>
      <div className="card-footer">{actions}</div>
    </div>
  );
};

export default Card;
