import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { validateRequired } from '../../../utils/formHelpers';
import { IProClubMemberFormData, ProClubMemberFormProps } from './types';
import { useTranslation } from 'react-i18next';

const ProfessionalInvolvement = ({ getFieldDataByName }: ProClubMemberFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    unregister,
    watch,
    formState: { errors },
  } = useFormContext<IProClubMemberFormData>();

  const participateInValue = watch('participateIn');

  useEffect(() => {
    if (participateInValue && participateInValue?.includes('Other')) {
      register('participateInOther');
    } else {
      unregister('participateInOther');
    }
  }, [register, unregister, participateInValue]);

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h5>{getFieldDataByName('step_2')?.text}</h5>
        </Col>
      </Row>
      <Row className="gx-3 gy-3">
        <Col xs={12} md={6}>
          <Form.Group controlId="workProfessionallyWithPets" className="required">
            <Form.Label>{getFieldDataByName('breed')?.title}</Form.Label>
            <div className="d-flex flex-column gap-2">
              <Form.Check
                type="radio"
                id="0"
                value={getFieldDataByName('breed')?.options[0].value}
                {...register('workProfessionallyWithPets', {
                  required: 'Please select one of these options.',
                })}
                label={getFieldDataByName('breed')?.options[0].label}
              />
              <Form.Check
                type="radio"
                id="1"
                value={getFieldDataByName('breed')?.options[1].value}
                {...register('workProfessionallyWithPets', {
                  required: 'Please select one of these options.',
                })}
                label={getFieldDataByName('breed')?.options[1].label}
              />
              <Form.Check
                type="radio"
                id="2"
                value={getFieldDataByName('breed')?.options[2].value}
                {...register('workProfessionallyWithPets', {
                  required: 'Please select one of these options.',
                })}
                label={getFieldDataByName('breed')?.options[2].label}
              />
            </div>
            {errors?.workProfessionallyWithPets && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.workProfessionallyWithPets.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group controlId="kenelCatteryName" className="required">
            <Form.Label>{getFieldDataByName('kennel_cattery_name')?.title}</Form.Label>
            <Form.Control
              {...register('kenelCatteryName', {
                required: validateRequired(getFieldDataByName('kennel_cattery_name')?.title, t),
              })}
              type="text"
              isInvalid={!!errors?.kenelCatteryName}
              aria-invalid={!!errors?.kenelCatteryName}
              placeholder={getFieldDataByName('kennel_cattery_name')?.title}
              aria-required="true"
            />
            {errors?.kenelCatteryName && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.kenelCatteryName.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="membershipNumber">
            <Form.Label>{getFieldDataByName('association_membership_number')?.title}</Form.Label>
            <Form.Control
              {...register('membershipNumber', {})}
              type="text"
              isInvalid={!!errors?.membershipNumber}
              aria-invalid={!!errors?.membershipNumber}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="kenelCatteryProfessionalWebsite">
            <Form.Label>{getFieldDataByName('kennel_cattery_website')?.title}</Form.Label>
            <Form.Control
              {...register('kenelCatteryProfessionalWebsite', {})}
              type="text"
              isInvalid={!!errors?.kenelCatteryProfessionalWebsite}
              aria-invalid={!!errors?.kenelCatteryProfessionalWebsite}
              placeholder=""
              maxLength={60}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={3}>
          <Form.Group controlId="petsPrimaryInvolvement" className="required">
            <Form.Label>{getFieldDataByName('involvement_choice')?.title}</Form.Label>
            <div className="d-flex flex-column gap-2">
              <Form.Check
                type="radio"
                id="0"
                value={getFieldDataByName('involvement_choice')?.options[0].value}
                {...register('petsPrimaryInvolvement', {
                  required: 'Please select one of these options.',
                })}
                label={getFieldDataByName('involvement_choice')?.options[0].label}
              />
              <Form.Check
                type="radio"
                id="1"
                value={getFieldDataByName('involvement_choice')?.options[1].value}
                {...register('petsPrimaryInvolvement', {
                  required: 'Please select one of these options.',
                })}
                label={getFieldDataByName('involvement_choice')?.options[1].label}
              />
              <Form.Check
                type="radio"
                id="2"
                value={getFieldDataByName('involvement_choice')?.options[2].value}
                {...register('petsPrimaryInvolvement', {
                  required: 'Please select one of these options.',
                })}
                label={getFieldDataByName('involvement_choice')?.options[2].label}
              />
              <Form.Check
                type="radio"
                id="3"
                value={getFieldDataByName('involvement_choice')?.options[3].value}
                {...register('petsPrimaryInvolvement', {
                  required: 'Please select one of these options.',
                })}
                label={getFieldDataByName('involvement_choice')?.options[3].label}
              />
              <Form.Check
                type="radio"
                id="4"
                value={getFieldDataByName('involvement_choice')?.options[4].value}
                {...register('petsPrimaryInvolvement', {
                  required: 'Please select one of these options.',
                })}
                label={getFieldDataByName('involvement_choice')?.options[4].label}
              />
            </div>
            {errors?.petsPrimaryInvolvement && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.petsPrimaryInvolvement.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col xs={12} md={3}>
          <Form.Group controlId="participateIn" className="required">
            <Form.Label>{getFieldDataByName('involvement_participate')?.title}</Form.Label>
            <div className="d-flex flex-column gap-2">
              <Form.Check
                id="0"
                {...register('participateIn', {
                  required: 'Please select one of these options.',
                })}
                value={getFieldDataByName('involvement_participate')?.options[0].value}
                label={getFieldDataByName('involvement_participate')?.options[0].label}
              />
              <Form.Check
                id="1"
                {...register('participateIn', {
                  required: 'Please select one of these options.',
                })}
                value={getFieldDataByName('involvement_participate')?.options[1].value}
                label={getFieldDataByName('involvement_participate')?.options[1].label}
              />
              <Form.Check
                id="2"
                {...register('participateIn', {
                  required: 'Please select one of these options.',
                })}
                value={getFieldDataByName('involvement_participate')?.options[2].value}
                label={getFieldDataByName('involvement_participate')?.options[2].label}
              />
              <Form.Check
                id="3"
                {...register('participateIn', {
                  required: 'Please select one of these options.',
                })}
                value={getFieldDataByName('involvement_participate')?.options[3].value}
                label={getFieldDataByName('involvement_participate')?.options[3].label}
              />
              <Form.Check
                id="4"
                {...register('participateIn', {
                  required: 'Please select one of these options.',
                })}
                value={getFieldDataByName('involvement_participate')?.options[4].value}
                label={getFieldDataByName('involvement_participate')?.options[4].label}
              />
              <Form.Check
                id="5"
                {...register('participateIn', {
                  required: 'Please select one of these options.',
                })}
                value={getFieldDataByName('involvement_participate')?.options[5].value}
                label={getFieldDataByName('involvement_participate')?.options[5].label}
              />
              <Form.Check
                id="6"
                {...register('participateIn', {
                  required: 'Please select one of these options.',
                })}
                value={getFieldDataByName('involvement_participate')?.options[6].value}
                label={getFieldDataByName('involvement_participate')?.options[6].label}
              />
              <Form.Check
                id="7"
                {...register('participateIn', {
                  required: 'Please select one of these options.',
                })}
                value={getFieldDataByName('involvement_participate')?.options[7].value}
                label={getFieldDataByName('involvement_participate')?.options[7].label}
              />
              {errors.participateIn?.root && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.participateIn.root.message}
                </Form.Control.Feedback>
              )}
            </div>
            {participateInValue && participateInValue?.includes('Other') && (
              <>
                <Form.Label className="mt-3">
                  {getFieldDataByName('involvement_participate_other')?.title}
                </Form.Label>
                <Form.Control
                  {...register('participateInOther')}
                  type="text"
                  isInvalid={!!errors?.participateInOther}
                  aria-invalid={!!errors?.participateInOther}
                  maxLength={60}
                />
              </>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="fedPets" className="required">
            <Form.Label>{getFieldDataByName('breeds_fed')?.title}</Form.Label>
            <Form.Control
              {...register('fedPets', {
                required: validateRequired(getFieldDataByName('breeds_fed')?.title, t),
              })}
              as="textarea"
              rows={4}
              isInvalid={!!errors?.fedPets}
              aria-invalid={!!errors?.fedPets}
              aria-required="true"
            />
            {errors?.fedPets && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.fedPets.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default ProfessionalInvolvement;
