import { styled } from '@linaria/react';
import { mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from 'src/common/enums';
import { defaultColors } from 'src/theme/colors';
import { theme } from 'src/theme/theme';
import Link from '../Link';
import BazaarvoiceWidget from '../bazaarvoice/BazaarvoiceWidget';
import PricespiderWidget from '../pricespider/PricespiderWidget';

const StyledImageLink = styled(Link)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme as CMS_THEME].background.imageMatte};
  box-sizing: border-box;

  img {
    mix-blend-mode: multiply;
  }
`;

const OfferLinkContainer = styled.div<{ cmsTheme: CMS_THEME }>`
  display: flex;
  justify-content: center;

  a {
    width: 100%;
    text-align: center;
    text-decoration: none;
    border: ${({ cmsTheme }) => `1px solid ${theme[cmsTheme].text.default}`};
    border-radius: 999999px;
    padding: 15px 20px;
    color: ${({ cmsTheme }) => `1px solid ${theme[cmsTheme].text.default}`};
    font-weight: 300;
  }
`;

const StyledBrand = styled.div`
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const StyledTitleLink = styled(Link)<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: ${({ cmsTheme }) => theme[cmsTheme as CMS_THEME].text.default};
    text-decoration: underline;
  }
`;
const StyledBadge = styled.div<{ children: any }>`
  position: absolute;
  top: 1rem;
  right: 0;
  display: flex;
  border-radius: 22.5px;
  align-items: right;
  color: white;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.5px;
  z-index: 100;

  .half-circle {
    width: 22.5px;
    height: 45px;
    border-bottom-left-radius: 22.5px;
    border-top-left-radius: 22.5px;
    position: relative;
  }
  .badge-text {
    height: 45px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    text-align: center;
    padding-right: 14px;
  }
`;

interface Props {
  image: IGatsbyImageData;
  link: string;
  title: string;
  sku: string;
  bvID: string;
  cmsTheme: CMS_THEME;
  brand?: string;
  onClick?: () => void;
  cardType?: string;
  product_status: string;
}

// Flag to identify new or discontinued products
const Badge: React.FC<{ bgColor: string; text: string }> = ({ bgColor, text }) => {
  return (
    <StyledBadge>
      <div className="half-circle" style={{ backgroundColor: bgColor }} />
      <div className="badge-text" style={{ backgroundColor: bgColor }}>
        {text}
      </div>
    </StyledBadge>
  );
};

const ProductCardBody: React.FC<Props> = ({
  cmsTheme = CMS_THEME.LIGHT,
  link,
  image,
  onClick,
  brand,
  title,
  sku,
  bvID,
  cardType,
  product_status,
}) => {
  const { t } = useTranslation();
  const isExternalLink = link.includes('http');

  // defines Badge background color and text
  const badgeAttr: { [key: string]: { bgColor: string; text: any } } = {
    '1154': { bgColor: defaultColors.primaryDark, text: t('New') },
    '260': { bgColor: theme.common.gray[500], text: t('Discontinued') },
  };
  return (
    <>
      {(product_status === '260' || product_status === '1154') && (
        <Badge bgColor={badgeAttr[product_status].bgColor} text={badgeAttr[product_status].text} />
      )}
      <StyledImageLink
        cmsTheme={cmsTheme}
        to={link}
        onClick={onClick}
        className="p-1 mb-2 text-center rounded-2 d-block p-md-3"
        target={isExternalLink ? '_blank' : ''}
        rel={isExternalLink ? 'noopener noreferrer' : ''}
      >
        <GatsbyImage image={image} objectFit="contain" alt={title} style={{ maxHeight: '400px' }} />
      </StyledImageLink>
      {brand && <StyledBrand>{brand}</StyledBrand>}
      <StyledTitleLink
        cmsTheme={cmsTheme}
        to={link}
        onClick={onClick}
        target={isExternalLink ? '_blank' : ''}
        tabIndex={-1}
      >
        <div className="typography_body fw-light mb-2">
          {title} {isExternalLink && <Icon path={mdiOpenInNew} size={0.8} />}
        </div>
      </StyledTitleLink>

      {cardType === 'products' && (
        <div className="flex-grow-1 mb-3 mb-lg-4">
          <BazaarvoiceWidget attributes={{ productID: bvID, widgetID: 'inline_rating' }} />
        </div>
      )}
      {cardType === 'offers' ? (
        <OfferLinkContainer cmsTheme={cmsTheme}>
          <Link to={link} onClick={onClick}>
            {t('Redeem Offer')}
          </Link>
        </OfferLinkContainer>
      ) : (
        <PricespiderWidget skipAnalytics title={title} cmsTheme={cmsTheme} sku={sku} />
      )}
    </>
  );
};

export default ProductCardBody;
