import * as React from 'react';
import SSRProvider from 'react-bootstrap/SSRProvider';
import ReactQueryWrapper from 'src/components/ReactQueryWrapper';

// eslint-disable-next-line react/function-component-definition
export default function WrapWithProvider({ element }: { element: React.ReactNode }) {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <SSRProvider>
      <ReactQueryWrapper>{element}</ReactQueryWrapper>
    </SSRProvider>
  );
}
