import {
  mdiAlertCircleOutline,
  mdiAlertDecagramOutline,
  mdiAutorenew,
  mdiPackageVariantClosed,
  mdiTrendingDown,
} from '@mdi/js';

import Icon from '@mdi/react';
import * as React from 'react';
import { SVG_PATHS } from '../../common/constants';
import { PRODUCT_STATUS } from '../../common/enums';
import Link from '../Link';
import Alert, { IAlertProps } from '../alert/Alert';

interface IProductStatus extends IAlertProps {
  name: string;
  link?: {
    title: string;
    url: string;
  };
}
interface IProductStatusListProps {
  statuses: IProductStatus[];
}

const getIcon = (icon: string) => {
  switch (icon) {
    case PRODUCT_STATUS.NEW_PRODUCT:
      return mdiAlertDecagramOutline;
    case PRODUCT_STATUS.FORMULA_UPDATE:
      return mdiAutorenew;
    case PRODUCT_STATUS.PACKAGE_UPDATE:
      return mdiPackageVariantClosed;
    case PRODUCT_STATUS.SUPPLY_SHORTAGES:
      return mdiTrendingDown;
    case PRODUCT_STATUS.PRODUCT_RECALL:
      return SVG_PATHS.mdiCampaign;
    case PRODUCT_STATUS.DISCONTINUED:
      return mdiAlertCircleOutline;
    default:
      return null;
  }
};

const ProductStatus: React.FC<IProductStatusListProps> = function ({ statuses }) {
  return (
    <div>
      {statuses.map(status => {
        const icon = getIcon(status.name);
        return (
          <Alert key={status.name} variant={status.variant} role="status" className="my-2 py-2">
            <span className="me-2">
              {icon && <Icon path={icon} size={1} className="me-2" />}
              {status.name}
            </span>
            {status?.link && <Link to={status.link.url}>{status.link.title}</Link>}
          </Alert>
        );
      })}
    </div>
  );
};

export default ProductStatus;
