import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useWebform } from 'src/hooks/useWebform';
import ProClubMemberForm from '../components/forms/ProClubMemberForm/ProClubMemberForm';
import RichText from '../components/rich_text/RichText';

interface StorageProClubMemberFormProps {
  node?: IStorageProClubMemberForm;
}

const StorageProClubMemberForm = ({ node: data }: StorageProClubMemberFormProps) => {
  const { getFieldDataByName } = useWebform(data?.relationships?.form);

  return (
    <Container className="py-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          <h2 className="text-left">{data?.title}</h2>

          <RichText body={data?.formDescription?.processed || ''} />
          <ProClubMemberForm getFieldDataByName={getFieldDataByName} />
        </Col>
      </Row>
    </Container>
  );
};

export default StorageProClubMemberForm;
