import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useWebform } from 'src/hooks/useWebform';
import DemandGenForm from '../components/forms/DemandGenForm';

interface StorageDemandGenFormProps {
  node?: IStorageDemandGenForm;
}

const StorageDemandGenForm = ({ node: data }: StorageDemandGenFormProps) => {
  const { getFieldDataByName } = useWebform(data?.relationships?.form);
  const heading = (
    <>
      <h2 className="text-left">{data?.title}</h2>

      <div className="text-left mb-5">{data?.description}</div>
    </>
  );

  return (
    <Container className="py-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          {heading}

          <DemandGenForm getFieldDataByName={getFieldDataByName} />
        </Col>
      </Row>
    </Container>
  );
};

export default StorageDemandGenForm;
