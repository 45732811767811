import { RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';

const useIsOverflowX = (ref: RefObject<any>, callback?: (v: boolean) => void) => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const observer = useRef<ResizeObserver | null | void>(null);

  const trigger = () => {
    if (ref.current) {
      const hasOverflow = ref.current.scrollWidth > ref.current.clientWidth;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    }
  };

  const observe = useCallback(
    triggerfn => {
      if (typeof window !== 'undefined' && 'ResizeObserver' in window) {
        observer.current = new ResizeObserver(triggerfn).observe(ref.current);
      }
    },
    [ref]
  );

  const disconnect = useCallback(() => observer.current?.disconnect(), []);

  useLayoutEffect(() => {
    if (ref.current) {
      if (typeof window !== 'undefined' && 'ResizeObserver' in window) {
        observe(trigger);
      } else {
        trigger();
      }
    }
    return () => disconnect();

    // eslint-disable-next-line
  }, [disconnect, observe, ref]);
  return isOverflow;
};

export default useIsOverflowX;
