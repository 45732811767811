import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { getFocalPoint } from 'src/utils/mediaHelpers';
import { CMS_OVERLAY, CMS_THEME } from '../common/enums';
import ArticleOverlayCard from '../components/article_card/ArticleOverlayCard';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

const ArticleCardsContainer = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  img {
    max-width: 100%;
  }

  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const articlesGrid = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
  grid-template-rows: auto;

  @media (min-width: ${theme.media.md}) {
    grid-template-columns: 1fr 1fr;

    & > :first-child {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }

  @media (min-width: 960px) {
    grid-template-columns: 66% 1fr;
    grid-template-rows: auto;
    grid-auto-flow: column;

    & > :first-child {
      grid-column: 1;
      grid-row: 1 / 3;

      & > * {
        height: 100%;
      }
    }
  }
`;

interface StorageArticleCardsProps {
  node: IStorageArticleCards;
}

const getArticleCardProps = (node: IArticleContent) => {
  const { title, type, name, drupal_id } = node;
  const summary = node.body.summary ?? undefined;
  const url = node.path?.alias;
  // eslint-disable-next-line no-underscore-dangle
  const image = node.relationships.field_image?.relationships.field_media_image?.gatsbyImage;
  const imageAlt = node.relationships.field_image?.field_media_image?.alt ?? '';
  const promoted = node.sticky;

  return {
    title,
    type,
    name,
    drupal_id,
    url,
    summary,
    imageComponent: image ? (
      <GatsbyImage
        alt={imageAlt}
        image={image}
        imgStyle={{
          objectFit: 'cover',
        }}
        objectPosition={getFocalPoint(node.relationships.field_image)}
      />
    ) : (
      <StaticImage
        aspectRatio={1.5}
        src="../assets/media/images/cat-dog-article-placeholder-2.jpg"
        alt="A cute dog & a cute cat."
      />
    ),
    promoted,
  };
};

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
    overlay: CMS_OVERLAY.DARK,
  },
};
const StorageArticleCards: React.FC<StorageArticleCardsProps> = function ({
  node: cmsArticleCards,
}) {
  const settings = Object.assign(DefaultSettings, cmsArticleCards?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const cmsOverlay = settings.entity_variant.overlay || CMS_OVERLAY.DARK;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;

  return (
    <ArticleCardsContainer
      data-id={cmsArticleCards.drupal_id}
      className="py-3 py-lg-5"
      fluid
      cmsTheme={cmsTheme}
    >
      <Container
        className={cx(
          cmsArticleCards?.title || cmsArticleCards?.body?.processed ? 'pb-3 pb-lg-5' : '',
        )}
      >
        {cmsArticleCards?.title && (
          <HeadingElement className={cx(headingStyle)}>{cmsArticleCards.title}</HeadingElement>
        )}
        {cmsArticleCards?.body?.processed && (
          <RichText
            cmsTheme={cmsTheme}
            className={cx('mt-3')}
            body={cmsArticleCards.body.processed}
          />
        )}
      </Container>
      <Container>
        <div
          className={cx(cmsArticleCards.relationships.articles.length === 3 ? articlesGrid : 'row')}
        >
          {cmsArticleCards.relationships.articles.map((article, index) => {
            return (
              <div
                data-id={article.drupal_id}
                key={`${index}-${article.drupal_internal__nid}`}
                className={cx(
                  cmsArticleCards.relationships.articles.length === 2 && 'mb-4 col-12 col-lg-6',
                )}
              >
                <ArticleOverlayCard
                  cmsTheme={cmsTheme}
                  cmsOverlay={cmsOverlay}
                  {...getArticleCardProps(article)}
                  condensed={index !== 0 && cmsArticleCards.relationships.articles.length > 2}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </ArticleCardsContainer>
  );
};

export default StorageArticleCards;
