import * as React from 'react';

export interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {
  children?: React.ReactNode;
  variant?: string;
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
}

const variantMap: { [key: string]: keyof JSX.IntrinsicElements } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  overline: 'span',
  body: 'span',
};

// To customize text properties such as alignment, weight, and italic
// pass Bootstrap CSS utility classes. https://getbootstrap.com/docs/4.0/utilities/text/

const Typography: React.FC<Props> = function ({
  variant = 'body',
  tag,
  className,
  ...props
}: Props) {
  const Tag: keyof JSX.IntrinsicElements = tag || variantMap[variant] || 'span';

  return <Tag className={`typography_${variant} ${className || ''}`} {...props} />;
};

export default Typography;
