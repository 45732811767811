import { css, cx } from '@linaria/core';
import React from 'react';
import Link from '../Link';

type Props = {
  media?: JSX.Element;
  href: string;
};

const BaseCard = css`
  --bs-card-border-radius: 1rem;
  --bs-card-inner-border-radius: 1rem;
  --bs-card-cap-padding-y: 1rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1.5rem;

  color: #2b2b2b;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  padding: 1rem;
  text-decoration: none;
  width: 100%;

  &:hover {
    color: #2b2b2b;
    text-decoration: underline;
  }

  .card-img {
    display: flex;
    background-color: #e3e7f2;
    flex-shrink: 0;
    height: auto;
    max-width: 180px;
    width: auto;
  }
`;

const Card: React.FC<Props> = function ({ media, children, ...props }) {
  return (
    <Link to={props.href} className={cx('card', `${BaseCard}`)}>
      {media && (
        <div className="card-img" aria-hidden>
          {media}
        </div>
      )}
      <div className="card-body">{children}</div>
    </Link>
  );
};

export default Card;
