import { styled } from '@linaria/react';
import * as React from 'react';
import { theme } from '../../theme/theme';

const StyledAlert = styled.div<IAlertProps>`
  background-color: ${({ variant }) => theme.common[variant].light};
  border-radius: ${theme.common.shape.rounded1};
  color: ${({ variant }) => theme.common[variant].dark};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${theme.common.spacing[1]};

  a {
    color: ${({ variant }) => theme.common[variant].dark};
  }
  h3 {
    color: ${({ variant }) => theme.common[variant].dark};
    font-weight: 600;
    line-height: 1;
  }
  p {
    color: ${({ variant }) => theme.common[variant].dark};
    font-weight: 400;
    line-height: 1;
  }
`;

export interface IAlertProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: 'success' | 'warning' | 'error' | 'info';
}

const Alert = React.forwardRef<HTMLDivElement, IAlertProps>((props: IAlertProps, ref) => {
  const { children, className, variant, ...rest } = props;

  return (
    <StyledAlert ref={ref} variant={variant} className={className} {...rest}>
      {children}
    </StyledAlert>
  );
});

export default Alert;
