import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import IconCard from '../components/icon_card/IconCard';
import RichText from '../components/rich_text/RichText';
import useIsOverflowX from '../hooks/useIsOverflowX';
import { theme } from '../theme/theme';
import { getFocalPoint, getMedia } from '../utils/mediaHelpers';

type Props = {
  node: IStorageCategoryCards;
};

interface CategoryCardContainerProps {
  cmsTheme: CMS_THEME;
}

const CategoryCardContainer = styled(Container)<CategoryCardContainerProps>`
  padding: 120px 0;
  background: ${({ cmsTheme }) => theme[cmsTheme].background.default};
`;

interface TextContainerProps {
  textColor: string;
}

const containerFn = ({ textColor, ...props }) => <Container {...props} />;
const TextContainer = styled(containerFn)<TextContainerProps>`
  color: ${({ textColor }) => textColor};

  .category-cards__body {
    color: ${({ textColor }) => textColor};
  }
`;

interface CategoryCardGridProps {
  rows: number;
  columns: number;
  isOverflow: boolean;
  numOfItems: number;
}

const CategoryCardGrid = styled.div<CategoryCardGridProps>`
  /* Can be used if nestle would rather grid wrap then overflow */
  /* margin: 0 10%; */
  /* /** */
  /*  * User input values. */
  /*  */
  /* --grid-layout-gap: 16px; */
  /* --grid-column-count: ${({ columns }) => columns}; */
  /* --grid-item--min-width: 230px; */

  /* /** */
  /*  * Calculated values. */
  /*  */
  /* --gap-count: calc(var(--grid-column-count) - 1); */
  /* --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap)); */
  /* --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count)); */

  /* display: grid; */
  /* grid-template-columns: repeat( */
  /*   auto-fill, */
  /*   minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr) */
  /* ); */
  /* grid-gap: var(--grid-layout-gap); */

  padding: 30px;
  justify-content: start;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  overflow-x: auto;
  grid-template-columns: ${({ columns, isOverflow, numOfItems }) =>
    isOverflow ? `repeat(${numOfItems}, 260px)` : `repeat(${Math.min(numOfItems, columns)}, 260px)` || 'repeat(1, 1fr)'};
  grid-template-rows: ${({ rows, isOverflow }) =>
    isOverflow ? '1fr' : `repeat(${rows}, 1fr)` || 'repeat(1, 1fr)'};
  justify-content: ${({ isOverflow }) => (isOverflow ? 'flex-start' : 'center')};
  .card {
    min-height: 280px;
  }
`;

const defaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const StorageCategoryCards: React.FC<Props> = ({ node: categoryCards }) => {
  const settings = Object.assign(defaultSettings, categoryCards?.behaviors || {});
  const containerRef = useRef(null);
  const rows = categoryCards.behaviors?.entity_rows_columns?.rows;
  const columns = categoryCards.behaviors?.entity_rows_columns?.columns;
  const { title } = categoryCards;
  const text = categoryCards?.text?.processed;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const iconMenu = categoryCards.relationships.icon_menu;
  const items = iconMenu.relationships.field_menu_links;
  const cmsTheme = settings?.entity_variant?.theme;
  const isOverflow = useIsOverflowX(containerRef);

  // If menu is draft or all items are draft, render nothing.
  if (!iconMenu || !items || !items.length) {
    return null;
  }

  return (
    <CategoryCardContainer data-id={categoryCards.drupal_id} fluid cmsTheme={cmsTheme}>
      <TextContainer
        textColor={theme[cmsTheme].text.default}
        fluid
        className="justify-content-center align-items-center d-flex flex-column"
      >
        {title && <HeadingElement className={cx('mb-3', headingStyle)}>{title}</HeadingElement>}
        {text && <RichText cmsTheme={cmsTheme} className="mb-5 category-cards__body" body={text} />}
      </TextContainer>
      <CategoryCardGrid
        numOfItems={items.length}
        ref={containerRef}
        rows={isOverflow ? 1 : Number(rows)}
        columns={Number(columns)}
        isOverflow={isOverflow}
      >
        {items.map((item, i) => {
          const fieldMedia = item?.relationships?.field_media;
          // @todo - add consistent typings for files across media.
          const isIcon =
            // @ts-ignore
            fieldMedia?.relationships?.svg?.filemime.search(/.svg/i) > -1 ||
            // @ts-ignore
            fieldMedia?.relationships?.field_media_image?.filemime.search(/.png/i) > -1;

          if (fieldMedia) {
            return (
              <IconCard
                drupalId={item.drupal_id}
                key={i}
                cmsTheme={cmsTheme}
                image={getMedia({
                  media: fieldMedia,
                  objectFit: isIcon ? 'contain' : 'cover',
                  objectPosition: isIcon
                    ? '50% 50%'
                    : getFocalPoint(item.relationships.field_media),
                })}
                to={item.link.url}
                title={item.link.title}
                {...item.link.options?.attributes}
              />
            );
          }
        })}
      </CategoryCardGrid>
    </CategoryCardContainer>
  );
};

export default StorageCategoryCards;
