import { css } from '@linaria/core';
import React from 'react';
import { FormControl, FormControlProps, FormGroup, FormLabel, FormText } from 'react-bootstrap';
import { FieldErrorsImpl, Path, UseFormRegister } from 'react-hook-form';
import { theme } from '../../../theme/theme';
import PurinaTooltip from '../../tooltip/Tooltip';
// eslint-disable-next-line import/no-cycle
import { IContactUsFormData } from '../ContactUsForm';

type Props = Omit<FormControlProps, 'name'> & {
  label: string;
  subtitle?: string | null;
  register: UseFormRegister<IContactUsFormData>;
  required?: boolean;
  name: Path<IContactUsFormData>;
  errors: FieldErrorsImpl<IContactUsFormData>;
  tooltip?: React.ReactElement;
  formatValue?: (value: string) => string;
  maxLength?: string;
};

export const requiredClass = css`
  &:after {
    content: ' *';
    color: ${theme.light.error.light};
  }
`;

const InputField: React.FC<Props> = ({
  required = false,
  label,
  subtitle,
  name,
  register,
  errors,
  tooltip,
  placeholder,
  ...rest
}) => {
  return (
    <FormGroup controlId={name} className="required">
      <div className="d-flex gap-1">
        <FormLabel className={required ? requiredClass : ''}>{label}</FormLabel>
        {tooltip && <PurinaTooltip>{tooltip}</PurinaTooltip>}
      </div>
      {subtitle && <FormText muted>{subtitle}</FormText>}
      <FormControl
        isInvalid={!!errors[name]}
        aria-invalid={!!errors[name]}
        {...register(name)}
        type="text"
        placeholder={placeholder}
        {...rest}
      />
      {errors[name] && (
        <FormControl.Feedback className="d-block" type="invalid">
          {errors[name]?.message}
        </FormControl.Feedback>
      )}
    </FormGroup>
  );
};

export default InputField;
