import { PageProps, graphql } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import StorageContactUsForm from 'src/cms-components/StorageContactUsForm';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ContactUsSidebar, {
  ContactUsSidebarComponents,
} from '../components/contact_us/ContactUsSidebar';
import RichText from '../components/rich_text/RichText';
import Typography from '../components/typography/Typography';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

type ComponentTypes = IStorageContactCards | IStorageHighlights;

interface NodePageProps extends NodeContent {
  settings: ContactUsSettings;
  relationships?: {
    components: ComponentTypes[];
  };
}
interface DataProps {
  node: NodePageProps;
  translations: Translations;
}

const ContactUsTemplate: React.FC<PageProps<DataProps>> = function ({ data: { node } }) {
  const { t } = useTranslation();
  const components = node.relationships?.components;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const body = node.body?.processed ?? '';
  const contactUsForm = (components ?? []).find(
    storage => storage.type === ContactUsSidebarComponents.STORAGE_CONTACT_US_FORM,
  );

  return (
    <Layout node={node} metaData={node.metatag} language={node.langcode}>
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <Container className="py-4">
        <Row>
          <AnalyticsPoint
            type="module"
            typeLabel="contact_form"
            label="contact us form"
            as={Col}
            xs="12"
            lg="8"
            className="d-flex flex-column gap-4 p-3"
          >
            <Typography variant="h1">{node.title}</Typography>
            {body && <RichText body={body} />}
            {/* <Typography variant="h5" className="text-danger">
              *{t('Required Field')}
            </Typography> */}
            {contactUsForm && (
              <StorageContactUsForm node={contactUsForm as unknown as IStorageWebForm} standalone />
            )}
          </AnalyticsPoint>
          <AnalyticsPoint
            type="module"
            typeLabel="contact_sidebar"
            label="contact us sidebar"
            as={Col}
            xs="12"
            md="auto"
            lg="4"
            className="p-3"
          >
            {components && <ContactUsSidebar components={components} />}
          </AnalyticsPoint>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeContactUs(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
      body {
        processed
      }
      relationships {
        components: field_storage {
          type: __typename
          ...StorageHighlights
          ...StorageContactCards
          ...StorageContactUsForm
        }
      }
    }
    translations: allNodeContactUs(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ContactUsTemplate;
