import { styled } from '@linaria/react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import ProductCardBody from './ProductCardBody';

interface Props {
  drupalId?: string;
  image: IGatsbyImageData;
  link: string;
  title: string;
  sku: string;
  bvID: string;
  product_status: string;
  cmsTheme?: CMS_THEME;
  brand?: string;
  type?: 'products' | 'offers';
  onClick?: () => void;
  disableAnalyticsPoint?: boolean;
}

const StyledProductCard = styled(Card)<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme as CMS_THEME].text.default};
  background: ${({ cmsTheme }) => theme[cmsTheme as CMS_THEME].background.default};
  border: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ProductCard: React.FC<Props> = function ({
  title,
  link,
  image,
  bvID,
  sku,
  brand,
  drupalId,
  disableAnalyticsPoint,
  product_status,
  cmsTheme = CMS_THEME.LIGHT,
  type: cardType = 'products',
  onClick = () => {},
}: Props) {
  // TODO: figure out another way to have this analytics point not triggered rather then just creating a ternary.
  // There should be a way to manually skip a anlytics point

  return disableAnalyticsPoint ? (
    <StyledProductCard data-id={drupalId} cmsTheme={cmsTheme}>
      <ProductCardBody
        image={image}
        link={link}
        title={title}
        sku={sku}
        bvID={bvID}
        brand={brand}
        cardType={cardType}
        onClick={onClick}
        cmsTheme={cmsTheme}
        product_status={product_status}
      />
    </StyledProductCard>
  ) : (
    <AnalyticsPoint
      addParentLevel={1}
      type="component"
      eventLabel={title}
      action="view product card"
      category="product cards"
      node={{ drupal_id: drupalId || '', title, type: 'product_card', name: title }}
      data-id={drupalId}
      cmsTheme={cmsTheme}
      as={StyledProductCard}
    >
      <ProductCardBody
        image={image}
        link={link}
        title={title}
        cmsTheme={cmsTheme}
        sku={sku}
        bvID={bvID}
        brand={brand}
        cardType={cardType}
        onClick={onClick}
        product_status={product_status}
      />
    </AnalyticsPoint>
  );
};

export default ProductCard;
