import { css, cx } from '@linaria/core';
import React from 'react';
import { GetMediaReturn } from '../../utils/mediaHelpers';

type Props = {
  media?: GetMediaReturn;
  actions?: JSX.Element;
  drupalId: string;
};

const highlight = css`
  height: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

const Highlight: React.FC<Props> = function ({ media, children, actions, drupalId }) {
  return (
    <div data-id={drupalId} className={cx(highlight, 'd-flex flex-column text-center')}>
      <div className="overflow-hidden rounded-circle mb-5">{media}</div>
      <div className="flex-grow-1 mb-3">{children}</div>
      <div>{actions}</div>
    </div>
  );
};

export default Highlight;
