import { common } from './common';
import { ITheme } from './types';

export const neutral: ITheme = {
  mode: 'light',
  primary: {
    main: '#2B2B2B',
    withAlpha: (alpha: number) => `rgba(43, 43, 43, ${alpha})`,
    // dark: '',
    // light: '',
  },
  text: {
    invert: '#FFF',
    withAlpha: (alpha: number) => `rgba(43, 43, 43, ${alpha})`,
    default: '#2B2B2B',
  },
  background: {
    default: '#EDF0F8',
    alternate: '#FFF',
    paper: '#FFFFFF',
    imageMatte: '#FFFFFF',
    iconContainer: '#EDF0F8',
  },
  action: '#2553F8',
  ...common,
};
