import { PageProps, graphql } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Trans } from 'react-i18next';
import BannerDismissible from '../components/BannerDismissible';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Seo from '../components/Seo';
import DescriptionList, {
  DescriptionListItem,
} from '../components/description_list/DescriptionList';
import RichText from '../components/rich_text/RichText';
import { useGetBreedInfo } from '../utils/breedHelpers';
import { getMedia } from '../utils/mediaHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface NodeProps extends NodeContent {
  field_breed_info: {
    activities: string;
    coat: string;
    energy: string;
    height: string;
    size: string;
    indoor_outdoor: string;
    weight: string;
  };
  relationships: {
    field_image: MediaImage;
    field_breed_collection: TaxonomyTerm[];
  };
}
interface DataProps {
  node: NodeProps;
  translations: Translations;
}

const BreedTemplate: React.FC<PageProps<DataProps>> = function ({ data: { node, translations } }) {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const breedInfoList = useGetBreedInfo(node?.field_breed_info);
  const breedName = node.title;
  const filteredBreedInfoList = (breedInfoList?.filter(item => item !== null) ??
    []) as DescriptionListItem[];
  const breedCollections = node.relationships?.field_breed_collection;

  // hard-coded BannerDismissible nodes for static banners - PC-241
  const petFinderBanner = {
    type: 'storage__banner',
    id: 'bada1fbf-9672-55f6-b1f3-51a034ba1d52',
    drupal_id: '4e71cacd-6723-479c-8e68-7a05da07576a',
    name: 'Explore Pets to Adopt',
    behaviors: {
      entity_variant: {
        justify_content: 'center',
        theme: 'dark',
      },
      entity_buttons: {
        primary_button: 'btn-text',
      },
    },
    title: t('Explore Pets to Adopt'),
    link: {
      url: 'https://www.petfinder.com/',
      uri: '',
      title: t('Find Your Pet'),
      options: {
        attributes: {
          target: '_blank',
          ariaLabel: null,
          icon: null,
          rel: null,
        },
      },
    },
  };

  const localBreederBanner = {
    type: 'storage__banner',
    id: '261e780a-dce8-5cc3-b93c-e1ced712cd3d',
    drupal_id: '20c59181-992d-4f2b-bbcc-a5770090d297',
    name: 'Still Looking for a Pet Near You?',
    behaviors: {
      entity_variant: {
        justify_content: 'center',
        theme: 'neutral',
      },
      entity_buttons: {
        primary_button: 'btn-text',
      },
    },
    title: t('Still Looking for a Pet Near You?'),
    link: {
      url: `${activeLanguage !== 'en' ? `/${activeLanguage}` : ''}/breeder-referral`,
      uri: '',
      title: t('Find a Local Breeder'),
      options: {
        attributes: {
          target: null,
          ariaLabel: null,
          icon: null,
          rel: null,
        },
      },
    },
  };

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <div className="bg-gray-100 py-5 px-3">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Row className="mb-3 text-center ">
              <Col lg={12}>
                <h1 className="h1 mb-2 text-capitalize">{node.title}</h1>
              </Col>
            </Row>
            <Row className="justify-content-center my-lg-5 my-3 align-items-center">
              <Col xs="12" lg="9" xl="8" className="bg-white rounded-3 text-center">
                {getMedia({
                  media: node.relationships.field_image,
                  loading: 'eager',
                })}
              </Col>
            </Row>
            {filteredBreedInfoList && (
              <Row className="justify-content-md-center">
                <Col className="p-0" xs="12" lg="9" xl="8">
                  <DescriptionList items={filteredBreedInfoList} />
                </Col>
              </Row>
            )}
          </Row>
        </Container>
      </div>
      <BannerDismissible node={petFinderBanner} />
      <BannerDismissible node={localBreederBanner} />
      {node.body?.processed && (
        <Container className="my-5">
          <Row className="justify-content-md-center">
            <Col xs="12" lg="9" xl="8">
              <RichText body={node.body.processed} />
            </Col>
          </Row>
        </Container>
      )}
      <AnalyticsPoint
        type="module"
        label="breed collections"
        typeLabel="breed_collections"
        as={Container}
        className="bg-gray-200"
        style={{ paddingBlock: '4rem' }}
        fluid
      >
        <Container>
          <Row>
            <h2 className="h2 fw-light mb-5">
              <Trans i18nKey="Explore breeds like the {{breedName}}">
                Explore breeds like the <strong>{{ breedName }}</strong>
              </Trans>
            </h2>
            <div className="d-flex flex-wrap align-items-start gap-3">
              {breedCollections.map(collection => {
                const pageDelegatePath = collection.relationships?.field_page_delegate?.path?.alias;
                if (!pageDelegatePath) return null;
                return (
                  <AnalyticsPoint
                    type="component"
                    category="explore similar breeds"
                    node={{ ...collection, type: 'breed_collection' }}
                  >
                    <Link key={collection.id} to={pageDelegatePath} className="btn btn-secondary">
                      {collection.name}
                    </Link>
                  </AnalyticsPoint>
                );
              })}
            </div>
          </Row>
        </Container>
      </AnalyticsPoint>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeBreed(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      title
      langcode
      body {
        processed
      }
      ...Metatag
      field_breed_info {
        activities
        coat
        color
        energy
        height
        size
        indoor_outdoor
        weight
      }
      relationships {
        field_breed_collection {
          path {
            alias
          }
          name
          relationships {
            field_page_delegate {
              path {
                alias
              }
            }
          }
        }
        field_image {
          ...MediaImage
          relationships {
            field_media_image {
              gatsbyImage(
                aspectRatio: 1
                width: 500
                formats: AUTO
                placeholder: NONE
              )
            }
          }
        }
      }
    }
    translations: allNodeBreed(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default BreedTemplate;
