import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useWebform } from 'src/hooks/useWebform';
import BreederReferralForm from 'src/components/forms/BreederReferralForm';

interface StorageBreederReferralFormProps {
  node?: IStorageBreederReferralForm;
}

const StorageBreederReferralForm = ({ node: data }: StorageBreederReferralFormProps) => {
  const { getFieldDataByName } = useWebform(data?.relationships?.form);

  const heading = (
    <>
      <div className="text-left" dangerouslySetInnerHTML={{ __html: data?.form_title.processed }} />

      {data?.description && <div className="text-left mb-5">{data?.description}</div>}
    </>
  );

  return (
    <Container className="py-5">
      <Row className="justify-content-md-center">
        <Col md="10">
          {heading}

          <BreederReferralForm getFieldDataByName={getFieldDataByName} />
        </Col>
      </Row>
    </Container>
  );
};

export default StorageBreederReferralForm;
