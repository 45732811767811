import { create } from 'zustand';

export interface IModule {
  pageType?: string;
  modulePosition: string;
  moduleId: string;
  moduleType: string;
  drupalId: string;
  components?: {
    componentID?: string;
    componentPosition?: string;
    componentType?: string;
    drupalId?: string;
  }[];
}

interface PageContext {
  pageType: string;
  modules: IModule[];
}

interface PageStore {
  pageContext: PageContext;
  setPage: (page: PageContext) => void;
}

export const usePageStore = create<PageStore>()(set => ({
  pageContext: { pageType: '', modules: [] },
  setPage: page => set({ pageContext: page }),
}));
