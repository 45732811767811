import Axios from 'axios';
import { format } from 'date-fns';
import { OfferFormTypes } from 'src/components/forms/utils/offer';

const drupal = Axios.create({
  baseURL: `${process.env.GATSBY_DRUPAL_ENDPOINT}`,
  headers: {
    'api-key': `${process.env.GATSBY_DRUPAL_API_KEY}`,
  },
});

const getDrupalSessionToken = async () => {
  let token = null;
  try {
    const response = await Axios.get(`${process.env.GATSBY_DRUPAL_ENDPOINT}session/token`);
    token = response.data;
  } catch (error) {
    console.error(error);
  }
  return token;
};

export interface PetType {
  name: string;
  key_name: string;
}

export interface Referrer {
  code: string;
}

export interface CouponRequest {
  id: number;
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  address1?: any;
  address2?: any;
  city?: any;
  state?: any;
  postal_code: number;
  phone?: any;
  opt_in: boolean;
  created_at: Date;
  pet_type: PetType;
  purpose?: any;
  referrer: Referrer;
}

export interface User {
  id: number;
  uuid: string;
}

export interface Pet {
  name: string;
  gender: string;
  pet_type: {
    keyName: 'DOG' | 'CAT';
  };
  birthDate: string;
}

export const mapPetType =
  (keyName: 'DOG' | 'CAT') =>
  ({ birthDate, ...pet }: OfferFormTypes['dogPets'][number]) => {
    return {
      ...pet,
      birthDate: format(birthDate, 'yyyy-MM-dd'),
      pet_type: { keyName },
    };
  };

export const newsletterSignUpPost = async ({ data }: { data: any }) => {
  let newsletterResponse = null;
  drupal.defaults.headers.post['X-CSRF-Token'] = await getDrupalSessionToken();
  try {
    const response = await drupal.post('/webform_rest/submit', data);

    if (response.status === 200) {
      newsletterResponse = response.data;
    }
  } catch (error) {
    return error;
  }
  return newsletterResponse;
};

export const demandGenPost = async ({ data }: { data: any }) => {
  let demandGenResponse = null;
  drupal.defaults.headers.post['X-CSRF-Token'] = await getDrupalSessionToken();
  try {
    const response = await drupal.post('/webform_rest/submit', data);

    if (response.status === 200) {
      demandGenResponse = response.data;
    }
  } catch (error) {
    return error;
  }
  return demandGenResponse;
};

export const contactUsPost = async ({ data }: { data: any }) => {
  let contactUsResponse = null;
  drupal.defaults.headers.post['X-CSRF-Token'] = await getDrupalSessionToken();
  try {
    const response = await drupal.post('/webform_rest/submit', data);

    if (response.status === 200) {
      contactUsResponse = response.data;
    }
  } catch (error) {
    console.error(error);
  }
  return contactUsResponse;
};

export const nominatePetHeroPost = async ({ data }: { data: any }) => {
  let nominatePetHeroResponse;
  drupal.defaults.headers.post['X-CSRF-Token'] = await getDrupalSessionToken();
  try {
    const response = await drupal.post('/webform_rest/submit', data);
    if (response.status === 200) {
      nominatePetHeroResponse = response.data;
    }
  } catch (error) {
    console.error(error);
  }
  return nominatePetHeroResponse;
};
