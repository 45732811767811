import axios from 'axios';
import { useEffect, useState } from 'react';

type Props = {
  url: string;
  payload: object;
};

const useAxiosPost = ({ url, payload }: Props) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post(url, payload);
        setData(response.data);
      } catch (e: any) {
        setError(e.message);
      } finally {
        setLoaded(true);
      }
    })();
  }, []);

  return { data, error, loaded };
};

export default useAxiosPost;
