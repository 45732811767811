import { AxiosError } from 'axios';
import { PageProps, graphql, navigate } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getProductSearchData } from 'src/ssr/getSearchData';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import IconCard from '../../components/icon_card/IconCard';
import RichText from '../../components/rich_text/RichText';
import SearchListing from '../../components/search_listing/SearchListing';
import StickyFacetContainer from '../../components/search_listing/StickyFacetContainer';
import { theme } from '../../theme/theme';
import { getCmsComponent } from '../../utils/cmsComponentHelpers';
import { getFocalPoint, getMedia } from '../../utils/mediaHelpers';
import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';
import { ProductSD } from './types/types';

interface DataProps {
  node: IProductListing;
  translations: Translations;
}

const RelatedCategoriesLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1rem;

  > * {
    width: calc((100% - 100px) / 2);
    flex-shrink: 0;
  }

  > * + * {
    margin-left: 1rem;
  }

  @media (min-width: ${theme.media.md}) {
    > * {
      width: calc((100% - 100px) / 3);
    }
  }
  @media (min-width: ${theme.media.lg}) {
    justify-content: center;
    > * {
      width: calc((100% - 100px) / 5);
    }
  }
`;
const listingTab = css`
  &.nav-tabs .nav-item .nav-link {
    background-color: #edf0f8;
  }

  &.nav-tabs .nav-item .nav-link.active {
    background-color: #fff !important;
    border-color: #fff !important;
  }
`;

const PlpTemplate: React.FC<PageProps<DataProps, any, any, ProductSD>> = function ({
  data: { node, translations },
  location,
  serverData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { components, subnav, related } = node.relationships;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  useEffect(() => {
    setIsLoading(false);
  }, [location]);

  const handleFacetChange: any = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    const { url } = event.currentTarget.dataset;
    if (url) {
      const facetUrlObject = new URL(url);
      const facetSearchParams = new URLSearchParams(facetUrlObject.search);
      Object.keys(serverData.contextualFilters).forEach(key => facetSearchParams.delete(key));
      if (facetSearchParams.toString() !== new URLSearchParams(location.search).toString()) {
        navigate(`?${facetSearchParams}`);
      }
    }
  };

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {subnav && getCmsComponent({ node: subnav, index: 0 })}
      <Container fluid className="bg-gray-100 py-5 text-center">
        <h1>{node.title}</h1>
        {node?.body?.processed && (
          <RichText className="text-center py-4" body={node.body.processed} />
        )}
      </Container>
      <Container fluid className="px-0">
        {components && components.length > 0 ? (
          <Tabs
            style={{ paddingInline: 'calc(50% - 270px' }}
            defaultActiveKey="shop"
            className={`bg-gray-100 ${listingTab} nav-justified justify-content-center flex-nowrap`}
            id="product-tabs"
          >
            <Tab tabClassName="py-3 typography_h5 js-track" eventKey="shop" title={t('Shop')}>
              {related && related.length > 0 && (
                <AnalyticsPoint
                  type="module"
                  label="related categories product listing page"
                  typeLabel="plp_related_categories"
                >
                  <Container className="my-5">
                    <h2 className="typography_h1 text-center">{t('Related Categories')}</h2>
                    <RelatedCategoriesLayout className="my-5">
                      {related.map((relatedPlp, i) => {
                        const fieldMedia = relatedPlp.relationships.image;
                        const fieldIcon = relatedPlp.relationships.icon;

                        return (
                          <li key={i}>
                            <IconCard
                              image={getMedia({
                                media: fieldIcon || fieldMedia,
                                sizes: '(min-width: 768px) 8.25rem, calc(0.65 * (100% - 6.25rem))',
                                objectFit: fieldIcon ? 'contain' : 'cover',
                                objectPosition: fieldIcon
                                  ? '50% 50%'
                                  : getFocalPoint(relatedPlp.relationships.image),
                              })}
                              link={relatedPlp.path.alias}
                              title={relatedPlp.title}
                            />
                          </li>
                        );
                      })}
                    </RelatedCategoriesLayout>
                  </Container>
                </AnalyticsPoint>
              )}
              <Container className="my-5">
                <SearchListing
                  data={serverData.productData || undefined}
                  error={serverData.error || new Error(t('Sorry, there was an error'))}
                  loaded={!isLoading}
                  defaultLoadedState
                >
                  <Row className="align-items-center py-3">
                    <SearchListing.SearchHeader
                      title={t('Products')}
                      onFacetRemove={handleFacetChange}
                    />
                  </Row>
                  <Row className="position-relative">
                    <Col lg={3} className="py-3 d-none d-lg-block">
                      <StickyFacetContainer>
                        <SearchListing.SearchFacets onChange={handleFacetChange} />
                      </StickyFacetContainer>
                    </Col>
                    <Col>
                      <SearchListing.ProductSearchResults serverData={serverData.productData} />
                      <SearchListing.Pagination serverData={serverData.productData} />
                    </Col>
                    <SearchListing.FacetModal onChange={handleFacetChange} />
                  </Row>
                </SearchListing>
              </Container>
            </Tab>
            <Tab tabClassName="py-3 typography_h5 js-track" eventKey="learn" title={t('Learn')}>
              {components &&
                components.map((component, index) => {
                  return (
                    <React.Fragment key={component.id}>
                      {getCmsComponent({ node: component, index })}
                    </React.Fragment>
                  );
                })}
            </Tab>
          </Tabs>
        ) : (
          <>
            {related && related.length > 0 && (
              <AnalyticsPoint
                type="module"
                label="related categories product listing page"
                typeLabel="plp_related_categories"
              >
                <Container className="my-5">
                  <h2 className="typography_h1 text-center">{t('Related Categories')}</h2>
                  <RelatedCategoriesLayout className="my-5">
                    {related.map((relatedPlp, i) => {
                      const fieldMedia = relatedPlp.relationships.image;
                      const fieldIcon = relatedPlp.relationships.icon;
                      return (
                        <li key={relatedPlp.drupal_internal__nid} className="list-unstyled">
                          <IconCard
                            image={getMedia({
                              media: fieldIcon || fieldMedia,
                              sizes: '(min-width: 768px) 8.25rem, calc(0.65 * (100% - 6.25rem))',
                              objectFit: fieldIcon ? 'contain' : 'cover',
                              objectPosition: fieldIcon
                                ? '50% 50%'
                                : getFocalPoint(relatedPlp.relationships.image),
                            })}
                            link={relatedPlp.path.alias}
                            title={relatedPlp.title}
                          />
                        </li>
                      );
                    })}
                  </RelatedCategoriesLayout>
                </Container>
              </AnalyticsPoint>
            )}
            <Container className="my-5">
              <SearchListing
                data={serverData.productData}
                error={serverData.error || new Error(t('Sorry, there was an error'))}
                loaded={!isLoading}
                defaultLoadedState
              >
                <Row className="align-items-center py-3">
                  <SearchListing.SearchHeader
                    title={t('Products')}
                    onFacetRemove={handleFacetChange}
                  />
                </Row>
                <Row className="position-relative">
                  <Col lg={3} className="py-3 d-none d-lg-block">
                    <StickyFacetContainer>
                      <SearchListing.SearchFacets onChange={handleFacetChange} />
                    </StickyFacetContainer>
                  </Col>
                  <Col>
                    <SearchListing.ProductSearchResults serverData={serverData.productData} />
                    <SearchListing.Pagination serverData={serverData.productData} />
                  </Col>
                  <SearchListing.FacetModal onChange={handleFacetChange} />
                </Row>
              </SearchListing>
            </Container>
          </>
        )}
      </Container>
    </Layout>
  );
};

export const getServerData = async (props: any) => {
  try {
    const productData = await getProductSearchData(props.pageContext, props.query);
    return {
      status: 200,
      headers: {
        'Cache-Control': 'public, max-age=3600, s-maxage=3600, stale-while-revalidate=3600',
      },
      props: {
        productData: productData.data,
        contextualFilters: productData.contextualFilters,
        error: null,
      },
    };
  } catch (e) {
    return {
      status: 500,
      props: {
        error: (e as AxiosError).response,
        productData: null,
      },
    };
  }
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeProductListingPage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      title
      path {
        alias
      }
      body {
        processed
      }
      langcode
      ...Metatag
      relationships {
        brand: field_brand {
          drupal_internal__tid
        }
        lifeStage: field_life_stage {
          drupal_internal__tid
        }
        specialDiet: field_special_diet {
          drupal_internal__tid
        }
        category: field_category {
          drupal_internal__tid
        }
        species: field_species_term {
          drupal_internal__tid
        }
        foodForm: field_food_form {
          drupal_internal__tid
        }
        litterType: field_litter_type {
          drupal_internal__tid
        }
        healthBenefits: field_health_benefits {
          drupal_internal__tid
        }
        image: field_image {
          ...MediaImage
          relationships {
            field_media_image {
              image: gatsbyImage(aspectRatio: 3, width: 1600, formats: AUTO, placeholder: NONE)
            }
          }
        }
        icon: field_icon {
          ...MediaVectorImage
        }
        subnav: field_subnav_storage {
          type: __typename
          ...StorageSubnav
        }
        components: field_storage {
          type: __typename
          ...StorageArticleCards
          ...StorageImageContent
          ...StorageHeroCard
          ...StorageManualCards
          ...StorageHighlights
          ...StorageProductCards
          ...StorageRichText
          ...StoragePageTitle
          ...StorageBanner
          ...StorageAnsiraProfileForm
          ...StorageIngredientsMap
          ...StorageBrandGrid
          ...StorageFaqSection
          ...StorageUnfilteredHtml
          ...StorageSubnav
          ...StorageCardGrid
          ...StorageReviews
          ...StorageCategoryCards
          ...StorageContactCards
          ...StorageVideo
        }
        related: field_related_listings {
          id
          title
          path {
            alias
          }
          langcode
          relationships {
            image: field_image {
              ...MediaImage
              relationships {
                field_media_image {
                  image: gatsbyImage(aspectRatio: 1, width: 500, formats: AUTO, placeholder: NONE)
                }
              }
            }
            icon: field_icon {
              ...MediaVectorImage
            }
          }
        }
      }
    }
    translations: allNodeProductListingPage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PlpTemplate;
