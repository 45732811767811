import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CMS_OVERLAY, CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import Link from '../Link';

interface Props {
  imageComponent: JSX.Element;
  condensed: boolean;
  drupal_id: string;
  title: string;
  url: string;
  summary?: string;
  promoted: boolean;
  cmsOverlay?: CMS_OVERLAY;
  cmsTheme?: CMS_THEME;
  type: string;
  name: string;
}

const StyledArticleOverlayCard = styled.article<{ cmsTheme: CMS_THEME }>`
  border: ${({ cmsTheme }) => `1px solid ${theme[cmsTheme].background.paper}`};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  transition: 0.3s box-shadow;
  width: 100%;

  &:focus-within,
  &:hover {
    box-shadow: 0 3px 3px rgb(0 0 0 / 25%);
  }

  @media (min-width: ${theme.media.md}) {
    border: 0;
  }
`;

const StyledLink = styled(Link)<{ cmsTheme: CMS_THEME; cmsOverlay: CMS_OVERLAY }>`
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  text-decoration: none;

  &:hover {
    color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
    text-decoration: underline;
  }

  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (min-width: ${theme.media.md}) {
    color: ${({ cmsOverlay }) => theme.common.overlay[cmsOverlay].text};

    &:hover {
      color: ${({ cmsOverlay }) => theme.common.overlay[cmsOverlay].text};
      text-decoration: underline;
    }
  }
`;

const articleImage = css`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 100%;
  overflow: hidden;

  & > * {
    height: inherit;
  }
`;

const truncate = css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const OverlayBox = styled.div<{ condensed: boolean; cmsOverlay: CMS_OVERLAY; cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.paper};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-end;
  padding: 1.5rem;
  width: 100%;

  @media (min-width: ${theme.media.md}) {
    background-color: ${({ cmsOverlay }) => theme.common.overlay[cmsOverlay].backgroundRgba};
    color: ${({ cmsOverlay }) => theme.common.overlay[cmsOverlay].text};
    position: absolute;
    height: 100%;
    text-shadow: ${({ cmsOverlay }) => theme.common.overlay[cmsOverlay].shadow};
    padding: ${props => (props?.condensed ? '1rem' : '2rem')};
  }
`;

const ArticleOverlayCard: React.FC<Props> = function ({
  title,
  url,
  type,
  name,
  drupal_id,
  summary,
  imageComponent,
  condensed,
  promoted,
  cmsOverlay = CMS_OVERLAY.DARK,
  cmsTheme = CMS_THEME.LIGHT,
  ...props
}: Props) {
  const { t } = useTranslation();
  return (
    <AnalyticsPoint
      type="component"
      node={{ type, name, title, drupal_id }}
      as={StyledArticleOverlayCard}
      cmsTheme={cmsTheme}
      {...props}
    >
      <div className={articleImage}>{imageComponent}</div>
      <OverlayBox
        cmsTheme={cmsTheme}
        cmsOverlay={cmsOverlay}
        className={condensed ? 'condensed' : ''}
        condensed={condensed}
      >
        {promoted && (
          <div
            className="typography_overline text-uppercase mb-2"
            style={{ letterSpacing: '0.2em' }}
          >
            {t('Promoted')}
          </div>
        )}
        <div className={condensed ? 'typography_h4' : 'typography_h3'}>
          <StyledLink cmsOverlay={cmsOverlay} cmsTheme={cmsTheme} className={cx(truncate)} to={url}>
            {title}
          </StyledLink>
        </div>
        {!condensed && summary && (
          <div
            style={{ zIndex: 1, position: 'relative' }}
            className={`${truncate} typography_body mb-0`}
          >
            {summary}
          </div>
        )}
      </OverlayBox>
    </AnalyticsPoint>
  );
};

export default ArticleOverlayCard;
