import { styled } from '@linaria/react';
import React from 'react';
import { Accordion, Stack } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import FaqItem from '../components/faq/FaqItem';
import RichText from '../components/rich_text/RichText';
import Typography from '../components/typography/Typography';
import { theme } from '../theme/theme';

type Props = {
  node: IStorageFaqSection;
};

const defaultSettings = {
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const AccordionWrapper = styled(Stack)<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  background: ${({ cmsTheme }) => theme[cmsTheme].background.default};
`;

const StorageFaqSection = ({ node }: Props) => {
  const { qa_sets: faqList } = node.relationships;
  const settings = Object.assign(defaultSettings, node.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const renderQASets = (faqItem: IStorageFaq) => (
    <FaqItem cmsTheme={cmsTheme} node={faqItem} key={faqItem.id} />
  );

  // If all the accordion sets are draft, don't render.
  if (!faqList || faqList.length === 0) {
    return null;
  }

  return (
    <AccordionWrapper data-id={node.drupal_id} cmsTheme={cmsTheme}>
      <Stack className="gap-4 py-4 container-xxl">
        <div>
          {node?.title && <Typography variant="h2">{node.title}</Typography>}
          {node?.body?.processed && <RichText cmsTheme={cmsTheme} body={node.body.processed} />}
        </div>
        <Accordion defaultActiveKey={node.relationships.qa_sets[0].id} id={node.id} flush>
          {faqList.map(renderQASets)}
        </Accordion>
      </Stack>
    </AccordionWrapper>
  );
};

export default StorageFaqSection;
