/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line import/no-unresolved
import wrapWithProvider from 'src/wrap-with-provider';

export const wrapRootElement = wrapWithProvider;

export const shouldUpdateScroll = ({ prevRouterProps, routerProps, getSavedScrollPosition }) => {
  if (prevRouterProps && prevRouterProps.location) {
    const { location: prevLocation } = prevRouterProps;
    const { location: currentLocation } = routerProps;

    if (prevLocation.pathname !== currentLocation.pathname) {
      const currentPosition = getSavedScrollPosition(currentLocation);
      window.scrollTo(...(currentPosition || [0, 0]));
    }
  }
  return false;
};

// export const onRouteUpdateDelayed = () => {
//   window.___emitter.emit('routeDelayed');
// };
