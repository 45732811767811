import { styled } from '@linaria/react';

const ButtonDiadWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0;
  max-width: 100%;
  width: fit-content;

  > * {
    flex-grow: 1;
  }

  > *:only-child {
    flex-grow: 0;
  }
`;

export default ButtonDiadWrapper;
