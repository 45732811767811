import Baz, { BazaarvoiceWidgetProps } from 'gatsby-plugin-bazaarvoice/BazaarvoiceWidget';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';

const BazaarvoiceWidget: React.FC<BazaarvoiceWidgetProps> = ({
  attributes: { productID, widgetID, seo = false },
  ...props
}) => {
  return (
    <AnalyticsPoint
      type="component"
      label="bazaarvoice widget"
      typeLabel="bv_rating_summary"
      as={Baz}
      attributes={{ productID, widgetID, seo }}
      {...props}
    />
  );
};
export default BazaarvoiceWidget;
