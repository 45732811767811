import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import * as React from 'react';
import { globals } from '../theme/globals';
import { MetatagProps } from '../utils/metatagHelpers';
import '../theme/layout.css';
import Footer from './Footer';
import Seo from './Seo';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import Header from './header';

interface LayoutProps {
  readonly children: React.ReactNode;
  theme?: string;
  metaData?: Array<MetatagProps>;
  language: Languages;
  translations?: Translations;
  node?: NodeContent;
}

const Layout: React.FC<LayoutProps> = function ({
  children,
  theme = 'default',
  language,
  translations,
  node,
  metaData,
}) {
  React.useEffect(() => {
    if (node?.internal?.type) {
      const type = node.internal.type.replace('node__', '');
      sendIt({
        event: 'page_categorization',
        eventCategory: 'page_categorization',
        eventAction: type,
        eventLabel: type,
        eventParams: {
          page_type: type,
          article_type: node?.relationships?.field_tags?.[0]?.name || '(not set)',
        },
      });
    }
  });
  // Uncomment to see page component tree that is used for analytic context.
  // const pageContext = usePageStore(state => state.pageContext);
  // console.log({ pageContext });
  return (
    <div className={globals}>
      <Seo htmlAttributes={{ lang: language }} />
      <Header language={language} translations={translations} />
      <AnalyticsPoint
        as="main"
        id="mainContent"
        role="main"
        type="page"
        pageType={node?.internal?.type || ''}
        label={node?.title || ''}
      >
        <Breadcrumbs metaData={metaData} />
        {children}
      </AnalyticsPoint>
      <Footer language={language} theme={theme} />
    </div>
  );
};

export default Layout;
