import React, { useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';

type Props = {
  node: IStorageDynamicScript;
};

const StorageDynamicScript = ({ node }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { attrs } = node;

  useEffect(() => {
    const scriptTag = document.createElement('script');

    attrs.forEach((attr: any) => {
      scriptTag.setAttribute(attr.name, attr.value);
    });

    containerRef?.current?.appendChild(scriptTag);
  }, [attrs]);

  return (
    <Container>
      <div ref={containerRef} />
    </Container>
  );
};

export default StorageDynamicScript;
