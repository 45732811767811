interface ColorProps {
  primary: string;
  primaryDark: string;
  dark: string;
  secondary: string;
  light: string;
  border: string;
}

export const defaultColors: ColorProps = {
  primary: '#e81c24',
  primaryDark: '#B72025',
  dark: '#2B2B2B',
  secondary: '#a9a390',
  light: '#EDF0F8',
  border: '#E6E6E6',
};
