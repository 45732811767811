import { styled } from '@linaria/react';
import Slider from 'react-slick';
import { theme } from 'src/theme/theme';

export const StyledCarousel = styled(Slider)`
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    & > * {
      height: 100%;
    }
  }
`;

export const ArrowContainer = styled.div<{ default: boolean }>`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b3b3b3;
  border-radius: 50%;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  &.slick-arrow {
    &.slick-next,
    &.slick-prev {
      width: 34px;
      height: 34px;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::before {
      content: '';
    }
  }

  &.prev-arrow {
    left: ${props => (!props.default ? '20px' : '-25px')};

    @media (max-width: ${theme.media.md}) {
      left: 20px;
    }
  }

  &.next-arrow {
    right: ${props => (!props.default ? '20px' : '-25px')};

    @media (max-width: ${theme.media.md}) {
      right: 20px;
    }
  }
`;

export const DotsContainer = styled.div`
  z-index: 1;
  bottom: 0;
  left: 50%;
  margin-bottom: 10px;
  position: absolute;
  width: fit-content;

  ul {
    background-color: hsla(0, 0%, 100%, 0.5);
    border-radius: 17px;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    position: relative;
    transform: translateX(-50%);

    li {
      background: transparent;
      border-radius: 16px;
      box-sizing: border-box;
      display: block;
      height: 16px;
      width: 16px;

      &.slick-active {
        button {
          &::before {
            background-color: #424242;
          }
        }
      }

      button {
        background: transparent;
        border: 0;
        color: transparent;
        cursor: pointer;
        display: block;
        font-size: 0;
        padding: 0;

        &:hover {
          &::before {
            background-color: #424242;
          }
        }

        &::before {
          border: 2px solid #424242;
          border-radius: 50%;
          content: '';
          display: block;
          height: 12px;
          width: 12px;
          background-color: #fff;
          border: 2px solid #424242;
          opacity: 1;
        }
      }
    }
  }
`;
