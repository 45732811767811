import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useMemo } from 'react';

type TBreedInfoMap = {
  [key: string]: {
    label: string;
    weight: number;
  };
};

type CMSBreedInfo = {
  [key: string]: string;
};

export const useGetBreedInfo = (data: CMSBreedInfo) => {
  const { t } = useTranslation();

  const BreedInfoMap: TBreedInfoMap = {
    activities: {
      label: t('Activities'),
      weight: 7,
    },
    coat: {
      label: t('Coat'),
      weight: 4,
    },
    color: {
      label: t('Color'),
      weight: 5,
    },
    energy: {
      label: t('Energy'),
      weight: 6,
    },
    height: {
      label: t('Height'),
      weight: 2,
    },
    indoor_outdoor: {
      label: t('Indoor or Outdoor'),
      weight: 8,
    },
    size: {
      label: t('Size'),
      weight: 1,
    },
    weight: {
      label: t('Weight'),
      weight: 3,
    },
  };

  if (data) {
    return Object.keys(data)
      .map((key: string) => {
        // Invalid info key or no description for valid key.
        if (!BreedInfoMap[key] || !data[key]) return null;
        return {
          key,
          title: BreedInfoMap[key].label,
          description: data[key],
          weight: BreedInfoMap[key].weight,
        };
      })
      .filter(x => x !== null)
      .sort((a, b) => {
        if (a?.weight && b?.weight) {
          return a.weight - b.weight;
        }
        return 0;
      });
  }

  return [];
};
