import { styled } from '@linaria/react';
import * as React from 'react';
import Link from '../Link';

const StyledMenu = styled.ul`
  list-style: none;

  a {
    padding: 0.25rem 0.75rem;
    display: flex;
    text-decoration: none;
    line-height: 1rem;
    letter-spacing: 2.5px;

    span {
      text-align: center;
      position: relative;

      &::after {
        content: '';
        display: block;
        height: 2px;
        width: 0;
        background: transparent;
        position: relative;
        bottom: -2px;
        margin: 0 auto;
        transition: width 0.1s linear;
      }
    }

    &:hover {
      span::after {
        background: currentColor;
        width: 100%;
      }
    }
  }
`;

interface ParagraphProps extends Paragraph {
  link: LinkProps;
  menu: [LinkProps];
}

interface ParagraphMenuProps {
  node: ParagraphProps;
  props?: {
    location?: string;
  };
}

const ParagraphMenu: React.FC<DeepReadonly<ParagraphMenuProps>> = function ({ node }) {
  const classes = node.behavior_settings?.decoupled_styles?.classes || [];
  const allClasses = [...classes, 'menu'];

  if (node?.menu.length) {
    return (
      <StyledMenu className={allClasses.join(' ')}>
        {node.menu.map((link: LinkProps, index) => {
          const attributes = link?.options?.attributes || {};
          return (
            <li key={index}>
              <Link to={link.url} {...attributes}>
                <span className="text-uppercase">{link.title}</span>
              </Link>
            </li>
          );
        })}
      </StyledMenu>
    );
  }
  return null;
};

export default ParagraphMenu;
