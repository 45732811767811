import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import { formatSku } from '../../utils/helpers';
import ProductCard from '../product_card/ProductCard';
import ProductCardSkeleton from '../product_card/ProductCardSkeleton';
import NoResults from './NoResults';
import { SearchListingContext } from './SearchListingContext';

const OfferSearchResults: React.FC = () => {
  const context = useContext(SearchListingContext);

  if (!context) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }

  const { data, loaded } = context || {};
  const { search_results } = data || {};

  if (!loaded) {
    return (
      <Row className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3">
        {[...Array(12)].map((_, i) => {
          return (
            <Col key={`loading-card-${i}`} xs={6} md={4} className="d-flex">
              <ProductCardSkeleton />
            </Col>
          );
        })}
      </Row>
    );
  }

  if (!search_results) {
    return <NoResults />;
  }

  return (
    <Row id="search-results" className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3">
      {Object.values(search_results).map(node => {
        const image = node.field_media_image
          ? process.env.GATSBY_DRUPAL_ENDPOINT + node.field_media_image.substring(1)
          : '';
        const sku = node.upc ? node.upc : '';
        // const sku = node.upc ? formatSku(node.upc) : '';

        return (
          <Col key={node.nid} xs={6} md={4} className="d-flex">
            <ProductCard
              image={{
                images: {
                  fallback: {
                    src: image,
                  },
                },
                layout: 'constrained',
                width: 800,
                height: 800,
              }}
              type="offers"
              brand={node.field_brand}
              link={node.url}
              title={node.title}
              sku={sku}
              bvID={node.bazaarvoice_id}
            />
          </Col>
        );
      })}
    </Row>
  );
};

OfferSearchResults.displayName = 'SearchListing.OfferSearchResults';

export default OfferSearchResults;
