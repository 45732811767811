import { styled } from '@linaria/react';
import React from 'react';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';

interface Props {
  children: React.ReactNode;
  size?: 1 | 2 | 3;
  radius?: 'rounded1' | 'rounded2' | 'rounded3';
  cmsTheme?: CMS_THEME;
  className?: string;
}

interface StyledProps extends Omit<Props, 'cmsTheme' | 'radius' | 'size'> {
  cmsTheme: CMS_THEME;
  size: 1 | 2 | 3;
  radius: 'rounded1' | 'rounded2' | 'rounded3';
}

const StyledThemedCard = styled.div<StyledProps>`
  padding: ${({ size }) => theme.common.spacing[size]};
  border-radius: ${({ radius }) => theme.common.shape[radius]};
  background: ${({ cmsTheme }) => theme[cmsTheme].background.paper};
`;

const ThemedCard: React.FC<Props> = ({
  size = 2,
  radius = 'rounded2',
  cmsTheme = CMS_THEME.LIGHT,
  ...props
}) => (
  <StyledThemedCard size={size} radius={radius} cmsTheme={cmsTheme} {...props}>
    {props.children}
  </StyledThemedCard>
);

export default ThemedCard;
