import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContactUsForm from 'src/components/forms/ContactUsForm';
import { useWebform } from 'src/hooks/useWebform';

interface StorageContactUsFormProps {
  node?: IStorageWebForm;
  standalone?: boolean;
}

const StorageContactUsForm = ({ node: data, standalone }: StorageContactUsFormProps) => {
  const { getFieldDataByName } = useWebform(data?.relationships?.form);
  if (standalone) {
    return (
      <ContactUsForm getFieldDataByName={getFieldDataByName} langcode={data?.langcode || 'en'} />
    );
  }

  const heading = (
    <>
      <h2 className="text-left">{data?.title}</h2>

      {data?.description && <div className="text-left mb-5">{data?.description}</div>}
    </>
  );
  return (
    <Container className="py-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          {heading}
          <ContactUsForm
            getFieldDataByName={getFieldDataByName}
            langcode={data?.langcode || 'en'}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StorageContactUsForm;
