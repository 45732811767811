import React from 'react';

interface NodeWithDID {
  drupal_id: string;
  id?: string;
  internal?: {
    type?: string;
  };
  name: string;
  title?: string;
  type: string;
}

interface NodeWithID {
  drupal_id?: string;
  id: string;
  internal?: {
    type?: string;
  };
  name: string;
  title?: string;
  type: string;
}

type Node = ({ drupal_id: string } & NodeWithDID) | ({ drupal_id?: string } & NodeWithID);

type PageProps = {
  type: 'page';
  pageType: string;
  label?: string;
  as?: React.ElementType;
  node?: Node;
  typeLabel?: string;
  additionalParams?: Record<string, string>;
  className?: string;
  children?: React.ReactNode;
  [key: string]: any;
};

type ModuleComponents = {
  type: 'module' | 'component';
};

interface WithoutNode extends ModuleComponents {
  node?: string;
  label: string;
  title?: string;
  // Additional level to go up to find parent with siblings
  addParentLevel?: number;
  typeLabel: string;
  pageType?: string;
  action?: string;
  category?: string;
  eventOverride?: string;
  className?: string;
  as?: React.ElementType;
  children?: React.ReactNode;
  additionalParams?: Record<string, string>;
  [key: string]: any;
}

interface WithNode extends ModuleComponents {
  node: Node;
  label?: string;
  title?: string;
  // Additional level to go up to find parent with siblings
  addParentLevel?: number;
  typeLabel?: string;
  eventLabel?: string;
  pageType?: string;
  className?: string;
  additionalParams?: Record<string, string>;
  as?: React.ElementType;
  children?: React.ReactNode;
  [key: string]: any;
}

type NodeLogic<T> = T extends WithNode ? WithNode : WithoutNode;

type Main =
  | PageProps
  | ({ node?: Node } & NodeLogic<WithoutNode>)
  | ({ node: Node } & NodeLogic<WithNode>);

/**
 * if type is page only requires pageType and type label
 * @label is title of the page
 *
 * if type is component or module then either node or label and typelabel are required.
 * where typeLabel is the cms given type and label is the admin name given
 **/
const AnalyticsPoint: React.FC<Main> = React.forwardRef<any, Main>(
  (
    {
      type,
      node,
      label,
      children,
      pageType,
      typeLabel,
      className,
      action,
      category,
      eventLabel,
      title,
      addParentLevel,
      eventOverride,
      additionalParams,
      as: Component = 'div',
      ...rest
    },
    ref
  ) => {
    return (
      <Component
        {...rest}
        title={title}
        className={`${className} js-track-stop-point`}
        data-internal-id={node?.drupal_id ?? node?.id ?? 'no id'}
        data-action={action}
        data-category={category}
        data-parent-level={addParentLevel}
        data-type-label={typeLabel ?? node?.type ?? node?.internal?.type ?? ''}
        data-event-override={eventOverride}
        data-additional-params={additionalParams}
        data-page-type={pageType}
        data-type={type}
        data-event-label={eventLabel}
        data-label={label ?? node?.name ?? title ?? ''}
        data-title={title ?? node?.title ?? label ?? ''}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);

export default AnalyticsPoint;
