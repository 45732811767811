/* eslint-disable no-useless-escape */
import { debounce } from 'lodash';

export const onRouteUpdate = () => {
  // Check if the script has loaded
  const pricespiderLoaded = typeof window !== 'undefined' && window.PriceSpider !== undefined;

  // If the script has loaded, run your code
  if (pricespiderLoaded) {
    // Assigning to new function on PriceSpider object for easier testing.
    window.PriceSpider.debouncedRebind = debounce(window.PriceSpider.rebind, 500);
  } else {
    // If the script hasn't loaded, wait and try again
    setTimeout(onRouteUpdate, 50);
  }
};
