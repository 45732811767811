import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import { theme } from '../theme/theme';

const ImageContentContainer = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};

  img {
    max-width: 100%;
  }
`;

interface StorageImageContentProps {
  node: IStorageImageContent;
}

const DefaultSettings = {
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_heading: {
    element: 'h2' as keyof JSX.IntrinsicElements,
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h3' as keyof JSX.IntrinsicElements,
    style: 'typography_h3',
  },
  entity_variant: {
    content_position: 'right',
    theme: CMS_THEME.LIGHT,
  },
};

const StorageImageContent: React.FC<StorageImageContentProps> = function ({ node: imageContent }) {
  const default_settings = { ...DefaultSettings };
  const settings = Object.assign(default_settings, imageContent?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const primaryButtonVariation = settings.entity_buttons?.primary_button;
  const entityHeading = settings?.entity_heading ?? DefaultSettings.entity_heading;
  const entitySubheading = settings?.entity_subheading ?? DefaultSettings.entity_subheading;
  const { element: HeadingElement, style: headingStyle } = entityHeading;
  const { element: SubheadingElement, style: subheadingStyle } = entitySubheading;
  const media_image = imageContent?.relationships?.image;

  return (
    <ImageContentContainer
      data-id={imageContent.drupal_id}
      className={cx('py-3 py-md-5 px-0')}
      fluid
      cmsTheme={cmsTheme}
    >
      <Container>
        <Row
          className={cx(
            settings?.entity_variant.content_position === 'right' ? 'flex-row-reverse' : null,
          )}
        >
          <Col>
            <GatsbyImage
              alt={media_image?.field_media_image?.alt}
              image={media_image?.relationships?.field_media_image?.gatsbyImage}
              className="rounded-2 mb-3 mb-md-0"
            />
          </Col>
          <Col
            xs={12}
            md={6}
            className="card-content d-md-flex flex-column justify-content-center ps-lg-5"
          >
            <HeadingElement className={headingStyle}>{imageContent.title}</HeadingElement>
            {imageContent.subtitle && (
              <SubheadingElement className={subheadingStyle}>
                {imageContent.subtitle}
              </SubheadingElement>
            )}
            {imageContent.body?.processed && (
              <div
                className="mt-3"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: imageContent.body.processed }}
              />
            )}
            {imageContent.links && imageContent.links?.length > 0 && (
              <ButtonDiadWrapper>
                {imageContent.links.map((linkObject, index) => {
                  return (
                    <AnalyticsPoint
                      type="component"
                      node={{
                        title: linkObject.title,
                        name: linkObject.title,
                        drupal_id: '',
                        type: 'image_content_link',
                      }}
                      key={index}
                      cmsTheme={cmsTheme}
                      {...linkObject.options?.attributes}
                    >
                      <div className="mt-auto">
                        <ButtonLink
                          variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
                          to={linkObject.url}
                          cmsTheme={cmsTheme}
                          {...linkObject.options?.attributes}
                        >
                          {linkObject.title}
                        </ButtonLink>
                      </div>
                    </AnalyticsPoint>
                  );
                })}
              </ButtonDiadWrapper>
            )}
          </Col>
        </Row>
      </Container>
    </ImageContentContainer>
  );
};

export default StorageImageContent;
