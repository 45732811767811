import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { styled } from '@linaria/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'src/components/Link';
import BazaarvoiceWidget from 'src/components/bazaarvoice/BazaarvoiceWidget';
import PricespiderWidget from 'src/components/pricespider/PricespiderWidget';
import ProductSelectFormula from 'src/components/product/ProductSelectFormula';
import ProductStatus from 'src/components/product/ProductStatus';
import { theme } from 'src/theme/theme';
import { NodeProductProps } from '../types';

const StyledBuyNowContainer = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 1000;

  .btn {
    border-radius: 0 !important;
    padding-block: 1rem !important;
    width: 100% !important;
  }

  @media (min-width: ${theme.media.md}) {
    background: none;
    border-top: 0;
    margin-top: 1rem;
    max-width: 300px;
    padding-inline: 0;
    position: relative;
    z-index: unset;

    .btn {
      border-radius: ${theme.common.shape.roundedPill} !important;
    }
  }
`;

interface Props {
  node: NodeProductProps;
  bvID: string;
  sku: string;
  productStatus:
    | (
        | TaxonomyTermStatus
        | {
            name: string;
            variant: AlertLevels;
            link: LinkProps;
          }
      )[]
    | null;
  brand: {
    url: string;
    title: string;
  } | null;
  relatedProducts: {
    edges: [
      {
        node: NodeProductProps;
      },
    ];
  };
}

const ProductTitle: React.FC<Props> = ({
  node,
  productStatus,
  brand,
  relatedProducts,
  bvID,
  sku,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {productStatus && <ProductStatus statuses={productStatus} />}
      <AnalyticsPoint
        type="module"
        label={node.title}
        typeLabel="product_title"
        as="div"
        className="p-lg-5 h-100 d-md-flex flex-md-column align-items-md-start justify-content-md-center"
      >
        <h2 className="h3">{node.title}</h2>
        {brand && (
          <p>
            {t('By')}&nbsp;
            <AnalyticsPoint
              as="span"
              type="component"
              label={brand.title}
              action="brand tout"
              typeLabel="product_brand_tout"
            >
              <Link to={brand.url} className="text-decoration-none">
                {brand.title}
              </Link>
            </AnalyticsPoint>
          </p>
        )}
        <BazaarvoiceWidget
          attributes={{
            productID: bvID,
            widgetID: 'rating_summary',
          }}
        />
        {relatedProducts?.edges.length > 0 && (
          <ProductSelectFormula node={node} products={relatedProducts.edges} />
        )}
        {sku && (
          <StyledBuyNowContainer>
            <PricespiderWidget
              title={node.title}
              className="d-block btn btn-primary rounded-pill px-5"
              sku={sku}
            />
          </StyledBuyNowContainer>
        )}
      </AnalyticsPoint>
    </>
  );
};
export default ProductTitle;
