import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { getFocalPoint } from 'src/utils/mediaHelpers';
import { CMS_OVERLAY, CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import { theme } from '../theme/theme';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';

const HeroContainer = styled(Container)<{
  cmsTheme: CMS_THEME;
  cmsOverlayTheme: CMS_OVERLAY;
}>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.alternate};

  img {
    max-width: 100%;
  }

  .hero-card {
    background-color: ${({ cmsTheme }) => theme[cmsTheme].background.alternate};
  }

  &.skrim {
    .hero-card {
      background-color: transparent;
    }

    text-shadow: ${({ cmsOverlayTheme }) => theme.common.overlay[cmsOverlayTheme].shadow};

    .gatsby-image-wrapper::after {
      background-color: ${({ cmsOverlayTheme }) =>
        theme.common.overlay[cmsOverlayTheme].background};
      opacity: ${theme.common.overlay.bgSoft};
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
    }
  }

  .gatsby-image-wrapper {
    height: 575px;

    &.tall {
      height: 400px;
    }
  }

  .hero-content {
    position: relative;
  }

  .hero-card {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
  }

  &.skrim {
    .gatsby-image-wrapper::after {
      display: none;
    }
  }

  .body {
    font-size: 14px;
  }

  // Tablet.
  @media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.lg}) {
    .gatsby-image-wrapper {
      height: 384px;

      &.tall {
        height: 576px;
      }
    }
  }

  // Desktop.
  @media (min-width: ${theme.media.lg}) {
    .hero-content.overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .hero-card {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }

    .gatsby-image-wrapper {
      height: 240px;

      &.tall {
        height: 480px;
      }
    }
    &.skrim {
      .gatsby-image-wrapper::after {
        display: block;
      }
    }
  }
  // Large Desktop.
  @media (min-width: ${theme.media.xl}) {
    .gatsby-image-wrapper {
      height: 400px;

      &.tall {
        height: 600px;
      }
    }
  }

  &.wide {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    > .container-lg {
      max-width: unset;
    }

    .gatsby-image-wrapper {
      border-radius: unset !important;
    }

    .hero-media {
      padding-left: 0;
      padding-right: 0;
    }

    // Small up.
    @media (max-width: ${theme.media.lg}) {
      .hero-content {
        padding: 0;
      }
    }
    // Large up.
    @media (min-width: ${theme.media.lg}) {
      .gatsby-image-wrapper {
        height: 248px;

        &.tall {
          height: 496px;
        }
      }
    }
    // X-large up.
    @media (min-width: ${theme.media.xl}) {
      .gatsby-image-wrapper {
        height: 400px;

        &.tall {
          height: 600px;
        }
      }
    }
  }
`;

const TextContainer = styled.div<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => {
    return theme[cmsTheme].text.default;
  }};
`;

interface StorageCardProps {
  node: IStorageHeroCard;
  eagerLoad?: Boolean;
}

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    hero_variant_type: 'tall',
    content_position: 'left',
    content_card: false,
    fluid: true,
    theme: CMS_THEME.LIGHT,
    overlay: CMS_OVERLAY.DARK,
  },
  entity_buttons: {
    primary_button: 'btn',
  },
};

const StorageHeroCard: React.FC<StorageCardProps> = function ({ node: heroCard, eagerLoad }) {
  const settings = Object.assign(DefaultSettings, heroCard?.behaviors || {});
  const direction = settings?.entity_variant?.content_position;
  const hasCard = settings?.entity_variant?.content_card;
  const isFullWidth = settings?.entity_variant?.fluid;
  const imageHeight = settings?.entity_variant?.hero_variant_type;
  const cmsTheme = heroCard.behaviors?.entity_variant?.theme || CMS_THEME.LIGHT;
  const cmsOverlayTheme = heroCard.behaviors?.entity_variant?.overlay || CMS_OVERLAY.LIGHT;
  const primaryButtonVariation = settings.entity_buttons.primary_button;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const { element: SubheadingElement, style: subheadingStyle } = settings.entity_subheading;
  const hero_image = heroCard.relationships.image?.relationships.field_media_image.gatsbyImage;

  const lg = imageHeight === 'short' && !isFullWidth ? 7 : 6;
  const { indexOnPage } = heroCard;

  return (
    <HeroContainer
      data-id={heroCard.drupal_id}
      className={cx(
        'px-0',
        isFullWidth && 'wide',
        !settings?.entity_variant?.content_card && 'skrim',
        `${isFullWidth ? 'py-0' : 'py-3 py-lg-5'}`,
      )}
      fluid
      cmsTheme={cmsTheme}
      cmsOverlayTheme={cmsOverlayTheme}
    >
      <Container fluid="lg">
        <Row
          className={cx(
            settings?.entity_variant?.content_position === 'right' ? 'flex-row-reverse' : null,
            'position-relative',
          )}
        >
          <Col xs={12} className="position-relative hero-media">
            <GatsbyImage
              alt={heroCard.relationships.image?.field_media_image?.alt}
              image={hero_image}
              className={cx('rounded-2', imageHeight)}
              objectFit="cover"
              objectPosition={getFocalPoint(heroCard.relationships.image)}
              loading={indexOnPage === 0 || eagerLoad ? 'eager' : 'lazy'}
            />
          </Col>
          <Col
            xs={12}
            lg={{ span: lg, offset: direction === 'right' ? 12 - lg : 0 }}
            xl={{ span: 5, offset: direction === 'right' ? 7 : 0 }}
            className={cx(
              'hero-content overlay d-flex flex-column justify-content-center overflow-hidden',
              direction === 'right' ? 'pe-lg-5' : 'ps-lg-5',
              hasCard && 'as-card',
            )}
          >
            <div className="rounded-2 p-3 hero-card">
              <TextContainer cmsTheme={cmsTheme}>
                <HeadingElement className={headingStyle}>{heroCard.title}</HeadingElement>
                {heroCard?.subtitle && (
                  <SubheadingElement className={subheadingStyle}>
                    {heroCard.subtitle}
                  </SubheadingElement>
                )}
                {heroCard?.body?.processed && (
                  <div
                    className="mt-3"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: heroCard.body.processed }}
                  />
                )}
              </TextContainer>
              <ButtonDiadWrapper>
                {heroCard.links.map((linkObject, index) => {
                  return (
                    <AnalyticsPoint
                      type="component"
                      node={{
                        title: linkObject.title,
                        name: linkObject.title,
                        type: 'hero_link',
                        drupal_id: '',
                      }}
                      as={ButtonLink}
                      key={linkObject.url}
                      variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
                      to={linkObject.url}
                      cmsTheme={cmsTheme}
                      mode="red"
                      {...linkObject?.options?.attributes}
                    >
                      {linkObject.title}
                    </AnalyticsPoint>
                  );
                })}
              </ButtonDiadWrapper>
            </div>
          </Col>
        </Row>
      </Container>
    </HeroContainer>
  );
};

export default StorageHeroCard;
