import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { styled } from '@linaria/react';
import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProductCard from 'src/components/product_card/ProductCard';
import Carousel from 'src/components/carousel/Carousel';
import { theme } from 'src/theme/theme';
import { NodeProductProps } from '../types';

const StyledRelatedProducts = styled(Row)`
  .h-scroll-sm {
    width: 100%;
  }

  @media (min-width: ${theme.media.lg}) {
    .h-scroll-sm {
      width: 25%;
    }

    .h-scroll-lg-3 {
      width: 24%;
    }
  }
`;

interface Props {
  relatedProducts: {
    edges: [
      {
        node: NodeProductProps;
      }
    ];
  };
}

const ItemsContainer = ({
  itemsCount,
  isMobile,
  children,
}: {
  itemsCount: number;
  isMobile: boolean;
  children: ReactNode;
}) => {
  if (itemsCount < 5) return <StyledRelatedProducts>{children}</StyledRelatedProducts>;

  return (
    <Carousel
      customSettings={{
        dots: !isMobile,
        infinite: false,
        slidesToShow: isMobile ? 1.2 : 4,
        slidesToScroll: 1,
      }}
      defaultArrowsAndDots
    >
      {children}
    </Carousel>
  );
};

const RelatedProducts: React.FC<Props> = ({ relatedProducts }) => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint.xs;

  return (
    <AnalyticsPoint
      as={Container}
      className="py-5"
      type="module"
      typeLabel="product_related_products"
      label="related products"
    >
      <Row>
        <Col>
          <h2 className="h4 mb-3 text-capitalize">{t('Discover similar products')}</h2>
        </Col>
      </Row>
      <Row>
        <ItemsContainer itemsCount={relatedProducts.edges.length} isMobile={isMobile}>
          {relatedProducts.edges.map(product => {
            const { node: productNode } = product;
            const productSkus = productNode?.relationships?.skus;
            const productSku = productSkus && productSkus.length > 0 ? productSkus[0].upc : '';
            const bvID = productNode?.field_bazaarvoice_id
              ? `${productNode?.field_bazaarvoice_id.slice(
                  0,
                  5
                )}-${productNode?.field_bazaarvoice_id.slice(5)}`
              : '';
            const relatedCount = relatedProducts.edges.length;
            const extraClasses = [];
            const url = productNode.field_alternate_url?.uri
              ? productNode.field_alternate_url.uri
              : productNode.path.alias;

            if (relatedCount > 1) {
              extraClasses.push('h-scroll-sm h-scroll-md-4');
              if (relatedCount > 4) {
                extraClasses.push('h-scroll-lg-3 px-3');
              }
            }

            return (
              <Col
                lg={3}
                key={productNode.path.alias}
                className={`d-flex h-100 ${extraClasses.join(' ')}`}
              >
                <AnalyticsPoint
                  as={ProductCard}
                  type="component"
                  node={{
                    ...productNode,
                    name: productNode.title,
                    type: productNode.internal?.type || '',
                  }}
                  action="view similar products"
                  category="product details"
                  eventLabel={productNode.title}
                  typeLabel="product_related_product"
                  title={productNode.title}
                  image={productNode?.relationships?.image?.gatsbyImage}
                  link={url}
                  bvID={bvID || ''}
                  sku={productSku ?? ''}
                  // sku={(productSku && formatSku(productSku)) || ''}
                />
              </Col>
            );
          })}
        </ItemsContainer>
      </Row>
    </AnalyticsPoint>
  );
};

export default RelatedProducts;
