import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Container } from 'react-bootstrap';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

interface StoragePageTitleProps {
  node: IStoragePageTitle;
}

const DefaultSettings = {
  entity_heading: {
    element: 'h1',
    style: 'typography_h1',
  },
  entity_subheading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_variant: {
    text_align: 'center',
    theme: CMS_THEME.LIGHT,
  },
};

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StoragePageTitle: React.FC<StoragePageTitleProps> = ({ node: pageTitle }) => {
  const settings = Object.assign(DefaultSettings, pageTitle?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const primaryButtonVariation = settings?.entity_buttons?.primary_button;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const { element: SubheadingElement, style: subheadingStyle } = settings.entity_subheading;
  const textAlign = settings.entity_variant.text_align;

  return (
    <Wrapper data-id={pageTitle.drupal_id} className="py-3 py-lg-5 px-0" fluid cmsTheme={cmsTheme}>
      <Container
        className={cx(
          `d-flex flex-column text-${textAlign}`,
          textAlign === 'center' && 'align-items-center'
        )}
      >
        {pageTitle?.title ? (
          <HeadingElement className={cx(headingStyle)}>{pageTitle.title}</HeadingElement>
        ) : null}
        {pageTitle?.subtitle ? (
          <SubheadingElement className={cx('mt-4', subheadingStyle)}>
            {pageTitle.subtitle}
          </SubheadingElement>
        ) : null}
        {pageTitle?.body?.processed ? (
          <RichText cmsTheme={cmsTheme} className={cx('mt-4')} body={pageTitle.body.processed} />
        ) : null}
        {pageTitle?.links?.length && pageTitle.links.length > 0 ? (
          <ButtonDiadWrapper className="mt-3">
            {pageTitle.links.map((linkObject, index) => {
              return (
                <AnalyticsPoint
                  type="component"
                  node={{
                    title: linkObject.title,
                    name: linkObject.title,
                    drupal_id: '',
                    type: 'page_title_link',
                  }}
                  as={ButtonLink}
                  className="me-sm-3 mb-3 mb-sm-0"
                  variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
                  key={index}
                  cmsTheme={cmsTheme}
                  to={linkObject.url}
                  {...linkObject?.options?.attributes}
                >
                  {linkObject.title}
                </AnalyticsPoint>
              );
            })}
          </ButtonDiadWrapper>
        ) : null}
      </Container>
    </Wrapper>
  );
};

export default StoragePageTitle;
