import React, { useEffect } from 'react';

type BVWidget = 'reviews' | 'inline_rating' | 'rating_summary';
export type BVMode = 'staging' | 'production';

export type BazaarvoiceWidgetProps = {
  attributes: {
    widgetID: BVWidget;
    productID: string;
    seo?: boolean;
  };
};

const BazaarvoiceWidget = function ({
  attributes: { productID, widgetID, seo = false },
  ...props
}: BazaarvoiceWidgetProps) {
  useEffect(() => {
    return () => {
      if (widgetID === 'rating_summary') {
        const ratingJsonLd = document.querySelector('#bv-jsonld-bvloader-summary');
        if (ratingJsonLd && ratingJsonLd.parentElement) {
          ratingJsonLd.parentElement.removeChild(ratingJsonLd);
        }
      }

      if (widgetID === 'reviews') {
        const reviewJsonLd = document.querySelector('#bv-jsonld-reviews-data');
        if (reviewJsonLd && reviewJsonLd.parentElement) {
          reviewJsonLd.parentElement.removeChild(reviewJsonLd);
        }
      }
    };
  }, [widgetID]);

  return (
    <div
      data-bv-show={widgetID}
      data-bv-product-id={productID}
      data-bv-seo={seo}
      {...props}
      className="js-track"
    />
  );
};

export default BazaarvoiceWidget;
