import { styled } from '@linaria/react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export const LanguageSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid #e81c24;
    padding: 0px 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    h6 {
      text-align: center;
      padding: 0px 20px;
      font-weight: bold;
    }
  }
`;

export const StyledPopover = styled(Popover)`
  .popover-arrow {
    display: none;
  }
`;

export const UnstyledButton = styled.button`
  all: unset;
  margin-left: 10px;
`;

export const LanguageSelectButton = styled.button`
  all: unset;
  margin-left: 16px;
  display: flex;

  svg {
    margin-left: 4px;
  }
`;
