import { PageProps, graphql } from 'gatsby';
import { styled } from '@linaria/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from 'src/common/enums';
import Seo from 'src/components/Seo';
import { Button, ButtonLink } from 'src/components/button/Button';
import { formatBytes } from 'src/templates/pdf/helpers/formatBytes';
import { theme } from 'src/theme/theme';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from 'src/utils/metatagHelpers';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const Document = React.lazy(() =>
  import('react-pdf/dist/esm/entry.webpack').then(module => ({ default: module.Document })),
);
const Page = React.lazy(() =>
  import('react-pdf/dist/esm/entry.webpack').then(module => ({ default: module.Page })),
);
const ViewerContainer = styled.div`
  background-color: ${theme.dark.background.default};
  color: ${theme.dark.text.default};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
`;
const Header = styled.div`
  padding: 1rem;
  text-align: center;
`;
const ZoomControl = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  margin-right: 1rem;

  @media screen and (max-width: 768px) {
    display: none;
  }

  button {
    background-color: transparent;
    border: none;
    color: ${theme.dark.text.default};
    cursor: pointer;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem;

    &:hover:not(:disabled) {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;
const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 15rem);
`;
const PdfViewer = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  canvas {
    border: none;
    max-width: 100%;
    height: auto !important;
  }
`;
const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
`;

interface PDFProps {
  node: {
    name: string;
    relationships: {
      field_media_document: {
        url: string;
        filesize: number;
      };
    };
  };
}

const PDFTemplate: React.FC<PageProps<PDFProps>> = function ({ data: { node } }) {
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const { t } = useTranslation();

  const pdf = node.relationships.field_media_document;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setPageCount(numPages);
  }

  const isSSR = typeof window === 'undefined';

  function zoomIn() {
    setScale(scale + 0.2);
  }

  function zoomOut() {
    setScale(scale - 0.2);
  }

  return (
    <ViewerContainer>
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <Header>
        <h1 className="typography_h5">{node.name}</h1>
        <p>{t('Page {{pageNumber}} of {{pageCount}}', { pageNumber, pageCount })}</p>
      </Header>
      <MainContent>
        {!isSSR && (
          <React.Suspense fallback={<div>Loading...</div>}>
            <PdfViewer>
              <Document file={pdf.url} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale={scale} />
              </Document>
            </PdfViewer>
          </React.Suspense>
        )}
      </MainContent>
      <Footer>
        <div className="d-flex gap-2">
          <ButtonLink cmsTheme={CMS_THEME.DARK} variant="btn-outline" to={pdf.url} download>
            {t('Download')} ({formatBytes(pdf.filesize)})
          </ButtonLink>
          <ZoomControl>
            <button type="button" onClick={zoomOut} disabled={scale <= 0.2}>
              -
            </button>
            <span>{Math.round(scale * 100)}%</span>
            <button type="button" onClick={zoomIn} disabled={scale >= 5.0}>
              +
            </button>
          </ZoomControl>
          {pageNumber > 1 && (
            <Button
              cmsTheme={CMS_THEME.DARK}
              variant="btn"
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              {t('Prev')}
            </Button>
          )}
          {pageCount && pageNumber < pageCount && (
            <Button
              cmsTheme={CMS_THEME.DARK}
              variant="btn"
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              {t('Next')}
            </Button>
          )}
        </div>
      </Footer>
    </ViewerContainer>
  );
};

export default PDFTemplate;

export const query = graphql`
  query ($id: String!, $language: String!) {
    node: mediaDocument(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      path {
        alias
      }
      name
      default_langcode
      langcode
      ...Metatag
      relationships {
        field_media_document {
          url
          filesize
          filename
        }
      }
      drupal_internal__mid
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
