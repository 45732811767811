import { GatsbyBrowser } from 'gatsby';
import CustomPages from './src/CustomPages';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  if (
    CustomPages[location.pathname] &&
    CustomPages[location.pathname].component === 'src/templates/custom-pages/pet-food-finder.tsx'
  ) {
    window.drupalSettings = {};
    window.drupalSettings.tesla = CustomPages[location.pathname].context;
  }
};
