import { TranslateFunctionType } from './TranslateFunctionType';

// A catch-all message for required fields. Prefer using the more specific methods
// below. They will provide better translations.
export const validateRequired = (label: string, t: TranslateFunctionType) => {
  if (label === undefined) {
    return t('Required Field');
  }
  return `${t('Required Field')}- ${label}`;
};

export const validateRequiredFirstName = (t: TranslateFunctionType) => {
  return t('Please fill out a First Name.');
};

export const validateRequiredLastName = (t: TranslateFunctionType) => {
  return t('Please fill out a Last Name.');
};

export const validateRequiredEmail = (t: TranslateFunctionType) => {
  return t('Please fill out an Email.');
};

export const validateRequiredPhoneNumber = (t: TranslateFunctionType) => {
  return t('Please fill out a Phone Number.');
};

export const validateRequiredCity = (t: TranslateFunctionType) => {
  return t('Please fill out a City.');
};

export const validateRequiredStreetAddress = (t: TranslateFunctionType) => {
  return t('Please fill out an Address 1.');
};

export const validateRequiredProvince = (t: TranslateFunctionType) => {
  return t('Please select a province.');
};

export const validateRequiredPostalCode = (t: TranslateFunctionType) => {
  return t('Please fill out a Postal Code.');
};

export const validateDropdownRequired = (
  userInput: string | undefined,
  unchangedOption: string,
  label: string,
) => {
  if (userInput === unchangedOption) {
    return `${label} field is required.`;
  }
  return true;
};

export const validateEmailPattern = (t: TranslateFunctionType) => {
  return {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: t('Provide a valid email address.'),
  };
};

export const validateTextPattern = (label: string, t: TranslateFunctionType) => {
  return {
    value: /^[a-zA-Z0-9_-]+\s?([a-zA-Z0-9_-]+\s?){0,3}$/,
    message: `${label} ${t('field must not contain numbers or special characters.')}`,
  };
};

export const validateWordLimit = (label: string, t: TranslateFunctionType) => {
  return {
    value: /^(\S+(\s+|$)){0,200}$/,
    message: `${label} ${t('field must be 200 words or less.')}`,
  };
};

export const validatePostalCodePattern = (t: TranslateFunctionType) => {
  return {
    value: /^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/gm,
    message: t('Please provide a valid postal code'),
  };
};

export const validatePhoneNumberPattern = (t: TranslateFunctionType) => {
  return {
    value: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    message: t('Phone number must be in format: xxx-xxx-xxxx'),
  };
};

export const getCanadianProvinces = () => {
  return {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    YT: 'Yukon',
  };
};

export const formatPhoneNumber = (input: string): string => {
  // Remove all non-numeric characters
  let cleaned = `${input}`.replace(/\D/g, '');

  if (cleaned.length > 10) {
    cleaned = cleaned.slice(0, 10);
  }
  if (cleaned.length > 3) {
    cleaned = `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
  }
  if (cleaned.length > 7) {
    cleaned = `${cleaned.slice(0, 7)}-${cleaned.slice(7)}`;
  }

  return cleaned;
};

export const getRecaptchaToken = async (): Promise<string> => {
  const tokenPromise: Promise<string> = new Promise((res, rej) => {
    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_SITE_KEY, {
        action: 'submit',
      });

      if (token) {
        res(token);
      } else {
        rej(Error('grecaptcha rejected'));
      }
    });
  });

  return tokenPromise;
};
