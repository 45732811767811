import uniqueId from 'lodash/uniqueId';
import { useState } from 'react';

type Props = {
  prefix?: string;
};
export const useUniqueId = (props: Props) => {
  const [id] = useState(uniqueId(props.prefix));

  return id;
};
