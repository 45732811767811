/* eslint-disable complexity */
import { styled } from '@linaria/react';
import { AxiosError } from 'axios';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import Cookies from 'js-cookie';
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ansiraUserPost } from '../../utils/clientRequests';
import {
  getRecaptchaToken,
  validateEmailPattern,
  validatePostalCodePattern,
  validateRequired,
  validateRequiredEmail,
  validateRequiredFirstName,
  validateRequiredLastName,
  validateRequiredPostalCode,
  validateTextPattern,
} from '../../utils/formHelpers';
import Alert from '../alert/Alert';
import RichText from '../rich_text/RichText';
import RecaptchaDisclaimer from './components/RecaptchaDisclaimer';

interface PetType {
  petType: {
    keyName: 'CAT' | 'DOG';
  };
  name: string;
  birthDate: string;
}

interface FormValues {
  optIn: boolean;
  sourceCode: {
    keyName: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  postalCode: string;
  pets: PetType[];
  language: {
    keyName: Languages;
  };
  petOwnershipPlan: {
    keyName: string;
  };
}

interface FormProps {
  sourceCode: string;
  indexOfModule: number;
  settings: IGlobalFormSettings;
  language: Languages;
}

const RemoveBtn = styled(Button)`
  margin-top: 42px;
`;

const FormError = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
`;

const ProfileForm: React.FC<FormProps> = function ({ sourceCode, settings, language }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      sourceCode: {
        keyName: sourceCode,
      },
      firstName: '',
      lastName: '',
      email: '',
      postalCode: '',
      pets: [],
      optIn: false,
      language: {
        keyName: 'en',
      },
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pets',
  });
  const watchPets = watch('pets');
  const controlledPets = fields.map((field, index) => {
    return {
      ...field,
      ...watchPets[index],
    };
  });
  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [optIn, setOptIn] = React.useState(false);
  const [apiError, setApiError] = React.useState('');
  const apiRef = React.useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const handleAddPet = (type: 'CAT' | 'DOG') => {
    const newPet: PetType = {
      petType: {
        keyName: type,
      },
      name: '',
      birthDate: '',
    };
    append(newPet);
  };

  const handleRemovePet = (index: number) => {
    remove(index);
  };

  const onOptIn = () => {
    if (optIn) {
      setOptIn(false);
      setValue('optIn', false);
      return;
    }

    setOptIn(true);
    setValue('optIn', true);
  };

  const onSubmit: SubmitHandler<FormValues> = async formData => {
    const { pets, postalCode, ...rest } = formData;
    const dogCount = pets?.filter(pet => pet.petType.keyName === 'DOG').length || 0;
    const catCount = pets?.filter(pet => pet.petType.keyName === 'CAT').length || 0;
    let ownerShipPlan = 'NEITHER';
    if (dogCount && catCount) {
      ownerShipPlan = 'BOTH';
    }
    if (dogCount && !catCount) {
      ownerShipPlan = 'DOG';
    }
    if (catCount && !dogCount) {
      ownerShipPlan = 'CAT';
    }
    const data = {
      ...rest,
      pets,
      petOwnershipPlan: { keyName: ownerShipPlan },
      address: { postalCode },
      RecaptchaToken: '',
      language: { keyName: language },
    };

    setSubmitting(true);
    data.RecaptchaToken = await getRecaptchaToken();
    const userData = await ansiraUserPost({ data });
    if (!userData) {
      setApiError('Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    if (userData instanceof AxiosError) {
      setApiError(userData.response?.data?.message || 'Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    Cookies.set('profiles_uuid', userData.uuid);
    setSubmitted(true);
    reset();
    setSubmitting(false);
  };

  if (submitting) {
    return (
      <Row className="justify-content-center">
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Submitting...</span>
          </Spinner>
          <span className="ms-3">Please Wait...</span>
        </Col>
      </Row>
    );
  }
  if (submitted) {
    return (
      <Row>
        <Col className="text-center">
          <h2>{settings.success_title}</h2>
          <Alert variant="success" className="my-3">
            <RichText body={settings.success_response} />
          </Alert>
        </Col>
      </Row>
    );
  }
  return (
    <AnalyticsPoint
      type="component"
      eventOverride="form_submit"
      label="Ansira Profile Form"
      node={{
        type: 'ansira_profile_form',
        name: 'Ansira Profile Form',
        title: 'form_submit',
        id: '',
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <Row className="gx-3 gy-3">
          {apiError && (
            <Alert variant="error" role="alert" tabIndex={0} ref={apiRef}>
              {apiError}
            </Alert>
          )}
          <Col xs={12} md={6}>
            <Form.Group controlId="firstName" className="required">
              <Form.Label>First name</Form.Label>
              <Form.Control
                {...register('firstName', {
                  required: validateRequiredFirstName(t),
                })}
                type="text"
                isInvalid={!!errors?.firstName}
                aria-invalid={!!errors?.firstName}
                placeholder="First name"
                maxLength={60}
                required
                aria-required="true"
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName && errors.firstName.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="lastName" className="required">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                {...register('lastName', {
                  required: validateRequiredLastName(t),
                })}
                isInvalid={!!errors?.lastName}
                aria-invalid={!!errors?.lastName}
                placeholder="Last name"
                maxLength={60}
                required
                aria-required="true"
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName && errors.lastName.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group controlId="email" className="required">
              <Form.Label>E-mail address</Form.Label>
              <Form.Control
                {...register('email', {
                  required: validateRequiredEmail(t),
                  pattern: validateEmailPattern(t),
                })}
                type="email"
                isInvalid={!!errors?.email}
                aria-invalid={!!errors?.email}
                placeholder="example@gmail.com"
                maxLength={128}
                required
                aria-required="true"
              />
              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="postalCode" className="required">
              <Form.Label>Zip code</Form.Label>
              <Form.Control
                {...register('postalCode', {
                  required: validateRequiredPostalCode(t),
                  pattern: validatePostalCodePattern(t),
                })}
                isInvalid={!!errors.postalCode}
                aria-invalid={!!errors.postalCode}
                placeholder="Zip code"
                maxLength={5}
                required
                aria-required="true"
              />
              <Form.Control.Feedback type="invalid">
                {errors.postalCode && errors.postalCode.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group controlId="pets">
              <Form.Label>My Pets</Form.Label>
              <Row className="gy-3 gx-2 mb-4">
                <Col xs={6} md="auto">
                  <Button
                    size="sm"
                    variant="outline-dark"
                    onClick={() => handleAddPet('DOG')}
                    className="w-100 d-block"
                  >
                    Add Dog
                  </Button>
                </Col>
                <Col xs={6} md="auto">
                  <Button
                    size="sm"
                    variant="outline-dark"
                    onClick={() => handleAddPet('CAT')}
                    className="w-100 d-block"
                  >
                    Add Cat
                  </Button>
                </Col>
              </Row>
              {controlledPets.map((pet, index) => {
                const petsError = errors?.pets && errors.pets[index];
                const petType: string = pet.petType.keyName.toLowerCase();
                const nameLabel = `${petType.charAt(0).toUpperCase() + petType.slice(1)} name`;
                return (
                  <>
                    <Row key={index} className="gy-3 gx-2 py-2">
                      <Col xs={12} md={6}>
                        <input
                          type="hidden"
                          value={pet.petType.keyName}
                          {...register(`pets.${index}.petType.keyName` as const)}
                        />
                        <Form.Group className="required" controlId={`pets-${index}-name`}>
                          <Form.Label>{nameLabel}</Form.Label>
                          <Form.Control
                            {...register(`pets.${index}.name` as const, {
                              required: validateRequired('Pet name', t),
                              pattern: validateTextPattern('Pet name', t),
                            })}
                            type="text"
                            isInvalid={petsError && !!petsError?.name}
                            aria-invalid={petsError && !!petsError?.name}
                            placeholder="Name"
                            maxLength={255}
                            required
                            aria-required="true"
                          />
                          <Form.Control.Feedback type="invalid">
                            {petsError && petsError?.name && petsError?.name.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="required" controlId={`pets-${index}-birthDate`}>
                          <Form.Label>Birthday</Form.Label>
                          <Form.Control
                            {...register(`pets.${index}.birthDate` as const, {
                              required: validateRequired('Birthday', t),
                            })}
                            type="date"
                            isInvalid={petsError && !!petsError?.birthDate}
                            aria-invalid={petsError && !!petsError?.birthDate}
                            placeholder="mm/dd/yyyy"
                            required
                            aria-required="true"
                          />
                          <Form.Control.Feedback type="invalid">
                            {petsError && petsError?.birthDate && petsError?.birthDate.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={2} className="d-inline-flex align-items-center">
                        <RemoveBtn variant="outline-dark" onClick={() => handleRemovePet(index)}>
                          Remove
                        </RemoveBtn>
                      </Col>
                    </Row>
                    <hr />
                  </>
                );
              })}
            </Form.Group>
          </Col>
          <Col xs={12} className="mt-3">
            <Form.Group controlId="optIn">
              <Form.Check
                {...register('optIn', {
                  onChange: onOptIn,
                  validate: {
                    isTrue: v => (v === true ? true : settings.optin_error),
                  },
                })}
                isInvalid={!!errors?.optIn}
                aria-invalid={!!errors?.optIn}
                type="checkbox"
                label={<RichText body={settings.optin_label} />}
                checked={optIn}
                required
                onClick={() => setOptIn(!optIn)}
              />
              <FormError>{errors.optIn && errors.optIn.message}</FormError>
            </Form.Group>
          </Col>
          <Col xs={12} className="mt-3">
            {settings.legal_text && <RichText body={settings.legal_text} />}
            <RecaptchaDisclaimer />
          </Col>
          <Col xs={12}>
            <Button
              className="rounded-pill justify-content-center mt-3"
              type="submit"
              size="sm"
              style={{ padding: '.5rem 3rem' }}
              disabled={submitting}
            >
              {submitting ? 'Please wait...' : 'Join Now'}
            </Button>
          </Col>
        </Row>
      </Form>
    </AnalyticsPoint>
  );
};

export default ProfileForm;
