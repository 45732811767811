import { styled } from '@linaria/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import { useMapStore } from 'src/stores/mapStore';
import { useMap } from './helpers/useMap';

interface Props {
  locations: TaxonomyTermLocation[];
  style?: Record<string, string | number>;
}

const StyledMap = styled.div`
  width: 100%;
  height: 500px;

  .gm-style-iw button {
    display: none !important;
  }
`;

const IngredientMap: React.FC<Props> = ({
  locations,
  style = { width: '100%', height: '400px' },
}) => {
  const mapData = useMapStore(state => state.mapData);
  const setMapData = useMapStore(state => state.setMapData);
  const { loadMap, focusMapLocations } = useMap();
  const iconData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "media/images/pin.png" }) {
        publicURL
      }
    }
  `);

  useEffect(() => {
    loadMap({ locations, iconData });

    return () => setMapData(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Focus initialy
    if (mapData && mapData?.markers.length > 0) {
      focusMapLocations();
    }
    // eslint-disable-next-line
  }, [mapData?.markers]);

  return <StyledMap style={style} id="ing-map" />;
};

export default IngredientMap;
