import { styled } from '@linaria/react';
import React from 'react';
import { theme } from '../../theme/theme';

export type DescriptionListItem = {
  key: string;
  title: string;
  description: string;
  weight: number;
};

type Props = JSX.IntrinsicElements['dl'] & {
  items: DescriptionListItem[];
};

const dList = ({ count, ...props }) => <dl {...props} />;

const StyledDList = styled(dList)`
  display: grid;
  justify-content: space-between;

  @media (min-width: ${theme.media.md}) {
    grid-auto-flow: column;
    grid-template-rows: ${({ count }) => `repeat(${count}, auto)`};
    grid-template-columns: auto auto;
  }

  dt:after {
    content: ': ';
  }

  dd {
    font-size: clamp(1rem, 0.52vw + 1rem, 1.3rem);
    font-weight: 300;
  }

  > div {
    border-left: 1px solid ${theme.common.gray[300]};
    padding-bottom: 2rem;
    padding-left: 2rem;
    margin-right: 20px;
  }
`;

const DescriptionList: React.FC<Props> = function ({ items, ...props }: Props) {
  const count = Math.ceil(items.length / 2);
  return (
    <StyledDList {...props} count={count}>
      {items.map((item, i) => (
        <div key={`${item.title}-${i}`} className="d-flex flex-column gap-2">
          <dt className="mb-0 typography_h5">{item.title}</dt>
          <dd>{item.description}</dd>
        </div>
      ))}
    </StyledDList>
  );
};

export default DescriptionList;
