import { Link, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Button, Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import useAxiosPost from '../hooks/useAxiosPost';

const linksList = [
  {
    key: 1,
    link: '/',
    label: 'Homepage',
  },
  {
    key: 2,
    link: '/dogs/dog-food',
    label: 'Dog Food',
  },
  {
    key: 3,
    link: '/cats/cat-food',
    label: 'Cat Food',
  },
];

const NotFoundPage: React.FC<PageProps> = ({ location }) => {
  useAxiosPost({
    url: '/api/log-404',
    payload: {
      path404: typeof window !== 'undefined' ? window.location.pathname : '',
    },
  });

  return (
    <Layout language="en">
      <Seo
        title="Error 404: We’re soooo sorry! | Purina"
        meta={[{ name: 'description', content: "We didn't mean to lose your page." }]}
      />
      <Container className="p-5 text-center d-grid gap-5">
        <StaticImage
          src="../assets/media/images/Purina-404.jpeg"
          alt=""
          height={600}
          width={1640}
        />
        <Stack className="gap-4">
          <h1 className="fw-normal">We’re soooo sorry!</h1>
          <h2 className="fw-light lh-base text-dark fs-x-large text-center">
            We didn&#39;t mean to lose your page. <br /> Try the following pages:
          </h2>
        </Stack>
        <Stack
          direction="vertical"
          gap={3}
          className="justify-content-center align-items-center flex-sm-row"
        >
          {linksList.map(({ key, link, label }) => (
            <Button
              key={key}
              variant="outline"
              className="btn btn-outline-primary rounded-pill text-black"
              aria-label={label}
            >
              <Link className="text-decoration-none fs-large text-reset" to={link}>
                {label}
              </Link>
            </Button>
          ))}
        </Stack>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
