import { GatsbyImage } from 'gatsby-plugin-image';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getFocalPoint } from 'src/utils/mediaHelpers';
import { CMS_THEME } from '../common/enums';
import ProductReview from '../components/product_review/ProductReview';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';
import { formatSku } from '../utils/helpers';

interface StorageProductReviewProps {
  node: IStorageReviews;
}

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_product_features: {
    read_reviews: true,
    buy_now: true,
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
    hide_media: false,
    content_position: 'right',
  },
};

const StorageProductReview: React.FC<StorageProductReviewProps> = ({ node: productReviews }) => {
  const settings = Object.assign(DefaultSettings, productReviews?.behaviors || {});
  const reviews = productReviews?.relationships?.reviews || [];
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const cmsTheme = settings.entity_variant.theme;
  const hasActionReadReviews = settings.entity_product_features?.read_reviews;
  const hasActionBuyNow = settings.entity_product_features?.buy_now;
  const hideMedia = settings.entity_variant?.hide_media;

  // If all the reviews are draft, don't render.
  if (!reviews || reviews.length === 0) {
    return null;
  }

  return (
    <Wrapper
      id={productReviews.component_id}
      data-id={productReviews.drupal_id}
      className="py-4 py-lg-5 px-0"
      fluid
      cmsTheme={cmsTheme}
    >
      <Container>
        {productReviews?.title || productReviews?.body?.processed ? (
          <div className="mb-5">
            {productReviews?.title ? (
              <HeadingElement className={cx(headingStyle, 'text-center')}>
                {productReviews.title}
              </HeadingElement>
            ) : null}
            {productReviews?.body?.processed ? (
              <RichText
                cmsTheme={cmsTheme}
                className={cx('mt-4 text-center')}
                body={productReviews.body.processed}
              />
            ) : null}
          </div>
        ) : null}
        <Row className="justify-content-center">
          <Col lg={!hideMedia || reviews.length > 1 ? 12 : 9}>
            <Row gap={3}>
              {reviews.map((review, index) => {
                const skus = review.relationships?.product[0]?.relationships?.skus;
                const sku: string = skus?.length && skus[0]?.upc ? formatSku(skus[0].upc) : '';
                const reviewMediaFile =
                  review.relationships.media?.relationships?.field_media_image;
                const { filemime } = reviewMediaFile;
                const imageAlt = review.relationships.media?.field_media_image.alt;
                const isPNG = filemime.search(/png/i) > -1;
                const image = isPNG ? reviewMediaFile?.reviewPng : reviewMediaFile?.reviewJpg;

                return (
                  <Col data-id={review.drupal_id} key={index} lg={12 / reviews.length} xs={12}>
                    <ProductReview
                      cmsTheme={cmsTheme}
                      review={review?.body?.processed || ''}
                      author={review.author}
                      rating={review.rating}
                      title={review.title}
                      alignment={!hideMedia ? settings.entity_variant?.content_position : 'right'}
                      reviewLink={
                        hasActionReadReviews
                          ? review.relationships?.product[0]?.path?.alias
                          : undefined
                      }
                      isVertical={reviews.length > 1}
                      sku={hasActionBuyNow ? sku : undefined}
                      imgCover={isPNG}
                      imgComponent={
                        !hideMedia ? (
                          <GatsbyImage
                            objectFit={`${isPNG ? 'contain' : 'cover'}`}
                            objectPosition={
                              isPNG ? '50% 50%' : getFocalPoint(review.relationships.media)
                            }
                            image={image}
                            alt={imageAlt}
                          />
                        ) : undefined
                      }
                      className="h-100 justify-content-center"
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default StorageProductReview;
