import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import { typography } from '../../theme/typography';

/* TODO: revisit rich text once all components are rethemed and make cmsTheme required to be passed
 * change color to use the passed theme
 */
const StyledRichText = styled.div<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  font-family: ${typography.body.fontFamily};
  font-size: ${typography.body.fontSize};
  font-weight: normal;
  line-height: ${typography.body.lineHeight};
  letter-spacing: normal;

  &.is-article > p:first-of-type {
    font-family: ${typography.xxxLarge.fontFamily};
    font-size: ${typography.xxxLarge.fontSize};
    font-weight: normal;
    line-height: ${typography.xxxLarge.lineHeight};
    letter-spacing: normal;
  }

  blockquote {
    background: ${({ cmsTheme }) => theme[cmsTheme].background.paper};
    border-left: ${({ cmsTheme }) => `2px solid ${theme[cmsTheme].text.default}`};
    padding: 1rem 1rem 1rem 2rem;
    margin-left: 2rem;
    margin-right: 2rem;

    *:last-child {
      margin-bottom: 0;
    }
  }

  &.small {
    font-size: ${typography.small.fontSize};
  }

  .text-align-center {
    text-align: center;
  }

  .text-align-right {
    text-align: right;
  }

  & > * + * {
    margin-block: 1em;
  }

  & h1 {
    font-weight: 200;
  }

  & h2 {
    font-weight: 200;
  }

  & h3 {
    font-weight: 200;
  }

  & h4 {
    font-weight: 200;
  }

  & h5 {
    font-weight: 200;
  }

  & h6 {
    font-weight: 200;
  }

  ul,
  ol {
    margin-left: 3rem;

    ul {
      margin-left: 0;
      padding-left: 1rem;
    }

    ol {
      margin-left: 0;
      padding-left: 1.3rem;
    }

    li {
      line-height: 2;
      padding-left: 1rem;
    }
  }

  a {
    color: ${({ cmsTheme }) => theme[cmsTheme].action};

    &:hover,
    &:focus,
    &:active {
      color: ${({ cmsTheme }) => theme[cmsTheme].action};
    }
  }
`;

type Props = {
  body: string;
  isArticle?: boolean;
  className?: string;
  cmsTheme?: CMS_THEME;
};

const RichText = ({ body, isArticle, className, cmsTheme = CMS_THEME.LIGHT }: Props) => (
  <StyledRichText
    cmsTheme={cmsTheme}
    className={cx(className, `${isArticle && 'is-article'}`)}
    dangerouslySetInnerHTML={{ __html: body }}
  />
);

export default RichText;
