import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import GatsbyMediaImage from '../components/media/MediaImage';
import Typography from '../components/typography/Typography';
import { theme } from '../theme/theme';

const StyledGrid = styled.div<{ columns: string; cmsTheme: CMS_THEME }>`
  --grid-layout-gap: 1rem;
  --grid-column-count: ${props => props.columns};
  --grid-item--min-width: 250px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  column-gap: var(--grid-layout-gap);
  row-gap: 70px;
  display: grid;
  place-items: center;
`;

const StyledStack = styled(Stack)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StyledCard = styled(Card)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const gridClass = css`
  padding-left: 1rem;
  @media (min-width: 450px) {
    padding-right: 1rem;
  }
`;

const defaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h3',
    style: 'typography_h3',
  },
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_variant: {
    text_align: 'left',
    theme: CMS_THEME.LIGHT,
  },
  entity_rows_columns: {
    columns: '2',
  },
};

type ContentCardItemProps = IStorageContentCard & {
  mode?: string;
  buttonVariant: 'btn' | 'btn-outline' | 'btn-link';
  columns: number;
  cmsTheme: CMS_THEME;
  indexOfComponent: number;
  indexOfModule: number;
};

const ContentCardItem = (el: ContentCardItemProps): JSX.Element => {
  const headingStyle = el.columns < 4 ? 'h3' : 'h4';
  return (
    <AnalyticsPoint
      as={Stack}
      data-id={el.drupal_id}
      className="flex-column"
      gap={3}
      type="component"
      node={el}
    >
      <StyledCard cmsTheme={el.cmsTheme} className="border-0 d-flex rounded-3">
        {el.relationships?.field_media?.relationships &&
          (el.relationships.field_media as MediaImage).relationships.field_media_image && (
            <GatsbyMediaImage
              {...(el.relationships.field_media as MediaImage)}
              variant={el.imgSize}
            />
          )}
      </StyledCard>
      <Typography variant={headingStyle} className="fw-light">
        {el.title}
      </Typography>
      {/* eslint-disable-next-line react/no-danger */}
      {el.copy && <div dangerouslySetInnerHTML={{ __html: el.copy?.processed }} />}
      {el?.link?.title && (
        <div className="mt-auto">
          <ButtonLink
            variant={el.buttonVariant}
            to={el.link.url}
            cmsTheme={el.cmsTheme}
            {...el.link.options?.attributes}
          >
            {el.link.title}
          </ButtonLink>
        </div>
      )}
    </AnalyticsPoint>
  );
};

type StorageCardGridProps = {
  node: IStorageCardGrid;
};

const StorageCardGrid = ({ node: cardGrid }: StorageCardGridProps) => {
  const settings = Object.assign(defaultSettings, cardGrid?.behaviors || {});
  const themeType = cardGrid.relationships.theme?.field_color_type ?? 'light';
  const cmsTheme = settings.entity_variant.theme;
  const { primary_button: buttonStyle } = settings.entity_buttons;
  const { text_align: textAlign } = settings.entity_variant;
  const { columns } = settings.entity_rows_columns;
  const { element: headingElement, style: headingStyle } = settings.entity_heading;
  const { element: subheadingElement, style: subheadingStyle } = settings.entity_subheading;
  const {
    relationships: { cards },
  } = cardGrid;

  const renderCardItem = (element: IStorageContentCard, i: number) => (
    <ContentCardItem
      indexOfModule={cardGrid.indexOnPage}
      indexOfComponent={i}
      {...element}
      cmsTheme={cmsTheme}
      columns={Number(columns)}
      buttonVariant={buttonStyle}
      key={element.id}
      mode={themeType}
    />
  );

  // If cards are all drafts, render nothing.
  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <StyledStack data-id={cardGrid.drupal_id} cmsTheme={cmsTheme}>
      <Stack className={cx('py-5 gap-5 container-xxl text-center', gridClass)}>
        <div>
          {cardGrid?.title && (
            <Typography key="card-title" variant={headingElement} className={headingStyle}>
              {cardGrid.title}
            </Typography>
          )}
          {cardGrid?.subtitle && (
            <Typography key="card-subtitle" variant={subheadingElement} className={subheadingStyle}>
              {cardGrid.subtitle}
            </Typography>
          )}
          {cardGrid.body?.processed && (
            <div
              key="card-content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: cardGrid.body?.processed }}
            />
          )}
        </div>
        <div className="gap-5 d-flex flex-column">
          <StyledGrid
            columns={columns}
            key="card-grid-desktop"
            cmsTheme={cmsTheme}
            className={`text-${textAlign}`}
          >
            {cards.map(renderCardItem)}
          </StyledGrid>
        </div>
      </Stack>
    </StyledStack>
  );
};

export default StorageCardGrid;
