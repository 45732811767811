/* eslint-disable no-param-reassign */
import { Loader } from '@googlemaps/js-api-loader';
import { IconData, useMapStore } from 'src/stores/mapStore';
import { mapTheme } from './mapTheme';

interface ILoadMap {
  locations: TaxonomyTermLocation[];
  iconData: IconData;
}

interface ICreateMarkers {
  locations: TaxonomyTermLocation[];
  isInfoWindowOpen: boolean;
  openInfoWindowId: number;
  infoWindow: google.maps.InfoWindow;
  markers: google.maps.Marker[];
  map: google.maps.Map;
  bounds: google.maps.LatLngBounds;
  iconData: IconData;
  focus?: boolean;
}

export const useMap = () => {
  const setMapData = useMapStore(state => state.setMapData);
  const mapData = useMapStore(state => state.mapData);
  const isSSR = typeof window === 'undefined';
  const isDesktop = !isSSR ? window.innerWidth > 767 : false;

  const createMarkers = ({
    locations,
    isInfoWindowOpen,
    openInfoWindowId,
    infoWindow,
    markers,
    map,
    bounds,
    focus,
    iconData,
  }: ICreateMarkers) => {
    if (locations.length !== 0) {
      locations.forEach((location, i) => {
        const marker = new google.maps.Marker({
          position: { lat: location.field_coordinates.lat, lng: location.field_coordinates.lon },
          map,
          icon: {
            url: iconData.file.publicURL,
            scaledSize: new google.maps.Size(30, 38),
          },
        });

        const infoWindowContent = `
          <div>${location.name}<div>
        `;

        marker.addListener('click', () => {
          if (isInfoWindowOpen && i === openInfoWindowId) {
            infoWindow.close();
            isInfoWindowOpen = false;
            openInfoWindowId = -1;
            return;
          }

          infoWindow.setContent(infoWindowContent);
          infoWindow.open(map, marker);
          isInfoWindowOpen = true;
          openInfoWindowId = i;
        });

        bounds.extend(marker.getPosition());
        markers.push(marker);
      });

      if (focus) {
        const menu = document.getElementById('map-menu');
        const options = isDesktop
          ? { left: menu?.clientWidth || 0 }
          : { bottom: menu?.clientHeight || 0 };
        map.fitBounds(bounds, options);
      }
    }

    setMapData({
      map,
      bounds,
      markers,
      infoWindow,
      isInfoWindowOpen,
      openInfoWindowId,
      iconData,
    });
  };

  const loadMap = async ({ locations, iconData }: ILoadMap) => {
    const isInfoWindowOpen = false;
    let openInfoWindowId = -1;
    const key = process.env.GATSBY_MAP_API_KEY;

    const loader = new Loader({
      apiKey: key || null,
    });

    loader.load().then(google => {
      const entry = document.getElementById('ing-map');
      if (!entry) return;

      const map = new google.maps.Map(entry, {
        zoom: 5,
        // minZoom: 5,
        maxZoom: 5,
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
        center: { lat: 38.403163, lng: -92.568913 },
        styles: mapTheme,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        // restriction: {
        //   latLngBounds: {
        //     north: 50,
        //     south: 14,
        //     east: -52,
        //     west: -164,
        //   },
        // },
      });

      const bounds = new google.maps.LatLngBounds();

      const infoWindow = new google.maps.InfoWindow({ content: null });

      map.addListener('click', () => {
        if (isInfoWindowOpen) {
          openInfoWindowId = -1;
          infoWindow.close();
        }
      });

      const markers: google.maps.Marker[] = [];

      createMarkers({
        markers,
        infoWindow,
        openInfoWindowId,
        isInfoWindowOpen,
        locations,
        map,
        bounds,
        iconData,
      });
    });
  };

  const updateMapMarkers = (locations: TaxonomyTermLocation[]) => {
    if (!mapData) return;
    mapData?.markers.forEach(marker => marker.setMap(null));
    setMapData({ ...mapData, markers: [] });

    createMarkers({
      ...mapData,
      locations,
      focus: true,
    });
  };

  const focusMapLocations = () => {
    if (!mapData) return;
    const { map, bounds, markers } = mapData;
    if (markers.length === 0) return;

    markers.forEach(marker => bounds.extend(marker.getPosition()));

    const menu = document.getElementById('map-menu');
    const options = isDesktop
      ? { left: menu?.clientWidth || 0 }
      : { bottom: menu?.clientHeight || 0 };
    map.fitBounds(bounds, options);
  };

  const focusMapLocation = (locationIndex: number) => {
    if (!mapData || mapData.markers.length === 0) return;
    const { map, markers } = mapData;
    const menu = document.getElementById('map-menu');
    let location = markers[locationIndex].getPosition();

    if (!location) return;

    if (menu) {
      const pixelLocation = map.getProjection()?.fromLatLngToPoint(location);
      const offset = !isSSR ? window.innerWidth > 767 : 6;

      if (pixelLocation) {
        const newPoint = isDesktop
          ? new google.maps.Point(pixelLocation.x - offset, pixelLocation.y || 0)
          : new google.maps.Point(pixelLocation.x, pixelLocation.y + offset || 0);
        const newLocation = map.getProjection()?.fromPointToLatLng(newPoint);

        location = newLocation || location;
      }
    }

    map.panTo(location);
  };

  const removeMarkers = () => {
    if (!mapData || mapData.markers.length === 0) return;

    mapData.markers.forEach(marker => marker.setMap(null));
    setMapData({ ...mapData, markers: [] });
  };

  return {
    removeMarkers,
    focusMapLocations,
    createMarkers,
    focusMapLocation,
    updateMapMarkers,
    loadMap,
  };
};
