import { styled } from '@linaria/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme/theme';

const TextContainer = styled.div`
  color: ${theme.common.gray[500]};
`;

const RecaptchaDisclaimer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <TextContainer>
      {t('This site is protected by reCAPTCHA and the Google')}{' '}
      <a href="https://policies.google.com/privacy">{t('Privacy Policy')}</a> {t('and')}{' '}
      <a href="https://policies.google.com/terms">{t('Terms of Service')}</a> {t('apply')}.
    </TextContainer>
  );
};
export default RecaptchaDisclaimer;
