export enum CMS_THEME {
  DARK = 'dark',
  LIGHT = 'light',
  NEUTRAL = 'neutral',
  LIGHT_RED = 'light_red',
  LIGHT_BLUE = 'light_blue',
  GOLD = 'gold'
}

export enum CMS_OVERLAY {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum PRODUCT_STATUS {
  FORMULA_UPDATE = 'Formula Update',
  PACKAGE_UPDATE = 'Package Update',
  SUPPLY_SHORTAGES = 'Supply Shortages',
  PRODUCT_RECALL = 'Product Recall',
  DISCONTINUED = 'Discontinued',
  NEW_PRODUCT = 'New Product',
}
