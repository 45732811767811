import { create } from 'zustand';

export interface IconData {
  file: {
    publicURL: string;
  };
}

export interface MapData {
  map: google.maps.Map;
  bounds: google.maps.LatLngBounds;
  markers: google.maps.Marker[];
  infoWindow: google.maps.InfoWindow;
  isInfoWindowOpen: boolean;
  openInfoWindowId: number;
  iconData: IconData;
}

interface MapStore {
  mapData: MapData | null;
  setMapData: (map: MapData | null) => void;
}

export const useMapStore = create<MapStore>()(set => ({
  mapData: null,
  setMapData: map => set({ mapData: map }),
}));
