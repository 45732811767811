import { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { newsletterSignUpPost } from '../../../utils/clientRequests';
import { getRecaptchaToken } from '../../../utils/formHelpers';
import Alert from '../../alert/Alert';
import RichText from '../../rich_text/RichText';
import MemberProfile from './MemberProfile';
import ProfessionalInvolvement from './ProfessionalInvolvement';
import TellUsMore from './TellUsMore';
import { IProClubMemberFormData, ProClubMemberFormProps } from './types';

const ProClubMemberForm = ({ getFieldDataByName }: ProClubMemberFormProps) => {
  const { t } = useTranslation();

  const methods = useForm<IProClubMemberFormData>({
    mode: 'onSubmit',
    shouldUnregister: true,
    shouldFocusError: true,
  });

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiError, setApiError] = useState('');
  const apiRef = useRef<HTMLDivElement>(null);

  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<any> = async data => {
    const formattedData = {
      webform_id: 'pro_club_member_application',
      first_name: data.firstName,
      last_name: data.lastName,
      address: data.address,
      email_address: data.email,
      city: data.city,
      province: data.province,
      postal_code: data.postalCode,
      home_telephone: data.homePhoneNumber,
      mobile_telephone: data.mobilePhoneNumber,
      breed: data.workProfessionallyWithPets,
      kennel_cattery_name: data.kenelCatteryName,
      association_membership_number: data.membershipNumber,
      kennel_cattery_website: data.kenelCatteryProfessionalWebsite,
      involvement_choice: data.petsPrimaryInvolvement,
      involvement_participate: data.participateIn,
      involvement_participate_other: data.participateInOther,
      breeds_fed: data.fedPets,
      referred_by: data.referralName,
      num_pets_feeding: data.feedingPets,
      num_pets_bred: data.puppiesProduced,
      feeding_brands: data.priorProducts,
      where_do_you_currently_buy_your_dog_cat_food_: data.foodShoppingPlace,
      help_choosing: data.needHelp,
      pro_plan_delivery: data.largeOrders,
      how_did_you_hear: data.howDidYouHear,
      how_did_you_hear_show: data.howDidYouHearShow,
      how_did_you_hear_other: data.howDidYouHearOther,
      RecaptchaToken: '',
    };

    setSubmitting(true);
    formattedData.RecaptchaToken = await getRecaptchaToken();
    const newsletterResponse = await newsletterSignUpPost({ data: formattedData });
    if (!newsletterResponse) {
      setApiError('Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    if (newsletterResponse instanceof AxiosError) {
      setApiError(newsletterResponse.response?.data?.message || 'Something went wrong');
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    setSubmitted(true);
    setSubmitting(false);
  };

  if (submitting) {
    return (
      <Row className="justify-content-center">
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{`${t('Submitting')}...`}</span>
          </Spinner>
          <span className="ms-3">{`${t('Please wait')}...`}</span>
        </Col>
      </Row>
    );
  }
  if (submitted) {
    return (
      <Row>
        <Col className="text-center">
          <RichText body={getFieldDataByName('success_message')?.text} />
        </Col>
      </Row>
    );
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {apiError && (
          <Alert variant="error" role="alert" tabIndex={0} ref={apiRef}>
            {apiError}
          </Alert>
        )}
        <Container className="py-5">
          <Row className="justify-content-md-start mt-5">
            <Col>
              <MemberProfile getFieldDataByName={getFieldDataByName} />
              <ProfessionalInvolvement getFieldDataByName={getFieldDataByName} />
              <TellUsMore getFieldDataByName={getFieldDataByName} />
            </Col>
          </Row>
          <Row className="justify-content-md-start mt-5">
            <RichText body={getFieldDataByName('member_consent_conditions')?.text} />
            <Col className="py-4">
              <Button variant="dark" className="justify-content-center" type="submit">
                {submitting ? `${t('Please wait')}...` : getFieldDataByName('actions').submitLabel}
              </Button>
            </Col>
            <RichText body={getFieldDataByName('pro_club_membership_conditions')?.text} />
          </Row>
        </Container>
      </Form>
    </FormProvider>
  );
};

export default ProClubMemberForm;
