import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

interface NewsletterSignupModalProps {
  modalDelay: number;
  children: ReactNode;
}

const NewsletterSignupModal: React.FC<NewsletterSignupModalProps> = ({ modalDelay, children }) => {
  const [show, setShow] = useState<boolean>(false);
  const modalTriggered = useRef(false);

  const handleClose = () => setShow(false);

  function triggerModal() {
    if (modalTriggered.current) return;

    modalTriggered.current = true;

    setTimeout(() => {
      setShow(true);
    }, modalDelay * 1000);
  }

  useEffect(() => {
    triggerModal();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton />
      <Modal.Body className="p-4">{children}</Modal.Body>
    </Modal>
  );
};

export default NewsletterSignupModal;
