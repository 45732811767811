import { common } from './common';
import { ITheme } from './types';

export const light_blue: ITheme = {
  mode: 'light',
  primary: {
    main: '#2B2B2B',
    withAlpha: (alpha: number) => `rgba(43, 43, 43, ${alpha})`,
    // dark: '',
    // light: '',
  },
  text: {
    invert: '#FFF',
    withAlpha: (alpha: number) => `rgba(43, 43, 43, ${alpha})`,
    default: '#2B2B2B',
  },
  background: {
    default: '#A0D1E6',
    alternate: '#FFF',
    paper: '#F8FAFF',
    imageMatte: '#EEF1F8',
    iconContainer: '#EEF1F8',
  },
  action: '#2553F8',
  ...common,
};
